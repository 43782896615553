<form class="search-form" action="none" method="dialog">
  <h3>Productos:</h3>
  <input
    name="search"
    placeholder="Buscar"
    (keyup.enter)="applyFilters()"
    [(ngModel)]="searchWord"
  />
</form>
<div class="sel-container">
  <label for="productId">Estas viendo:</label>
  <select
    (change)="applyFilters()"
    id="productId"
    [(ngModel)]="productId"
    name="productId"
    class="selector"
  >
    <option value="music">Música</option>
    <option value="karaoke">Karaoke</option>
    <option value="video">Videos</option>
    <option value="combos_mus">Combos música</option>
    <option value="combos_vid">Combos videos</option>
  </select>
</div>
<div class="sel-container">
  <label for="genre">Buscar por genero músical:</label>
  <select
    (change)="applyFilters()"
    id="genre"
    [(ngModel)]="genre"
    name="genre"
    class="selector"
  >
    <option value="">Todos</option>
    <option *ngFor="let g of genres" [value]="g.genre">{{ g.genre }}</option>
  </select>
</div>
<div class="audio-table">
  <div class="del-container">
    <p class="p active" *ngIf="deleteList.length > 0">
      {{ deleteList.length }} Elementos seleccionados
    </p>
    <p class="p" *ngIf="deleteList.length == 0">
      Selecciona elementos a eliminar
    </p>
    <button
      *ngIf="deleteList.length < 1"
      class="buttonDelete"
      (click)="selectThings()"
    >
      Seleccionar todos ({{ this.items.length }})
    </button>
    <button
      class="deseleccionarItems"
      *ngIf="deleteList.length > 0"
      (click)="deSelectThings()"
    >
      Deseleccionar Elementos
    </button>
    <button *ngIf="deleteList.length > 0" (click)="deleteSelcted()">
      Eliminar Elementos
    </button>
  </div>
  <div *ngFor="let item of items; let i = index">
    <div class="audio">
      <span
        class="select-box"
        target="_blank"
        (click)="selectThing(item, i)"
        [ngClass]="{ active: item.delete }"
      ></span>
      <a class="play" [href]="item.url" target="_blank"></a>
      <h4 class="title">
        {{ item.name }}
        <span class="genre tag">{{ item.genre }}, {{ item.date }}</span>
      </h4>
      <span class="delete" (click)="delete(item.id, i)"></span>
    </div>
  </div>
  <section class="paginator" *ngIf="numberOfPages.length > 0">
    <div
      class="paginator-left"
      (click)="paginatorControl.setValue(paginatorControl.value - 1)"
      [ngClass]="{ 'paginator-disabled': pageSelected < 2 }"
    >
      <i class="fas fa-caret-left"></i>
    </div>
    <div *ngFor="let item of numberOfPages; index as i">
      <div
        class="paginator-number"
        (click)="paginatorControl.setValue(i + 1)"
        *ngIf="
          (i < pageSelected + 2 && i > pageSelected - 5) ||
          (pageSelected < 4 && i < 6) ||
          (pageSelected > numberOfPages.length - 3 &&
            i > numberOfPages.length - 7)
        "
        [ngClass]="{ 'paginator-active': pageSelected === i + 1 }"
      >
        {{ i + 1 }}
      </div>
    </div>
    <div
      class="paginator-right"
      (click)="paginatorControl.setValue(paginatorControl.value + 1)"
      [ngClass]="{ 'paginator-disabled': pageSelected >= numberOfPages.length }"
    >
      <i class="fas fa-caret-right"></i>
    </div>
    <div class="paginator-input">
      <select [formControl]="paginatorControl">
        <option *ngFor="let item of numberOfPages; index as i" [value]="i + 1">
          {{ i + 1 }}
        </option>
      </select>
    </div>
    <div class="paginator-input">
      <select [formControl]="paginatorLimit">
        <option [value]="25">25</option>
        <option [value]="100">100</option>
        <option [value]="500">500</option>
      </select>
    </div>
  </section>
</div>
<ngx-spinner
  bdColor="rgba(10, 14, 31,0.8)"
  size="medium"
  color="#0066ff"
></ngx-spinner>

<div class="upload-container">
    <div class="types">
        <p class="type" [ngClass]="{'active': selected === 'music' }" (click)="setSelected('music','Sube aquí las canciones')">Música</p>
        <p class="type" [ngClass]="{'active': selected === 'video' }" (click)="setSelected('video','Sube aquí los videos')">Videos</p>
        <p class="type" [ngClass]="{'active': selected === 'karaoke' }" (click)="setSelected('karaoke','Sube aquí las karaoke')">Karaoke</p>
        <p class="type" [ngClass]="{'active': selected === 'combos_mus' }" (click)="setSelected('combos_mus','Sube aquí las canciones del combo '+folder+'')">Combos Música</p>
        <p class="type" [ngClass]="{'active': selected === 'combos_vid' }" (click)="setSelected('combos_vid','Sube aquí los videos del combo, '+folder+'')">Combos Videos</p>
    </div>
    <label for="genre">Genero:</label>

    <select id="genre" [(ngModel)]="genre" (change)="genreChange()" name="genre" class="selector">
        <option *ngFor="let g of genres" [value]="g.genre">{{g.genre}}</option>
    </select>
    <div *ngIf="selected == 'combos_mus' || selected == 'combos_vid'">
        <label for="folder">Nombre del Combo:</label>
        <input name="folder" [(ngModel)]="folder">
        <button class="button full blue" (click)="addCombo()">{{status}}</button>
    </div>

    <!-- <app-toggle (click)="togglePublic()" text="¿Quieres hacer públicas estas canciones?" [isOn]="alsoPublic">
    </app-toggle> -->
    <uploader [alsoPublic]="alsoPublic" [genre]="genre" [folder]="folder" [title]="text" [type]="selected"></uploader>
</div>
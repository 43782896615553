import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  Output,
  EventEmitter,
} from "@angular/core";
import {
  AngularFireStorage,
  AngularFireUploadTask,
} from "@angular/fire/storage";
import { AngularFirestore } from "@angular/fire/firestore";
import { Observable } from "rxjs";
import { finalize, tap } from "rxjs/operators";
import { MysqlService } from "src/app/services/mysql.service";

declare var Ciseaux: any;
@Component({
  selector: "upload-task",
  templateUrl: "./upload-task.component.html",
  styleUrls: ["./upload-task.component.scss"],
})
export class UploadTaskComponent implements OnInit {
  @Input() type;
  @Input() genre;
  @Input() file: File;
  @Output() myImage = new EventEmitter<any>();
  @Input() folder = "";

  alsoPublic = true;

  task: AngularFireUploadTask;
  error = false;
  percentage;
  snapshot: Observable<any>;
  downloadURL: string;
  // formData: FormData = new FormData();

  constructor(
    private storage: AngularFireStorage,
    private db: AngularFirestore,
    private mysql: MysqlService
  ) {}

  ngOnInit() {
    if (this.type == "premium" || this.type == "home") {
      this.firebaseUpload();
    } else {
      this.startUpload();
    }
  }

  startUpload() {
    const formData: FormData = new FormData();
    formData.append("fileType", this.type);
    formData.append("genre", this.genre);
    formData.append("file", this.file);

    if (this.folder != "") {
      formData.append("folder", this.folder);
    }
    console.log("inicie la subida");

    this.mysql.upload(formData).subscribe(
      (res) => {
        console.log("res: ", res);
        this.percentage = 100;
      },
      (error) => {
        console.log("error: ", error);
        this.error = true;
      }
    );
  }

  firebaseUpload() {
    const path = `${this.type}/${this.file.name}_${Date.now()}`;
    // Reference to storage bucket
    const ref = this.storage.ref(path);
    // The main task
    this.task = this.storage.upload(path, this.file);
    // Progress monitoring
    this.percentage = this.task.percentageChanges();
    this.snapshot = this.task.snapshotChanges().pipe(
      tap(console.log),
      // The file's download URL
      finalize(async () => {
        console.log("oa");
        this.percentage = 100;
        this.downloadURL = await ref.getDownloadURL().toPromise();
        let name = this.file.name.replace("-", " ");
        console.log(name);
        if (this.type == "home") {
          this.db
            .collection("home")
            .doc("r2fLi75yhf5OWti96Dg3")
            .update({ banner: this.downloadURL });
        } else if (this.type == "premium") {
          this.emitImage(this.downloadURL);
        }
      })
    );
  }

  emitImage(item) {
    console.log("SEND");
    this.myImage.emit(item);
  }

  isActive(snapshot) {
    return (
      snapshot.state === "running" &&
      snapshot.bytesTransferred < snapshot.totalBytes
    );
  }
}

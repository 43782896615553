<br>
<br>
<h3 class="bold center">Politica de reembolso</h3>
<div class="content_med">
    <br>
    <p class="bold">Cómo cancelar el servicio</p>

    <p> Para pagos con tarjeta de crédito:</p>
    <ul>
        <li>Desde el panel principal de la cuenta, haga clic en el menú superior derecho y haga clic en 'MI CUENTA'</li>
        <li>Haga clic en "ADMINISTRAR SUSCRIPCIÓN"</li>
        <li>Haga clic en 'Cancelar suscripción ahora'</li>
        <li>Aparecerá una ventana emergente para confirmar; haga clic en "Sí, cancelar"</li>
    </ul>
    <p> Para pagos con Paypal:</p>
    <ul>
        <li>Inicie sesión en su cuenta de PayPal a través de PayPal.com</li>
        <li>Haga clic en la pestaña 'Actividad'</li>
        <li>Haga clic en ‘U’HRemix</li>
        <li>Haga clic en 'Administrar pagos de UHRemix</li>
        <li>Haga clic en 'Cancelar'</li>
        <li>Aparecerá una ventana emergente para confirmar; haga clic en 'Cancelar perfil'</li>

    </ul>

    <br>
    <p class="bold">Cargos dobles</p>

    <p>Si ha visto cargos dobles en su cuenta, esto podría deberse a problemas con la renovación de una cuenta vencida o
        problemas técnicos al registrarse. Si nota que hay varios cargos para el mismo mes, contáctenos para que podamos
        investigar su cuenta. Recuerde que si renueva su cuenta con nueva información de tarjeta de crédito, debe volver
        a iniciar sesión en el sitio y cancelar su primera cuenta creada.</p>

    <br>
    <p class="bold">Cancelar 72 horas antes del pago recurrente</p>
    <p>Si desea cancelar su suscripción a UHRemix, asegúrese de cancelar al menos 72 horas antes de su pago recurrente
        para asegurarse de que no se procese un pago en su cuenta. Puede cancelar su cuenta en cualquier momento y aún
        tendrá acceso a su cuenta durante los 30 días completos que pagó. BPM Latino no emite ningún reembolso por
        ningún pago que ya se haya aplicado y no es responsable de cancelar cuentas.</p>


    <br>
    <p class="bold">Cómo recibir un reembolso</p>
    <p>Una vez que su reembolso haya sido aprobado por el Departamento de Facturación, espere de 5 a 7 días hábiles para
        que UHRemix procese el reembolso. El reembolso se enviará a la misma cuenta que se realizó el original.
        Entonces, si pagó con una tarjeta de crédito, su reembolso se enviará a esa tarjeta. Si pagó con Paypal, su
        reembolso se enviará a su cuenta de Paypal. Una vez que su establecimiento bancario acepte el reembolso, verá el
        reembolso en sus transacciones.</p>
    <br>
    <p class="bold">Reembolso de doble carga: sin reembolso durante 90 días</p>
    <p>Si ha visto cargos dobles en su cuenta, esto podría deberse a problemas con la renovación de una cuenta vencida o
        problemas técnicos al registrarse. Si nota que hay varios cargos para el mismo mes, contáctenos para que podamos
        investigar su cuenta. Recuerde que si renueva su cuenta con nueva información de tarjeta de crédito, debe volver
        a iniciar sesión en el sitio y cancelar su primera cuenta creada.</p>
    <br>
    <p class="bold">No se realizarán reembolsos después de que se haya aplicado el cargo</p>
    <p>Véase más arriba. UHRemix no emite ningún reembolso por ningún pago que ya se haya aplicado y no es responsable
        de cancelar cuentas.</p>
    <br>
    <p class="bold">Todas las ventas son finales</p>
    <p>UHRemix no emite reembolsos a menos que se trate de una facturación doble dentro de los 90 días.</p>
</div>
<div class="uh-card">
  <div class="uh-card-main">
    <div class="uh-card-main-3d"></div>
    <div class="uh-card-main-contain">
      <form class="form" [ngClass]="{ completeProfile: completeProfile }">
        <h1 *ngIf="!completeProfile">Crea tu Cuenta</h1>
        <div class="uh-input">
          <label>Ingresa tu Nombre:</label>
          <input
            required
            [(ngModel)]="name"
            [formControl]="form.controls.name"
            name="name"
            placeholder="Nombre Completo"
            type="text"
          />
          <p
            class="error"
            *ngIf="
              form.get('name').touched && form.get('name').hasError('required')
            "
          >
            Campo obligatorio
          </p>
          <p
            class="error"
            *ngIf="
              form.get('name').touched && form.get('name').hasError('pattern')
            "
          >
            Solo se permite los siguientes caracteres. (a-z A-Z)
          </p>
        </div>
        <div class="uh-input">
          <label>Ingresa tu Email:</label>
          <input
            required
            [(ngModel)]="mail"
            [formControl]="form.controls.mail"
            name="mail"
            placeholder="Dirección Email"
            type="email"
          />
          <p
            class="error"
            *ngIf="
              form.get('mail').touched && form.get('mail').hasError('required')
            "
          >
            Campo obligatorio
          </p>
          <p
            class="error"
            *ngIf="
              form.get('mail').touched && form.get('mail').hasError('email')
            "
          >
            Ingresa un correo electronico valido
          </p>
        </div>
        <div class="uh-input">
          <label>Ingresa tu Contraseña:</label>
          <input
            required
            [(ngModel)]="password"
            [formControl]="form.controls.password"
            name="password"
            placeholder="Contraseña"
            type="password"
            *ngIf="!completeProfile"
          />
          <p
            class="error"
            *ngIf="
              form.get('password').touched &&
              form.get('password').hasError('required')
            "
          >
            Campo obligatorio
          </p>
          <p
            class="error"
            *ngIf="
              form.get('password').touched &&
              form.get('password').hasError('minlength')
            "
          >
            Solo se permite como minimo 6 caracteres
          </p>
        </div>
        <div class="uh-input">
          <label>Ingresa tu Teléfono:</label>
          <input
            required
            [(ngModel)]="phone_code"
            [formControl]="form.controls.phone_code"
            list="phone-coodes"
            name="browser"
            id="browser"
            placeholder="Número de telefono"
            class="phone-code"
          />
          <datalist id="phone-coodes">
            <option *ngFor="let n of numbers" [value]="n.code">
              {{ n.display }}
            </option>
          </datalist>
          <input
            required
            [(ngModel)]="phone"
            [formControl]="form.controls.phone"
            name="phone"
            placeholder="Celular"
            type="phone"
            maxlength="10"
            minlength="10"
            class="phone"
          />
          <p
            class="error"
            *ngIf="
              form.get('phone_code').touched &&
              form.get('phone_code').hasError('required')
            "
          >
            El Codigo del telefono es un campo obligatorio
          </p>
          <p
            class="error"
            *ngIf="
              form.get('phone').touched &&
              form.get('phone').hasError('required')
            "
          >
            El Telefono es un campo obligatorio
          </p>
          <p
            class="error"
            *ngIf="
              form.get('phone').touched && form.get('phone').hasError('pattern')
            "
          >
            Solo se permite los siguientes caracteres. (0-9)
          </p>
          <p
            class="error"
            *ngIf="
              form.get('phone').touched &&
              form.get('phone').hasError('minlength')
            "
          >
            Solo se permite como minimo 10 caracteres
          </p>
          <p
            class="error"
            *ngIf="
              form.get('phone').touched &&
              form.get('phone').hasError('maxlength')
            "
          >
            Solo se permite como maximo 10 caracteres
          </p>
        </div>
      </form>
      <div [ngClass]="{ completeProfile: completeProfile }" class="form">
        <div class="mcwidget-checkbox" data-widget-id="16621268"></div>
        <button (click)="signUp()">
          {{ !completeProfile ? "Registrarse Ahora" : "Completar registro" }}
        </button>
        <a routerLink="/signup">
          Al
          {{ !completeProfile ? "registrarse" : "completar el registro" }}
          aceptas los
          <span
            ><a href="/#/terminos-y-condiciones" target="_blank"
              >Terminos de uso</a
            ></span
          >
          <br />
          y la
          <span
            ><a href="/#/aviso-de-privacidad" target="_blank"
              >Pólitica de privacidad</a
            ></span
          ></a
        >
        <a routerLink="/v2/login" *ngIf="!completeProfile"
          >¿Ya tienes cuenta? <span>Ingresa aquí</span></a
        >
      </div>
    </div>
  </div>
</div>
<app-modal
  [visible]="visible"
  [text]="text"
  [title]="title"
  [link]="link"
></app-modal>

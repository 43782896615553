import { Component, OnInit } from '@angular/core';
import { DbService } from 'src/app/services/db.service';

@Component({
  selector: 'app-adminvideos',
  templateUrl: './adminvideos.component.html',
  styleUrls: ['./adminvideos.component.scss']
})
export class AdminvideosComponent implements OnInit {
  genre = "Otros";
  genres = []
  files: any = [];
  alsoPublic = false;
  constructor(private db: DbService) {}

  ngOnInit(): void {
    this.db.getGenres().subscribe((res)=>{
      console.log(res)
      this.genres = res;
    });
  }

  uploadFile(event) {
    for (let index = 0; index < event.length; index++) {
      const element = event[index];
      this.files.push(element.name)
    }  
  }
  deleteAttachment(index) {
    this.files.splice(index, 1)
  }

  togglePublic(){
    console.log(this.alsoPublic)
    this.alsoPublic = !this.alsoPublic
  }
}

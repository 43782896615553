<div class="newuser">
  <h3>Usuarios:</h3>
</div>
<div class="controlls">
  <button [routerLink]="['/admin/newuser']">Crear nuevo usuario</button>
  <button (click)="exportAll()">Exportar usuarios</button>
</div>
<div class="newuser">
  <label for="memberships">Filtrar Membresias: {{fechabusqueda | date: 'dd/MM/yyyy'}} <span *ngIf="fechabusquedafinal"> hasta  {{fechabusquedafinal | date: 'dd/MM/yyyy'}} </span><span *ngIf="form.controls.email.value "> {{form.controls.email.value}}</span> <span *ngIf="form.controls.filter.value !== 'todas' "> | Mostrar {{form.controls.filter.value}}</span> <span *ngIf="fechabusquedafinal || form.controls.filter.value !== 'todas' || form.controls.email.value"> | </span> <span class="eraseFilters" (click)="cleanForm()" *ngIf="fechabusquedafinal || form.controls.filter.value !== 'todas' || form.controls.email.value"><i class="fas fa-times"></i> Borrar filtros</span></label>
</div>
<form action="none" method="dialog" [formGroup]="form">
  <div class="inputform">
    <label for="buscar">Correo electronico</label>
    <input placeholder="Correo electronico" name="buscar" id="buscar" formControlName="email" />
  </div>
  <div class="inputform" *ngIf="!form.controls.email.value">
    <label for="fechainicial">Desde</label>
    <input
      placeholder="Desde inicial"
      type="date"
      class="input"
      name="fechainicial"
      id="fechainicial"
      formControlName="fechainicial"
    />
  </div>
  <div class="inputform" *ngIf="!form.controls.email.value">
    <label for="fechafinal">Hasta</label>
    <input
      placeholder="Hasta final"
      type="date"
      class="input"
      name="fechafinal"
      id="fechafinal"
      formControlName="fechafinal"
    />
  </div>
  <div class="inputform" *ngIf="!form.controls.email.value">
    <label for="mostrar">Mostrar</label>
    <select class="selector" name="mostrar" id="mostrar" formControlName="filter">
      <option value="todas">Ver todas</option>
      <option value="activas">Ver activas</option>
      <option value="inactivas">Ver inactivas</option>
      <option value="canceladas">Ver canceladas</option>
    </select>
  </div>
  <div class="inputform">
    <button (click)="changeMemb()">Buscar</button>
  </div>
</form>
<div class="tableee">
  <div class="content">
    <ng-container *ngFor="let user of filterUsers">
      <div class="row" *ngIf="user.show">
        <i
          class="fas fa-pen"
          [routerLink]="['/admin/users', user.id, user.name]"
        ></i>
        <span class="status">
          <span
            *ngIf="user.membership != undefined"
            [ngClass]="{ active: user.membership.status == 'active' }"
          ></span>
          <span *ngIf="user.membership == undefined"></span>
        </span>
        <a class="mail name bold">{{ user.name }}</a>
        <p class="mail bold">{{ user.email }}</p>
        <p class="phone">{{ user.ip }}</p>
        <a class="phone bold">{{ user.phone }}</a>
        <a *ngIf="user.tokens != undefined" class="tokens bold"
          >{{ user.tokens }} tokens</a
        >
        <a *ngIf="user.tokens == undefined" class="tokens bold">0 tokens</a>
        <p class="name">Creado el: {{ user.cDate }}</p>
        <span (click)="giveDays(user)" class="moredays icon"></span>
        <span (click)="givePoints(user)" class="morepoints icon"></span>
        <p *ngIf="user.cancelTime != undefined" class="name">
          Cancelo el: {{ user.cancelTime }}
        </p>
        <p *ngIf="user.membership != undefined" class="lname">
          empieza: {{ user.startDate }}, termina: {{ user.endDate }}
        </p>
      </div>
    </ng-container>
  </div>
</div>
<ngx-spinner
  bdColor="rgba(10, 14, 31,0.8)"
  size="medium"
  color="#0066ff"
></ngx-spinner>

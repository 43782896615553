<div class="form">
    <div class="background"></div>
    <div class="r">
        <h2>¿Tienes un problema, quejas o alguna recomendación?</h2>
        <h3>Estamos para servirte...</h3>
        <form class="box" ngNativeValidate (ngSubmit)="sendmail()">
            <div class="first">
                <label>Nombre</label>
                <input [(ngModel)]="name" name="name"required type="text" placeholder="Ulises Hernandez">

                <label>Correo</label>
                <input [(ngModel)]="mail" name="mail"required type="email" placeholder="uliseshernandez@gmail.com">

                <label>Telefono</label>
                <input [(ngModel)]="phone" name="phone" required type="tel" placeholder="+52 352 112 8021">
            </div>
            <div class="second">
                <label>Mensaje</label>
                <textarea [(ngModel)]="message" name="message"placeholder="¡Me encanta la plataforma, siempre tienen las mejores canciones!" requried rows="11"></textarea>
            </div>
            <button class=" third full" type="submit">Enviar Correo</button>


            <!-- (click)="sendmail()" -->
        </form>
    </div>
</div>

<app-modal [visible]="visible" title="Correo enviado exitosamente" text="Gracias por tu opinion, pronto nos pondremos en contacto contigo" link="/home"></app-modal>
import { Injectable } from "@angular/core";

import "rxjs/add/operator/mergeMap";

import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError, BehaviorSubject, of } from "rxjs";
import { catchError, filter, take, switchMap } from "rxjs/operators";
import { Router } from "@angular/router";

import { AuthService } from "../services/auth.service";
@Injectable()
export class FirebaseInterceptor implements HttpInterceptor {
  constructor(private auth: AuthService) {}

  // intercept(
  //   request: HttpRequest<any>,
  //   next: HttpHandler
  // ): Observable<HttpEvent<any>> {
  //   request = this.addToken(request, "OA");
  //   return next.handle(request).pipe(
  //     catchError((error) => {
  //       if (error instanceof HttpErrorResponse) {
  //         return throwError(error);
  //       } else {
  //         return throwError(error);
  //       }
  //     })
  //   );
  // }

  // private addToken(request: HttpRequest<any>, token: string) {
  //   return request.clone({
  //     setHeaders: {
  //       authorization: `Bearer  asdasdasd${token}`,
  //       // 'Set-Cookie': 'HttpOnly;Secure;SameSite=Strict'
  //     },
  //   });
  // }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.auth.getUserToken().mergeMap((token) => {
      if(request.url == "https://api.ipify.org/?format=json") return next.handle(request)
      const req = request.clone({
        headers: request.headers
          .set("authorization", `Bearer ${token}`)
      });
      return next.handle(req);
    });
  }
}

import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: "root",
})
export class IpService {
  constructor(private http: HttpClient) {}
  public getIPAddress() {
    return this.http.get("https://api.ipify.org/?format=json");
  }
  public async getIPAddressPromise() {
    return await new Promise(resolve => {
      this.http.get("https://api.ipify.org/?format=json").subscribe((data: any) => {
        console.log(data);
        resolve({
          ip: data.ip
        })
      });
    })
  }
}


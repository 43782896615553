<ng-container *ngIf="type!='premium' && type!='home'">
  <p class="bold"> {{file.name}} </p>
  <div [ngClass]="{'active':error}">
    <progress [value]="percentage" max="100"></progress>
    {{ percentage }}
  </div>
</ng-container>
<ng-container *ngIf="type=='premium' || type=='home'">
  <div *ngIf="snapshot | async as snap">
    {{ snap.bytesTransferred }} of {{ snap.totalBytes }}
  </div>
  <p class="bold"> {{file.name}} </p>
  <div [ngClass]="{'active':error}">
    <progress [value]="percentage" max="100"></progress>
    {{ percentage }}
  </div>
</ng-container>
<br>
<br>
<h3 class="bold center">Terminos y condiciones</h3>
<div class="content_med">
    <p>Este contrato describe los términos y condiciones generales aplicables al uso de los servicios ofrecidos por
        UHRemix , dentro del sitio https://www.uhremix.com Cualquier persona que desee acceder y/o usar el sitio o los
        servicios podrá hacerlo sujetándose a los Términos y Condiciones Generales, junto con todas las demás políticas
        y principios que rigen UHRemix . y que son incorporados al presente por referencia.
        CUALQUIER PERSONA QUE NO ACEPTE ESTOS TÉRMINOS Y CONDICIONES GENERALES, LOS CUALES TIENEN UN CARÁCTER
        OBLIGATORIO Y VINCULANTE, DEBERÁ ABSTENERSE DE UTILIZAR EL SITIO Y/O LOS SERVICIOS.
    </p>

    <p>El Usuario debe leer, entender y aceptar todas las condiciones establecidas en los Términos y Condiciones
        Generales y en el Aviso de Privacidad, así como en los demás documentos incorporados a los mismos por
        referencia, previo a su inscripción como Usuario de https://www.uhremix.com </p>

    <p>La utilización del Sitio y/o los Servicios por parte del Usuario, implica su consentimiento expreso, libre,
        informado, específico e inequívoco a los Avisos de Privacidad de UHRemix., así como su consentimiento expreso de
        todas las políticas establecidas en las Condiciones Generales y sus Anexos, y demás políticas y principios
        incorporados a las mismas por referencia.</p>


    <br>
    <p class="bold">Capacidad</p>

    <p>Los Servicios sólo están disponibles para personas que tengan capacidad legal para contratar. No podrán utilizar
        los servicios las personas que no tengan esa capacidad, los menores de edad o Usuarios de UHRemix que hayan sido
        suspendidos temporalmente o inhabilitados definitivamente. Si estás inscribiendo un Usuario como Empresa, debes
        tener capacidad para contratar a nombre de tal entidad y de obligar a la misma en los términos de este Acuerdo.
    </p>

    <br>
    <p class="bold">Inscripción</p>

    <p>Es obligatorio completar el formulario de inscripción en todos sus campos con datos válidos para poder utilizar
        los servicios que brinda UHRemix. El futuro Usuario deberá completarlo con su información personal de manera
        exacta, precisa y verdadera (en adelante, los "Datos Personales") y asume el compromiso de actualizar y/o
        rectificar los Datos Personales conforme resulte necesario. El Usuario autoriza a UHRemix a que utilice diversos
        medios para identificar sus datos personales, asumiendo el Usuario la obligación de revisarlos, rectificarlos y
        mantenerlos actualizados. UHRemix NO se responsabiliza por la certeza de los Datos Personales de sus Usuarios.
        Los Usuarios garantizan y responden, en cualquier caso, de la veracidad, exactitud, vigencia y autenticidad de
        sus Datos Personales.</p>

    <p>UHRemix se reserva el derecho de solicitar algún comprobante y/o dato adicional a efectos de corroborar los Datos
        Personales, así como de suspender temporal o definitivamente a aquellos Usuarios cuyos datos no hayan podido ser
        confirmados. En estos casos de inhabilitación, NO SERÁN VIGENTES precios pactados, sin que ello genere algún
        derecho a resarcimiento.</p>

    <p>Como parte del proceso para completar el formulario de inscripción, para poder utilizar su cuenta en el Sitio,
        así como realizar cualquier función en el Sitio o hacer uso de los Servicios, el Usuario autoriza como medios de
        autenticación aquellos medios electrónicos, tales como, número de identificación personal, huella digital, firma
        electrónica, ¿?? o cualquier otro que determine UHRemix</p>

    <p>El Usuario accederá a su cuenta personal ("Cuenta") mediante el ingreso de su nombre de usuario o Seudónimo junto
        a la clave de seguridad personal elegida ("Clave de Seguridad"). El Usuario se obliga a mantener la
        confidencialidad de su Clave de Seguridad.</p>

    <p>La Cuenta es personal, única e intransferible, y está prohibido que un mismo Usuario inscriba o posea más de una
        Cuenta. En caso que UHRemix detecte distintas Cuentas que contengan datos coincidentes o relacionados, podrá
        cancelar, suspender o inhabilitarlas.</p>

    <p>El Usuario será responsable por todas las operaciones efectuadas en su Cuenta, pues el acceso a la misma está
        restringido al ingreso y uso de su Clave de Seguridad, de conocimiento exclusivo del Usuario. El Usuario se
        compromete a notificar a UHRemix en forma inmediata y por medio idóneo y fehaciente, cualquier uso no autorizado
        de su Cuenta, así como el ingreso por terceros no autorizados a la misma. Se aclara que está prohibida la venta,
        cesión o transferencia de la Cuenta (incluyendo la reputación y opiniones) bajo ningún título.</p>

    <p>UHRemix se reserva el derecho de rechazar cualquier solicitud de inscripción o de cancelar una inscripción
        previamente aceptada, sin que esté obligado a comunicar o exponer las razones de su decisión y sin que ello
        genere algún derecho a indemnización o resarcimiento.</p>

    <br>
    <p class="bold">Modificaciones del Acuerdo</p>

    <p>UHRemix podrá modificar los Términos y Condiciones Generales en cualquier momento haciendo públicos en el Sitio
        los términos modificados. Todos los términos modificados entrarán en vigor a los 10 (diez) días de su
        publicación. Dichas modificaciones serán comunicadas por UHRemix a los usuarios que en la Configuración de su
        Cuenta de UHRemix hayan indicado que desean recibir notificaciones de los cambios en estos Términos y
        Condiciones. Todo usuario que no esté de acuerdo con las modificaciones efectuadas por UHRemix podrá solicitar
        la baja de la cuenta.</p>

    <p>El uso del sitio y/o sus servicios implica la aceptación de estos Términos y Condiciones generales de uso de
        UHRemix</p>

    <br>
    <p class="bold">Utilización de PayPal y Stripe</p>

    <p>Al publicar un anuncio en el Sitio, el usuario consiente expresamente la utilización de Paypal y Stripe como una
        de las formas disponibles para el pago. Asimismo, el usuario declara que ha leído, comprendido y aceptado los
        Términos y Condiciones de utilización de dicho servicio.</p>


    <br>
    <p class="bold">Fallas en el sistema</p>

    <p>UHRemix no se responsabiliza por cualquier daño, perjuicio o pérdida al Usuario causados por fallas en el
        sistema, en el servidor o en Internet. UHRemix tampoco será responsable por cualquier virus que pudiera infectar
        el equipo del Usuario como consecuencia del acceso, uso o examen de su sitio web o a raíz de cualquier
        transferencia de datos, archivos, imágenes, textos, o audio contenidos en el mismo. Los Usuarios NO podrán
        imputarle responsabilidad alguna ni exigir pago por lucro cesante, en virtud de perjuicios resultantes de
        dificultades técnicas o fallas en los sistemas o en Internet. UHRemix no garantiza el acceso y uso continuado o
        ininterrumpido de su sitio. El sistema puede eventualmente no estar disponible debido a dificultades técnicas o
        fallas de Internet, o por cualquier otra circunstancia ajena a UHRemix; en tales casos se procurará
        restablecerlo con la mayor celeridad posible sin que por ello pueda imputársele algún tipo de responsabilidad.
        UHRemix no será responsable por ningún error u omisión contenidos en su sitio web.</p>

    <br>
    <p class="bold">Jurisdicción y Ley Aplicable</p>

    <p>Este acuerdo estará regido en todos sus puntos por las leyes vigentes en la Republica Mexicana, en particular
        respecto de mensajes de datos, contratación electrónica y comercio electrónico se regirá por lo dispuesto por la
        legislación federal respectiva.
        Cualquier controversia derivada del presente acuerdo,
    </p>

    <p>su existencia, validez, interpretación, alcance o cumplimiento, será sometida a las leyes aplicables y a los
        Tribunales competentes.</p>

    <p>Para la interpretación, cumplimiento y ejecución del presente contrato, las partes expresamente se someten a la
        jurisdicción de los tribunales competentes de la Ciudad de México, Distrito Federal, renunciando en consecuencia
        a cualquier fuero que en razón de su domicilio presente o futuro pudiera corresponderles.</p>

    <br>
    <p class="bold">Domicilio</p>

    <p>Aquiles 169 fracc delta la piedad Michoacan mexico</p>
</div>



<p></p>
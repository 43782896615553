import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "../services/auth.service";
import { DbService } from "../services/db.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuardGuard implements CanActivate {
  constructor(
    private auth: AuthService,
    private router: Router,
    private db: DbService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      // return resolve(true);
      try {
        this.auth.getUser().then((res) => {
          if (res == null) {
            reject();
            this.router.navigateByUrl("/login");
          }
          return resolve(true);
          // if (state.url == "/myaccount") return resolve(true);
          // this.db.getUser(res.uid).subscribe((user: any) => {
          // if (user.isAdmin) return resolve(true);
          // if (user.isVerified) return resolve(true);
          // reject();
          // this.router.navigateByUrl("/confirm-email");
          // });
        });
      } catch (error) {
        reject(error);
      }
    });
  }
}

import { Component, OnInit } from "@angular/core";
import * as firebase from "firebase/app";

@Component({
  selector: "app-contact",
  templateUrl: "./contact.component.html",
  styleUrls: ["./contact.component.scss"],
})
export class ContactComponent implements OnInit {
  constructor() {}
  name= ""
  mail = ""
  phone = ""
  message = ""
  visible = false
  ngOnInit(): void {}

  sendmail() {
    let msg = 
`Nombre
${this.name}

Correo
${this.mail}

Telefono
${this.phone}

Mensaje
${this.message}
`
    let sendcorreo = firebase.functions().httpsCallable("sendcorreo");
    this.visible = true;
    sendcorreo({text:msg}).then(
      (result) => {
        console.log(result);
      },
      (error) => {
        console.log(error);
      }
    );
  }
}

import { NgxSpinnerService } from "ngx-spinner";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore } from "@angular/fire/firestore";
import * as firebase from "firebase/app";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/fromPromise";
import { first, take, switchMap } from "rxjs/operators";
import { DbService } from "./db.service";
import { MysqlService } from "./mysql.service";
import { environment } from "src/environments/environment";

@Injectable()
export class AuthService {
  private user: Observable<firebase.User>;
  private userDetails: firebase.User = null;
  tokens = 100;
  actionCodeSettings = {
    url: environment.emailRedirect + "/#/confirm-email",
    handleCodeInApp: true,
  };
  constructor(
    public afAuth: AngularFireAuth,
    private firestore: AngularFirestore,
    private router: Router,
    private db: DbService,
    private mysql: MysqlService,
    private spinner: NgxSpinnerService
  ) {
    db.getHome().subscribe((res) => {
      this.tokens = res[0].tokens;
    });
    // if (localStorage.getItem("waiting") == "true") this.spinner.show();
    // firebase
    //   .auth()
    //   .getRedirectResult()
    //   .then((c) => {
    //     this.spinner.hide();
    //     if (c.user) {
    //       localStorage.getItem("waiting") == "false";
    //       if (c.additionalUserInfo.isNewUser)
    //         this.firestore
    //           .doc(`/users/${c.user.uid}`)
    //           .set({
    //             name: c.user.displayName,
    //             email: c.user.email,
    //             phone: c.user.phoneNumber,
    //             tokens: this.tokens,
    //             isVerified: true,
    //           })
    //           .then((res) => {
    //             this.router.navigate(["/confirm-email"]);
    //           });
    //     }
    //   })
    //   .catch((err) => {
    //     localStorage.getItem("waiting") == "false";
    //     this.spinner.hide();
    //     window.alert(err.message);
    //   });
  }

  loginUser(email: string, password: string) {
    // return firebase
    //   .auth()
    //   .sendSignInLinkToEmail(email, this.actionCodeSettings)
    //   .then((res) => {
    //     return res;
    //   })
    return this.afAuth.signInWithEmailAndPassword(email, password);
  }

  signupUser(email, password, name, phone): Promise<any> {
    return this.afAuth
      .createUserWithEmailAndPassword(email, password)
      .then((newUserCredential: firebase.auth.UserCredential) => {
        return firebase
          .firestore()
          .doc(`/users/${newUserCredential.user.uid}`)
          .set({ name, email, phone, tokens: this.tokens, isVerified: true });
      })
      .catch((error) => {
        console.error(error);
        throw new Error(error);
      });
  }

  async signupUserFacebook() {
    const provider = new firebase.auth.FacebookAuthProvider();
    return this.afAuth
      .signInWithPopup(provider)
      .then(async (c: any) => {
        localStorage.setItem("userId", c.user.uid);
        if (!c.additionalUserInfo.isNewUser) {
          return true;
        }
        return this.firestore.doc(`/users/${c.user.uid}`).set({
          name: c.user.displayName,
          email: c.user.email,
          phone: c.user.phoneNumber,
          tokens: this.tokens,
          isVerified: true,
        });
      })
      .catch((error) => {
        console.error(error);
        throw new Error(error);
      });
  }

  async signupUserGoogle() {
    const provider = new firebase.auth.GoogleAuthProvider();
    return this.afAuth
      .signInWithPopup(provider)
      .then(async (c: any) => {
        localStorage.setItem("userId", c.user.uid);
        if (!c.additionalUserInfo.isNewUser) {
          return true;
        }
        return this.firestore.doc(`/users/${c.user.uid}`).set({
          name: c.user.displayName,
          email: c.user.email,
          phone: c.user.phoneNumber,
          tokens: this.tokens,
          isVerified: true,
        });
      })
      .catch((error) => {
        console.error(error);
        throw new Error(error);
      });
  }

  recoverPassword(emailAddress) {
    return this.afAuth.sendPasswordResetEmail(emailAddress).then();
  }

  getUser() {
    return this.afAuth.authState.pipe(first()).toPromise();
  }

  getUserToken() {
    return Observable.fromPromise(
      this.afAuth.idToken.pipe(first()).toPromise()
    );
  }

  logout() {
    this.afAuth.signOut().then((res) => {
      //@ts-ignore
      this.router.navigate('')
      window.location.reload();
    });
  }

  async paySubscription(price, uid, coupon, planPrice) {
    let payment_id;
    let stripe_id;
    firebase
      .firestore()
      .collection("users")
      .doc(uid)
      .get()
      .then((res) => {
        payment_id = res.data().payment_method.stripe_id;
        stripe_id = res.data().stripe_id;
        firebase
          .firestore()
          .collection("stripeSubscriptionIntent")
          .add({ payment_id, price, stripe_id, uid, coupon, planPrice });
      });
  }
}

<div class="newuser">
  <h3>Pagos:</h3>
  <!--  <h3 class="left"><span>Total: </span>{{ total }}.00 <span> mxn</span></h3> -->
</div>
<div class="controlls">
  <button (click)="exportAll(true)">Exportar pagos</button>
  <!-- <button (click)="exportAll(true)">Exportar pagos con detalle de usuario</button> -->
</div>
<div class="newuser">
  <label for="memberships"
    >Filtrar Pagos: {{ fechabusqueda | date: "dd/MM/yyyy" }}
    <span *ngIf="fechabusquedafinal">
      hasta {{ fechabusquedafinal | date: "dd/MM/yyyy" }}
    </span>
    <span *ngIf="form.controls.filter.value !== 'todas'">
      | Mostrar {{ form.controls.filter.value }}</span
    >
    <span *ngIf="fechabusquedafinal || form.controls.filter.value !== 'todas'">
      |
    </span>
    <span
      class="eraseFilters"
      (click)="cleanForm()"
      *ngIf="fechabusquedafinal || form.controls.filter.value !== 'todas'"
      ><i class="fas fa-times"></i> Borrar filtros</span
    ></label
  >
</div>
<form action="none" method="dialog" [formGroup]="form">
  <div class="inputform">
    <label for="email">Correo electronico</label>
    <input
      placeholder="Correo electronico"
      type="text"
      class="input"
      name="email"
      id="email"
      [formControl]="email"
    />
  </div>
  <div class="inputform">
    <label for="fechainicial">Desde</label>
    <input
      placeholder="Desde inicial"
      type="date"
      class="input"
      name="fechainicial"
      id="fechainicial"
      formControlName="fechainicial"
    />
  </div>
  <div class="inputform">
    <label for="fechafinal">Hasta</label>
    <input
      placeholder="Hasta final"
      type="date"
      class="input"
      name="fechafinal"
      id="fechafinal"
      formControlName="fechafinal"
    />
  </div>
  <div class="inputform">
    <label for="mostrar">Mostrar</label>
    <select
      class="selector"
      name="mostrar"
      id="mostrar"
      formControlName="filter"
    >
      <option value="todas">Ver todas</option>
      <option value="stripe">Ver stripe</option>
      <option value="paypal">Ver paypal</option>
      <option value="oxxo">Ver oxxo</option>
      <option value="uh">Ver UH</option>
    </select>
  </div>
  <div class="inputform">
    <button (click)="loadMore()">Buscar</button>
  </div>
</form>

<!--
customer_id: "cus_JyVqaF16N5fZu5"
id: "cQykpCQ7PXekMKGqsVDI"
paymentIntent_id: "pi_3Js0CKD2DaxyRogK0zVgRpIP"
planPrice: 199
status: "active"
subscriptionEnd: t {seconds: 1638598492, nanoseconds: 0}
subscriptionStart: t {seconds: 1636006492, nanoseconds: 0}
subscription_id: "sub_JyaYq6tEapYs8d"
type: "stripe"
userId: "pTzZdKX89nR93N9ahtMZcuPyxO73"
 -->

<div class="tableee">
  <div class="content">
    <div class="row" *ngFor="let item of filterCharges">
      <i
        class="fas fa-pen"
        [routerLink]="['/admin/users', item.userId, item?.name]"
      ></i>
      <span  *ngIf="item.type != 'mercado pago'"
        class="status"
        [ngClass]="{
          stripe: item.type == 'stripe',
          oxxo: item.type == 'oxxo',
          paypal: item.type == 'paypal',
          uh: item.type == 'uh'
        }"
      ></span>
      <span *ngIf="item.type == 'mercado pago'" style="background-color: white; width: 50px; height: 29px; margin-inline: 10px; padding: 5px; border-radius: 5px;">
        <img src="/assets/icons/mercadopago.png" alt="" style="width: 50px;"> 
      </span>
      <p class="phone bold">${{ item.planPrice }}.00 <span>mxn</span></p>
      <p class="name">{{ item.subscriptionStart }}</p>
      <ng-container *ngIf="item.email">
        <p class="userDetailes">
          <span> {{ item.email }}</span>
          <span>|</span>
          <span> {{ item.name }}</span>
          <span>|</span>
          <span>{{ item.phone }}</span>
        </p>
      </ng-container>
      <ng-container *ngIf="!item.email">
        <div class="load" (click)="loadDetailsUser(item.id, false)">
          <a>Cargar datos de usuario</a>
        </div>
      </ng-container>
    </div>
  </div>
</div>
<ngx-spinner
  bdColor="rgba(10, 14, 31,0.8)"
  size="medium"
  color="#0066ff"
></ngx-spinner>

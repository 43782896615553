import { Router } from "@angular/router";
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  HostListener,
} from "@angular/core";
import { DbService } from "src/app/services/db.service";
import { AuthService } from "src/app/services/auth.service";
import { MysqlService } from "src/app/services/mysql.service";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
  public selected_type_currency: string = "mxn";

  @ViewChild("homeVideo", { read: ElementRef }) homeVideo: ElementRef;
  myHome = {
    banner: "",
    button: "",
    headline: "",
    text: "",
    isVideo: false,
  };
  valuesNames = [
    "Descargas Ilimitadas de Canciones ",
    "Descargas Ilimitadas de Videos ",
    "Descargas Ilimitadas de Karaoke ",
    "Descargas Ilimitadas de Combos ",
    "Creacion de playlist de descarga",
    "Historial de descargas",
    "Previsualización del contenido",
    "Descargas en un click",
    "Atención al cliente",
  ];
  plans = [
    {
      name: "AUDIO",
      link: "/payment/99",
      price: "99",
      priceUSD: "8",
      priceCOP: "30250",
      values: [true, false, false, false, false, false, true, true, true, true],
      repeat: "mensualmente",
    },
    {
      name: "AUDIO+VIDEO",
      link: "/payment/149",
      price: "149",
      priceUSD: "12",
      priceCOP: "45376",
      values: [true, true, false, false, false, false, true, true, true, true],
      repeat: "mensualmente",
    },
    {
      name: "FULL",
      link: "/payment/199",
      price: "199",
      priceUSD: "16",
      priceCOP: "60501",
      values: [true, true, true, true, true, true, true, true, true, true],
      repeat: "mensualmente",
    },
    // {
    //   name: "FULL ANUAL",
    //   link: "/payment/1899",
    //   price: "1899",
    //   priceUSD: "145",
    //   priceCOP: "548296",
    //   values: [true, true, true, true, true, true, true, true, true, true],
    //   repeat: "anualmente",
    // },
  ];

  sections = [
    {
      title: "Canciones",
      text: "Cientos de canciones, subdivididas por genero músical, todas descargables desde el momento de crear tu cuenta con la facilidad de un solo click.",
      image: "/assets/sections/music.png",
      icon: "/assets/sections/music.svg",
    },
    {
      title: "Videos",
      text: "Miles de videos, subdivididos por genero músical, todos descargables desde el momento de crear tu cuenta con la facilidad de un solo click.",
      image: "/assets/sections/video.png",
      icon: "/assets/sections/video.svg",
    },
    {
      title: "Karaoke",
      text: "Las mejores canciones, cuidadosamente reescritas para que tus karaokes siempre sean los mejores de la fiesta.",
      image: "/assets/sections/karaoke.png",
      icon: "/assets/sections/karaoke.svg",
    },
    {
      title: "Combos",
      text: "Carpetas cuidadosamente seleccionadas por nuestro equipo, para que tengas tus descargas ordenadas",
      image: "/assets/sections/combos.png",
      icon: "/assets/sections/combos.svg",
    },
    {
      title: "Playlist",
      text: "Arma tus propios paquetes para poderlos descargar, todo con la facilidad de un sencillo click",
      image: "/assets/sections/playlist.png",
      icon: "/assets/sections/playlist.svg",
    },
    // {
    //   title: "Premium",
    //   text: "Las descargas premium son paquetes exclusivos, con los mejores contenidos, se te proporcionara un link de descarga de BOX",
    //   image: "/assets/sections/premium.png",
    //   icon: "/assets/sections/premium.svg",
    // },
  ];

  constructor(
    private router: Router,
    private db: DbService,
    private auth: AuthService
  ) {}
  ngOnInit(): void {
    this.db.getHome().subscribe((res) => {
      this.myHome = res[0];
    });
  }

  ngAfterViewInit() {
    //Start home video
    try {
      const video: HTMLVideoElement = this.homeVideo.nativeElement;
      setTimeout(() => {
        video.muted = true;
        video.play();
      }, 500);
    } catch (error) {
      /* console.log(error); */
    }
  }
}

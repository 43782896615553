<div class="container">
  <h2>Descargas Premium</h2>
<!--   <p>
    Solo podras descargar los paquetes premium de los meses que hayas pagado tu
    membresia full, pronto podras adquirirlos individualmente.
  </p> -->
  <div class="downloads">
    <ng-container *ngFor="let item of premiums">
      <div class="download" *ngIf="!item.hide">
        <img [src]="item.image" />
        <div class="text">
          <h3>{{ item.title }}</h3>
          <p>{{ item.info }}</p>
          <a
            *ngIf="hasPaid && item.show"
            (click)="download(item)"
            target="_blank"
            class="button blue"
            >Descargar</a
          >
          <a
            *ngIf="hasPaid && item.month == undefined && !item.show"
            [href]="item.link"
            (click)="download(item)"
            target="_blank"
            class="button blue"
            >Descargar</a
          >

          <a *ngIf="!hasPaid" routerLink="/payment/199" class="button blue"
            >Solo usuarios full, mejora tu plan</a
          >

          <a
            *ngIf="hasPaid && !item.show"
            (click)="downloadDemo(item)"
            target="_blank"
            class="button outline blue"
            >Demo</a
          >
          <a
            *ngIf="!hasPaid"
            (click)="downloadDemo(item)"
            target="_blank"
            class="button outline blue"
            >Demo</a
          >
        </div>
      </div>
    </ng-container>
  </div>
</div>

<div class="generateZip" *ngIf="generateZip">
  <div class="mod">
    <p class="spinner">Estamos preparando tu descarga No cerrar esta ventana</p>
    <i class="fas fa-spinner"></i>
  </div>
</div>

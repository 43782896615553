import { Component, OnInit } from "@angular/core";
import * as firebase from "firebase/app";
import { Router } from "@angular/router";
import { DbService } from "src/app/services/db.service";
import { environment } from "src/environments/environment";
import { AuthService } from "src/app/services/auth.service";

@Component({
  selector: "app-confirm-email",
  templateUrl: "./confirm-email.component.html",
  styleUrls: ["./confirm-email.component.scss"],
})
export class ConfirmEmailComponent implements OnInit {
  actionCodeSettings = {
    url: environment.emailRedirect + "/#/confirm-email",
    handleCodeInApp: true,
  };
  visible = false;
  email = "";
  title = "";
  text = "";

  validEmail = false;
  navegadorPitero = false;
  error="";
  constructor(
    private router: Router,
    private db: DbService,
    private auth: AuthService
  ) {}

  ngOnInit(): void {
    if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
      var email = window.localStorage.getItem("emailForSignIn");
      if (!email) {
        email = window.prompt("Por favor confirma tu correo");
        this.navegadorPitero = true;
      }
      console.log(email);
      if (!email) return;
      firebase
        .auth()
        .signInWithEmailLink(email, window.location.href)
        .then((result) => {
          console.log(result);
          console.log(result.user.uid);
          this.validEmail = true;
          this.updateUser(result.user.uid)

          // .finally(() => {
          //   try {
          //     window.localStorage.removeItem("emailForSignIn");
          //     window.location.href = "/#/choose-plan";
          //   } catch (error) {
          //     console.log(error);
          //   }
          // });
        })
        .catch((error) => {
          console.log(error);
          window.alert(error);
        });
    }
  }

  async send() {
    const user = await this.auth.getUser();
    if(this.validEmail) this.updateUser(user.uid)
    return firebase
      .auth()
      .sendSignInLinkToEmail(user.email, this.actionCodeSettings)
      .then((res) => {
        window.localStorage.setItem("emailForSignIn", user.email);
        this.visible = true;
        this.title = "Correo Enviado";
        this.text = "Te ha llegado un correo de confirmación";
        return res;
      });
  }

  try() {
    console.log(this.email);
    firebase
      .auth()
      .signInWithEmailLink(this.email, window.location.href)
      .then((result) => {
        console.log(result);
        console.log(result.user.uid);
        this.validEmail = true;
        this.updateUser(result.user.uid)
      })
      .catch((error) => {
        console.log(error);
        window.alert(error);
        this.error = error
        this.navegadorPitero = true;
      });
  }

  updateUser(uid) {
    this.db
      .updateUser(uid, { isVerified: true })
      .then((res) => {
        console.log(res);
        window.location.href = "/#/choose-plan";
      })
      .catch((error) => {
        console.log(error);
        this.error = error

      });
  }
}

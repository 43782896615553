import { Component, OnInit } from "@angular/core";
import { DbService } from "src/app/services/db.service";

@Component({
  selector: "app-adminhome",
  templateUrl: "./adminhome.component.html",
  styleUrls: ["./adminhome.component.scss"],
})
export class AdminhomeComponent implements OnInit {
  myHome = {
    banner: "",
    button: "",
    headline: "",
    text: "",
    tokens: "",
    wa: "",
    fb: "",
    isVideo: false,
    hideWa: false,
    hideFb: false,
    hide99: false,
    hide149: false,
    hide199: false,
    hide1899: false,
    hide8: false,
    hide12: false,
    hide16: false,
    hide145: false,
    hide30250: false,
    hide45376: false,
    hide60501: false,
    hide548296: false,
  };
  constructor(private db: DbService) {}

  ngOnInit(): void {
    this.db.getHome().subscribe((res) => {
      console.log(res);
      this.myHome = res[0];
    });
  }

  toggleVid() {
    this.myHome.isVideo = !this.myHome.isVideo;
  }

  saveChanges() {
    console.log(this.myHome);
    this.db.updateHome(this.myHome);
  }
}

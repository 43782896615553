// export class FigmaService {
import { Injectable } from "@angular/core";

import { environment } from "src/environments/environment";
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: "root",
})
export class ActiveCampService {
  constructor(private http: HttpClient) {}

  getContent(id) {
    return this.http.get("https://api.paypal.com/v1/billing/subscriptions/"+id, {
      headers: {
        "Authorization": " Bearer QVppSUtGTDJzVjRkbU42bWlhR1NPMzZSaG1mWHk4UzlnbHpvam9MeVYtOTVEUmxwNnJUMTN0eEVmZDUzQjhoS2JhNzRjcGRmYnR5Rm81SnQ6RU92Ry13MDNuRHBDbjhQSnc2ZTlpM1daRUhyTHJCdjZsT05pQkJFakJucHIzYjFlRzlGeEJqUFJUdktibXR3SDhxQWRPa2l6c1p3cTlNb0I=",
      },
    });
  }
}

import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { DbService } from "src/app/services/db.service";
import { FormControl, FormGroup } from "@angular/forms";
import { ExportServices } from "src/app/services/export.services";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-cargos",
  templateUrl: "./cargos.component.html",
  styleUrls: ["./cargos.component.scss"],
})
export class CargosComponent implements OnInit {
  fechabusqueda: any = "";
  fechabusquedafinal: any = "";

  public email = new FormControl("");

  public form = new FormGroup({
    fechainicial: new FormControl(""),
    fechafinal: new FormControl(""),
    filter: new FormControl("todas"),
  });

  public filterCharges = [];

  constructor(
    private spinner: NgxSpinnerService,
    private db: DbService,
    private _export: ExportServices,
    private _activatedRoute: ActivatedRoute
  ) {}
  charges = [];
  total = 0;
  showStripe = false;
  async ngOnInit() {
    this._activatedRoute.params.subscribe(async (params) => {
      if (params.email) {
        this.email.setValue(params.email);
        this.spinner.show();
        let hasta: any = new Date();
        let desde: any = new Date();
        desde = new Date(desde.setDate(desde.getDate() - 90));
        console.log(desde);
        desde.setHours(0, 0, 0, 0);
        hasta.setHours(23, 59, 59, 59);
        this.fechabusqueda = desde;
        this.fechabusquedafinal = hasta;
        console.log(this.fechabusqueda);
        const refUser = this.db.afs
          .collection("users")
          .ref.where("email", "==", this.email.value);
        const colletionUser = await refUser.get();
        const documentsUser = colletionUser.docs;
        let users = documentsUser.map((d) => ({
          ...d.data(),
          id: d.id,
          subscriptionStart: d.data().subscriptionStart
            ? this.transformDate(d.data().subscriptionStart)
            : "",
          subscriptionEnd: d.data().subscriptionEnd
            ? this.transformDate(d.data().subscriptionEnd)
            : "",
        }));
        let user: any;
        if (users.length > 0) {
          user = users[0];
        }
        const ref = this.db.afs
          .collection("payments")
          .ref.where("userId", "==", user.id)
          .where("subscriptionStart", ">", desde)
          .where("subscriptionStart", "<", hasta)
          .orderBy("subscriptionStart", "desc");
        const colletion = await ref.get();
        const documents = colletion.docs;
        const data = documents.map((d) => ({
          ...d.data(),
          id: d.id,
          subscriptionStart: d.data().subscriptionStart
            ? this.transformDate(d.data().subscriptionStart)
            : "",
          subscriptionEnd: d.data().subscriptionEnd
            ? this.transformDate(d.data().subscriptionEnd)
            : "",
        }));
        this.charges = data;
        this.filterCharges = this.charges;
        let promises = [];
        this.filterCharges.forEach((charges) => {
          promises.push(this.loadDetailsUser(charges.id, true));
        });
        await Promise.all(promises);
        this.spinner.hide();
        if (this.filterCharges.length < 1) {
          alert("No hay pagos con los filtros seleccionados");
        }
        console.log(this.charges);
        this.spinner.hide();
        return;
      } else {
        this.spinner.show();
        let dateUser = new Date();
        dateUser.setHours(0, 0, 0, 0);
        dateUser.setDate(dateUser.getDate() - 30);
        let today = new Date();
        let day = today.getDate();
        let month: any = today.getMonth() + 1;
        month = month < 10 ? "0" + month : month;
        let year = today.getFullYear();
        this.fechabusquedafinal = new Date(`${month}/${day}/${year} 00:00`);
        this.fechabusqueda = dateUser;
        const ref = this.db.afs
          .collection("payments")
          .ref.where("subscriptionStart", ">", dateUser)
          .orderBy("subscriptionStart", "desc");
        const colletion = await ref.get();
        const documents = colletion.docs;
        const data = documents.map((d) => ({
          ...d.data(),
          id: d.id,
          subscriptionStart: d.data().subscriptionStart
            ? this.transformDate(d.data().subscriptionStart)
            : "",
          subscriptionEnd: d.data().subscriptionEnd
            ? this.transformDate(d.data().subscriptionEnd)
            : "",
        }));
        this.charges = data;
        this.filterCharges = data;
        let promises = [];
        this.filterCharges.forEach((charges) => {
          promises.push(this.loadDetailsUser(charges.id, true));
        });
        await Promise.all(promises);
        this.spinner.hide();
      }
    });
  }

  public transformDate(date) {
    let d;
    if (date != undefined) d = new Date(date.seconds * 1000);
    if (date == undefined) d = new Date();
    let options = { day: "numeric", month: "long", year: "numeric" };
    return d.toLocaleDateString("es-ES", options);
  }

  public cleanForm() {
    this.form.patchValue({
      filter: "todas",
      fechainicial: "",
      fechafinal: "",
    });
    this.fechabusquedafinal = "";
    this.fechabusqueda = "";
  }

  public async loadMore() {
    this.spinner.show();
    if (this.email.value) {
      const value = this.form.value;
      if (!value.fechainicial || !value.fechafinal) {
        alert("La fecha inicial y final son obligatorios");
        this.spinner.hide();
        return;
      }
      let desde: any = new Date(value.fechainicial);
      let hasta: any = new Date(value.fechafinal);
      desde.setDate(desde.getDate() + 1);
      hasta.setDate(hasta.getDate() + 1);
      desde.setHours(0, 0, 0, 0);
      hasta.setHours(23, 59, 59, 59);
      this.fechabusqueda = desde;
      this.fechabusquedafinal = hasta;
      const refUser = this.db.afs
        .collection("users")
        .ref.where("email", "==", this.email.value);
      const colletionUser = await refUser.get();
      const documentsUser = colletionUser.docs;
      let users = documentsUser.map((d) => ({
        ...d.data(),
        id: d.id,
        subscriptionStart: d.data().subscriptionStart
          ? this.transformDate(d.data().subscriptionStart)
          : "",
        subscriptionEnd: d.data().subscriptionEnd
          ? this.transformDate(d.data().subscriptionEnd)
          : "",
      }));
      let user: any;
      if (users.length > 0) {
        user = users[0];
      }
      const ref = this.db.afs
        .collection("payments")
        .ref.where("userId", "==", user.id)
        .where("subscriptionStart", ">", desde)
        .where("subscriptionStart", "<", hasta)
        .orderBy("subscriptionStart", "desc");
      const colletion = await ref.get();
      const documents = colletion.docs;
      const data = documents.map((d) => ({
        ...d.data(),
        id: d.id,
        subscriptionStart: d.data().subscriptionStart
          ? this.transformDate(d.data().subscriptionStart)
          : "",
        subscriptionEnd: d.data().subscriptionEnd
          ? this.transformDate(d.data().subscriptionEnd)
          : "",
      }));
      this.charges = data;
      this.filterCharges = this.charges;
      switch (value.filter) {
        case "stripe":
          this.filterCharges = this.filterCharges.filter(
            (fu) => fu.type === "stripe"
          );
          break;
        case "paypal":
          this.filterCharges = this.filterCharges.filter(
            (fu) => fu.type === "paypal"
          );
          break;
        case "oxxo":
          this.filterCharges = this.filterCharges.filter(
            (fu) => fu.type === "oxxo"
          );
          break;
        case "uh":
          this.filterCharges = this.filterCharges.filter(
            (fu) => fu.type === "uh"
          );
          break;
      }
      let promises = [];
      this.filterCharges.forEach((charges) => {
        promises.push(this.loadDetailsUser(charges.id, true));
      });
      await Promise.all(promises);
      this.spinner.hide();
      if (this.filterCharges.length < 1) {
        alert("No hay pagos con los filtros seleccionados");
      }
      return;
    }
    const value = this.form.value;
    if (!value.fechainicial || !value.fechafinal) {
      alert("La fecha inicial y final son obligatorios");
      this.spinner.hide();
      return;
    }
    let desde: any = new Date(value.fechainicial);
    let hasta: any = new Date(value.fechafinal);
    desde.setDate(desde.getDate() + 1);
    hasta.setDate(hasta.getDate() + 1);
    desde.setHours(0, 0, 0, 0);
    hasta.setHours(23, 59, 59, 59);
    this.fechabusqueda = desde;
    this.fechabusquedafinal = hasta;
    const ref = this.db.afs
      .collection("payments")
      .ref.where("subscriptionStart", ">", desde)
      .where("subscriptionStart", "<", hasta)
      .orderBy("subscriptionStart", "desc");
    const colletion = await ref.get();
    const documents = colletion.docs;
    const data = documents.map((d) => ({
      ...d.data(),
      id: d.id,
      subscriptionStart: d.data().subscriptionStart
        ? this.transformDate(d.data().subscriptionStart)
        : "",
      subscriptionEnd: d.data().subscriptionEnd
        ? this.transformDate(d.data().subscriptionEnd)
        : "",
    }));
    this.charges = data;
    this.filterCharges = this.charges;
    switch (value.filter) {
      case "stripe":
        this.filterCharges = this.filterCharges.filter(
          (fu) => fu.type === "stripe"
        );
        break;
      case "paypal":
        this.filterCharges = this.filterCharges.filter(
          (fu) => fu.type === "paypal"
        );
        break;
      case "oxxo":
        this.filterCharges = this.filterCharges.filter(
          (fu) => fu.type === "oxxo"
        );
        break;
      case "uh":
        this.filterCharges = this.filterCharges.filter(
          (fu) => fu.type === "uh"
        );
        break;
    }
    let promises = [];
    this.filterCharges.forEach((charges) => {
      promises.push(this.loadDetailsUser(charges.id, true));
    });
    await Promise.all(promises);
    this.spinner.hide();
    if (this.filterCharges.length < 1) {
      alert("No hay pagos con los filtros seleccionados");
    }
  }

  public async loadDetailsUser(id, hideAlert: boolean) {
    let paymentIndex = this.filterCharges.findIndex((f) => f.id === id);
    let payment = this.filterCharges.find((f) => f.id === id);
    const doc = await this.db.afs
      .collection("users")
      .doc(payment.userId)
      .ref.get();
    if (!doc.exists) {
      if (!hideAlert) {
        alert("No hay datos de este usuario");
      }
    }
    const data: any = {
      ...doc.data(),
      id: doc.id,
    };
    this.filterCharges[paymentIndex] = {
      ...payment,
      name: data.name,
      email: data.email,
      phone: data.phone,
    };
  }

  public async exportAll(withDetails: boolean): Promise<void> {
    let promises = [];
    if (withDetails) {
      this.filterCharges.forEach((charges) => {
        promises.push(this.loadDetailsUser(charges.id, true));
      });
    }
    await Promise.all(promises);
    let data = [];
    this.filterCharges.forEach((fc) => {
      data.push({
        Tipo: fc.type,
        Precio: fc.planPrice,
        Fecha: fc.subscriptionStart,
        Correo: fc.email,
        Nombre: fc.name,
        Telefono: fc.phone,
      });
    });
    this._export.export(data, "pagos");
  }
}

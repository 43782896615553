import { Component, OnInit } from "@angular/core";
import * as firebase from "firebase";
import { AuthService } from "src/app/services/auth.service";
import { DbService } from "src/app/services/db.service";
import {
  eventFacebook,
  EventFacebookService,
} from "src/app/services/event-facebook.service";
import { PlanService } from "src/app/services/plan.service";

declare var window: any;
declare var fbq: any;

@Component({
  selector: "app-plans",
  templateUrl: "./plans.component.html",
  styleUrls: ["./plans.component.scss"],
})
export class PlansComponent implements OnInit {
  public selected_type_currency: string = "mxn";

  constructor(
    private _eventFacebook: EventFacebookService,
    private auth: AuthService,
    public db: DbService,
    public _plan: PlanService
  ) {}
  valuesNames = [
    "Descargas Ilimitadas de Canciones ",
    "Descargas Ilimitadas de Videos ",
    "Descargas Ilimitadas de Karaoke ",
    "Descargas Ilimitadas de Combos ",
    // "Descargas Premium",
    "Creacion de playlist de descarga",
    "Historial de descargas",
    "Previsualización del contenido",
    "Descargas en un click",
    "Atención al cliente",
  ];
  plans = [
    {
      plan: "plan_1",
      name: "AUDIO",
      link: "/payment/",
      price: "99",
      priceUSD: "8",
      priceCOP: "30250",
      values: [true, false, false, false, false, false, true, true, true, true],
      repeat: "mensualmente",
      hide: false,
      hideUSD: false,
      hideCOP: false,
    },
    {
      plan: "plan_2",
      name: "AUDIO+VIDEO",
      link: "/payment/",
      price: "149",
      priceUSD: "12",
      priceCOP: "45376",
      values: [true, true, false, false, false, false, true, true, true, true],
      repeat: "mensualmente",
      hide: false,
      hideUSD: false,
      hideCOP: false,
    },
    {
      plan: "plan_3",
      name: "FULL",
      link: "/payment/",
      price: "199",
      priceUSD: "16",
      priceCOP: "60501",
      values: [true, true, true, true, true, true, true, true, true, true],
      repeat: "mensualmente",
      hide: false,
      hideUSD: false,
      hideCOP: false,
    },
    // {
    //   plan: "plan_4",
    //   name: "FULL ANUAL",
    //   link: "/payment/",
    //   price: "1899",
    //   priceUSD: "145",
    //   priceCOP: "548296",
    //   values: [true, true, true, true, true, true, true, true, true, true],
    //   repeat: "anualmente",
    //   hide: false,
    //   hideUSD: false,
    //   hideCOP: false,
    // },
  ];

  myHome = {
    banner: "",
    button: "",
    headline: "",
    text: "",
    tokens: "",
    wa: "",
    fb: "",
    isVideo: false,
    hideWa: false,
    hideFb: false,
    hide99: false,
    hide149: false,
    hide199: false,
    hide1899: false,
    hide8: false,
    hide12: false,
    hide16: false,
    hide145: false,
    hide30250: false,
    hide45376: false,
    hide60501: false,
    hide548296: false,
  };

  ngOnInit(): void {
    this.db.getHome().subscribe((res) => {
      console.log(res);
      this.myHome = res[0];
      this.plans[0].hide = this.myHome.hide99;
      this.plans[0].hideUSD = this.myHome.hide8;
      this.plans[0].hideCOP = this.myHome.hide30250;
      this.plans[1].hide = this.myHome.hide149;
      this.plans[1].hideUSD = this.myHome.hide12;
      this.plans[1].hideCOP = this.myHome.hide45376;
      this.plans[2].hide = this.myHome.hide199;
      this.plans[2].hideUSD = this.myHome.hide16;
      this.plans[2].hideCOP = this.myHome.hide60501;
      this.plans[3].hide = this.myHome.hide1899;
      this.plans[3].hideUSD = this.myHome.hide145;
      this.plans[3].hideCOP = this.myHome.hide548296;
    });
    this.auth.getUser().then((res) => {
      if (!res) {
        const eventLocal = window.sessionStorage.getItem("plans");
        if (!eventLocal) {
          window.sessionStorage.setItem("plans", "true");
          const event: any = {
            event_name: "InitiateCheckout",
            event_source_url: "https://uhremix.com/#/plans",
            value: null,
            currency: null,
            ipAddress: "127.0.0.1",
            userAgent: "Chrome 1.16 mobile",
            test: true,
            type: "plans",
            num_items: 1,
            content_category: "Checkout",
            content_ids: null,
            content_name: null,
            content_type: null,
            contents: null,
            status: true,
            em: "",
            ph: "",
            fn: "",
            subscription_id: "",
          };
          window.MC_PIXEL.fireLogConversionEvent("InitiateCheckout");
          this._eventFacebook.sendEvent(event);
        }
        return;
      }
      if (res != undefined) {
        this.db.getUser(res.uid).subscribe((user: any) => {
          const eventLocal = window.sessionStorage.getItem("plans");
          if (!eventLocal) {
            window.sessionStorage.setItem("plans", "true");
            const event: any = {
              event_name: "InitiateCheckout",
              event_source_url: "https://uhremix.com/#/plans",
              value: null,
              currency: null,
              ipAddress: "127.0.0.1",
              userAgent: "Chrome 1.16 mobile",
              test: true,
              type: "plans",
              num_items: 1,
              content_category: "Checkout",
              content_ids: null,
              content_name: null,
              content_type: null,
              contents: null,
              status: true,
              em: user ? user.email : "",
              ph: user ? user.phone : "",
              fn: user ? user.name : "",
              subscription_id: "",
            };
            window.MC_PIXEL.fireLogConversionEvent("InitiateCheckout");
            this._eventFacebook.sendEvent(event);
            this._eventFacebook.sendEventCampaint(
              "InitiateCheckout",
              user.email,
              ``
            );
          }
        });
      }
    });
  }

  inicioCompra() {
    fbq("track", "InitiateCheckout");
  }
}

import { Component, OnInit } from "@angular/core";
import { DbService } from "src/app/services/db.service";
import { MysqlService } from "src/app/services/mysql.service";

@Component({
  selector: "app-adminmusic",
  templateUrl: "./adminmusic.component.html",
  styleUrls: ["./adminmusic.component.scss"],
})
export class AdminmusicComponent implements OnInit {
  genre = "";
  folder = "";
  genres = [];
  files: any = [];
  alsoPublic = true;

  status = "Crear Combo";

  selected = "music";
  text = "Sube aquí las canciones";
  constructor(private db: DbService, private mysql: MysqlService) {}

  ngOnInit(): void {
    this.mysql.getGenre().subscribe((res: any) => {
      console.log(res);
      this.genres = res.genres;
      // this.genre = res.genres[0].genre;

      this.selected = localStorage.getItem("selected");
      this.text = localStorage.getItem("text");
      this.genre = localStorage.getItem("genre");
    });
  }

  uploadFile(event) {
    for (let index = 0; index < event.length; index++) {
      const element = event[index];
      this.files.push(element.name);
    }
  }

  addCombo() {
    this.status = "Creando...";
    this.mysql.addCombo(this.selected, this.folder, this.genre).subscribe(
      (res) => {
        console.log(res);
        this.status = "Exito";
      },
      (err) => {
        console.log(err);
        this.status = "Error";
      }
    );
  }
  deleteAttachment(index) {
    this.files.splice(index, 1);
  }

  togglePublic() {
    // this.alsoPublic = !this.alsoPublic;
  }

  genreChange() {
    localStorage.setItem("genre", this.genre);
  }

  setSelected(selected, text) {
    this.selected = selected;
    this.text = text;

    localStorage.setItem("selected", selected);
    localStorage.setItem("text", text);
    localStorage.setItem("genre", this.genre);
  }
}

import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class PlanService {
  public plans = {
    plan_1: {
      mxn: {
        price: 99,
        stripe_id: "",
        paypal_id: "",
        id: "mxn99",
      },
      usd: {
        price: 8,
        stripe_id: "",
        paypal_id: "",
        id: "usd8",
      },
      cop: {
        price: 30250.85,
        stripe_id: "",
        paypal_id: "",
        id: "cop8",
      },
    },
    plan_2: {
      mxn: {
        price: 149,
        stripe_id: "",
        paypal_id: "",
        id: "mxn149",
      },
      usd: {
        price: 12,
        stripe_id: "",
        paypal_id: "",
        id: "usd12",
      },
      cop: {
        price: 45376.27,
        stripe_id: "",
        paypal_id: "",
        id: "cop12",
      },
    },
    plan_3: {
      mxn: {
        price: 199,
        stripe_id: "",
        paypal_id: "",
        id: "mxn199",
      },
      usd: {
        price: 16,
        stripe_id: "",
        paypal_id: "",
        id: "usd16",
      },
      cop: {
        price: 60501.70,
        stripe_id: "",
        paypal_id: "",
        id: "cop16",
      },
    },
    plan_4: {
      mxn: {
        price: 1899,
        stripe_id: "",
        paypal_id: "",
        id: "mxn1899",
      },
      usd: {
        price: 145,
        stripe_id: "",
        paypal_id: "",
        id: "usd145",
      },
      cop: {
        price: 548296.62,
        stripe_id: "",
        paypal_id: "",
        id: "cop145",
      },
    },
  };
}

import { Component, OnInit, HostListener } from "@angular/core";
import { DbService } from "src/app/services/db.service";
import { ActivatedRoute, Router } from "@angular/router";

import { AuthService } from "src/app/services/auth.service";
import { ActiveCampService } from "src/app/services/active-camp.service";
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-playlist",
  templateUrl: "./playlist.component.html",
  styleUrls: ["./playlist.component.scss"],
})
export class PlaylistComponent implements OnInit {
  activeSubscription = true;
  endpoint = environment.endpoint;
  token = "";

  constructor(
    private db: DbService,
    private route: ActivatedRoute,
    private router: Router,

    private auth: AuthService,
    private ac: ActiveCampService,
    private spinner: NgxSpinnerService
  ) { }

  songs = [];
  backupSongs = [];

  hasMore = true;
  last: any;
  searchWord = "";
  genre = "";
  genres = [];

  user = {
    id: "",
    tokens: 0,
    vidIds: [],
    musicIds: [],
    comboIds: [],
    karaokeIds: [],
    playlist: [],
  };

  hasPaid = ""
  downloads = 0;
  loading = false;

  limit = 0;
  limitValue = 20;
  @HostListener("window:scroll", ["$event"])
  onWindowScroll() {
    let pos =
      (document.documentElement.scrollTop || document.body.scrollTop) +
      document.documentElement.offsetHeight;
    let max = document.documentElement.scrollHeight;
    if (pos == max) {
      this.getMore();
    }
  }

  getMore() {
    if (this.limit < this.songs.length) {
      this.hasMore = true;
      this.limit += this.limitValue;
    } else {
      this.hasMore = false;
    }
  }

  updatePlaylist(id) {
    let result = this.includesPlaylist(id);
    console.log(result);
    if (result >= 0) {
      this.user.playlist.splice(result, 1);
    } else {
      this.user.playlist.push({ id, type: "combos" });
    }
    this.db.updateUser(this.user.id, this.user);
  }

  includesPlaylist(id) {
    let array = this.user.playlist;
    for (let i = 0; i < array.length; i++) {
      if (array[i].id == id) {
        return i;
      }
    }
    return -1;
  }

  ngOnInit() {
    this.auth.getUserToken().subscribe((res) => {
      console.log(res);
      this.token = res;
    });

    this.spinner.show();

    this.auth.getUser().then((res) => {
      if (!res) return
      this.db.getUser(res.uid).subscribe((user: any) => {
        this.user = user;
        this.user.id = res.uid;
        this.downloads = user.tokens;
        if(!this.user.playlist)this.user.playlist = []
        for (let s of this.user.playlist) {
          this.transformDate(s);
        }

        if (user.paypalSubscription == undefined) {
          if (user.membership != undefined) {
            this.hasPaid = user.membership.status;
          } else {
            this.hasPaid = "none";
          }
        } else {
          this.ac
            .getContent(user.paypalSubscription.subscriptionID)
            .subscribe((res: any) => {
              if (res.status == "ACTIVE") {
                this.hasPaid = ""
              } else if (res.status == "SUSPENDED") {
                this.hasPaid = "incomplete";
              } else {
                this.hasPaid = "none";
              }
            });
        }
        if (this.downloads > 0) {
          this.hasPaid = ""
        }
        if (this.user.playlist == undefined) {
          this.user.playlist = [];
        }
        if (this.user.vidIds == undefined) {
          this.user.vidIds = [];
        }
        if (this.user.musicIds == undefined) {
          this.user.musicIds = [];
        }
        if (this.user.comboIds == undefined) {
          this.user.comboIds = [];
        }
        if (this.user.karaokeIds == undefined) {
          this.user.karaokeIds = [];
        }
        this.spinner.hide();
      });
    });
  }

  async downloadAll() {
    this.loading = true;
    // for (let d of this.user.playlist) {
    //   await window.setTimeout(() => {
    //     console.log(d);
    //     this.downloadFile(d);
    //   }, 1000);
    // }
    let playlist = this.user.playlist;
    for (let i = 0; i < playlist.length; i++) {
      window.setTimeout(() => {
        console.log(playlist[i]);
        this.downloadFile(playlist[i]);
      }, i * 1000);
    }

    window.setTimeout(() => {
      this.user.playlist = [];
      this.db.updateUser(this.user.id, this.user);
    }, playlist.length * 1200);
  }

  downloadFile(song): void {
    if (this.activeSubscription) {
      song.active = true;
      var a = document.createElement("a");
      a.href = this.endpoint + "/download/" + song.id + "/" + this.token;
      var evt = document.createEvent("MouseEvents");
      evt.initMouseEvent(
        "click",
        true,
        true,
        window,
        0,
        0,
        0,
        0,
        0,
        true,
        false,
        false,
        false,
        0,
        null
      );
      a.dispatchEvent(evt);
      window.setTimeout(() => {
        song.active = false;
        this.markFile(song);
      }, 1000);
    }
  }

  markFile(file) {
    file.name = "KI11";
    let result = this.includesId(file);
    if (result >= 0) {
      if (file.type == "music") this.user.musicIds[result].count++;
      if (file.type == "video") this.user.vidIds[result].count++;
      if (file.type == "karaoke") this.user.karaokeIds[result].count++;
      if (file.type == "combo") this.user.comboIds[result].count++;
    } else {
      if (file.type == "music") this.user.musicIds.push({ id: file.id, count: 1 });
      if (file.type == "video") this.user.vidIds.push({ id: file.id, count: 1 });
      if (file.type == "karaoke") this.user.karaokeIds.push({ id: file.id, count: 1 });
      if (file.type == "combo") this.user.comboIds.push({ id: file.id, count: 1 });
    }
    if (this.downloads > 0) {
      this.user.tokens--;
    }
  }

  includesId(file) {
    let array = [];
    if (file.type == "music") array = this.user.musicIds;
    if (file.type == "video") array = this.user.vidIds;
    if (file.type == "karaoke") array = this.user.karaokeIds;
    if (file.type == "combo") array = this.user.comboIds;
    for (let i = 0; i < array.length; i++) {
      if (array[i].id == file.id) {
        return i;
      }
    }
    return -1;
  }

  transformDate(item) {
    let date = item.createdAt;
    let d = new Date(date);
    let options = { day: "numeric", month: "long", year: "numeric" };
    item.date = d.toLocaleDateString("es-ES", options);
  }
  getDate(date) {
    return date.toDate();
  }
}

import { Injectable } from "@angular/core";
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
  DocumentReference,
} from "@angular/fire/firestore";
import { map, take } from "rxjs/operators";
import { Observable } from "rxjs";
import { renderFlagCheckIfStmt } from "@angular/compiler/src/render3/view/template";

@Injectable({
  providedIn: "root",
})
export class DbService {
  limit = 100;
  debug = true;

  public previousPage = "";
  public cuponAtive: boolean = false;
  public modalPromo: boolean = false;
  public closeModalPromo: boolean = false;
  public modalLogin: boolean = false;
  public closeModalLogin: boolean = false;

  private homeCollection: AngularFirestoreCollection;
  private home;

  private musicCollection: AngularFirestoreCollection;
  private music;

  private videoCollection: AngularFirestoreCollection;
  private video;

  private combosCollection: AngularFirestoreCollection;
  private combos;

  private karaokeCollection: AngularFirestoreCollection;
  private karaoke;

  private userCollection: AngularFirestoreCollection;
  private users;

  private genreCollection: AngularFirestoreCollection;
  private genre;

  private cuoponCollection: AngularFirestoreCollection;
  private cuopon;

  private paymentCollection: AngularFirestoreCollection;
  private payment;

  private premiumCollection: AngularFirestoreCollection;
  private premium;

  constructor(public afs: AngularFirestore) {
    this.musicCollection = this.afs.collection("publicmusic", (ref) =>
      ref.orderBy("createdAt", "desc")
    );
    if (this.debug)
      this.musicCollection = this.afs.collection("publicmusic", (ref) =>
        ref.orderBy("createdAt", "desc").limit(this.limit)
      );
    this.music = this.musicCollection.snapshotChanges().pipe(
      map((actions) => {
        return actions.map((a) => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );

    this.videoCollection = this.afs.collection("publicvideos", (ref) =>
      ref.orderBy("createdAt", "desc")
    );
    if (this.debug)
      this.videoCollection = this.afs.collection("publicvideos", (ref) =>
        ref.orderBy("createdAt", "desc").limit(this.limit)
      );
    this.video = this.videoCollection.snapshotChanges().pipe(
      map((actions) => {
        return actions.map((a) => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );

    this.combosCollection = this.afs.collection("publiccombos", (ref) =>
      ref.orderBy("createdAt", "desc")
    );
    if (this.debug)
      this.combosCollection = this.afs.collection("publiccombos", (ref) =>
        ref.orderBy("createdAt", "desc").limit(this.limit)
      );
    this.combos = this.combosCollection.snapshotChanges().pipe(
      map((actions) => {
        return actions.map((a) => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );

    this.karaokeCollection = this.afs.collection("publickaraoke", (ref) =>
      ref.orderBy("createdAt", "desc")
    );
    if (this.debug)
      this.karaokeCollection = this.afs.collection("publickaraoke", (ref) =>
        ref.orderBy("createdAt", "desc").limit(this.limit)
      );
    this.karaoke = this.karaokeCollection.snapshotChanges().pipe(
      map((actions) => {
        return actions.map((a) => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );

    this.genreCollection = this.afs.collection("genres", (ref) =>
      ref.orderBy("name")
    );
    this.genre = this.genreCollection.snapshotChanges().pipe(
      map((actions) => {
        return actions.map((a) => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );
    this.userCollection = this.afs.collection(
      "users",
      (ref) =>
        ref.orderBy(
          "creationTime",
          "desc"
        ) /* .where('date', '>', '').where('date', '<', '') */
    );
    this.users = this.userCollection.snapshotChanges().pipe(
      map((actions) => {
        return actions.map((a) => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );

    this.cuoponCollection = this.afs.collection("coupons");
    this.cuopon = this.cuoponCollection.snapshotChanges().pipe(
      map((actions) => {
        return actions.map((a) => {
          const data = a.payload.doc.data();
          const uid = a.payload.doc.id;
          return { uid, ...data };
        });
      })
    );

    this.paymentCollection = this.afs.collection("payments");
    this.payment = this.paymentCollection.snapshotChanges().pipe(
      map((actions) => {
        return actions.map((a) => {
          const data = a.payload.doc.data();
          const uid = a.payload.doc.id;
          return { uid, ...data };
        });
      })
    );

    this.premiumCollection = this.afs.collection("premium", (ref) =>
      ref.orderBy("createdAt", "desc")
    );
    this.premium = this.premiumCollection.snapshotChanges().pipe(
      map((actions) => {
        return actions.map((a) => {
          const data = a.payload.doc.data();
          const uid = a.payload.doc.id;
          return { uid, ...data };
        });
      })
    );
  } //EO Constructor

  getUserIP(ip) {
    this.userCollection = this.afs.collection("users", (ref) =>
      ref.where("ip", "==", ip)
    );
    let user = this.userCollection.snapshotChanges().pipe(
      map((actions) => {
        return actions.map((a) => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );
    return user;
  }

  //GENRES
  getGenres() {
    return this.genre;
  }

  createGenre(genre) {
    return this.genreCollection.add(genre);
  }

  //USER
  getUserpayments(userId) {
    let c: AngularFirestoreCollection = this.afs.collection(
      `users/${userId}/payments`
    );
    let payments = c.snapshotChanges().pipe(
      map((actions) => {
        return actions.map((a) => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );
    return payments;
  }

  //COUPONS
  getCoupon(cuopon) {
    this.cuoponCollection = this.afs.collection("coupons", (ref) =>
      ref.where("name", "==", cuopon)
    );
    this.cuopon = this.cuoponCollection.snapshotChanges().pipe(
      map((actions) => {
        return actions.map((a) => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );
    return this.cuopon;
  }

  removeCoupon(id) {
    return this.cuoponCollection.doc(id).delete();
  }

  getCoupons() {
    return this.cuopon;
  }

  //MUSIC
  getPublicMusic() {
    return this.music;
  }

  updateMusic(music) {
    return this.musicCollection.doc(music.id).update(music);
  }

  removeMusic(id) {
    this.musicCollection.doc(id).delete();
  }

  getGenreMusic(genre) {
    this.musicCollection = this.afs.collection("publicmusic", (ref) =>
      ref.where("genre", "==", genre).orderBy("createdAt")
    );
    if (this.debug)
      this.musicCollection = this.afs.collection("publicmusic", (ref) =>
        ref.where("genre", "==", genre).orderBy("createdAt").limit(this.limit)
      );
    this.music = this.musicCollection.snapshotChanges().pipe(
      map((actions) => {
        return actions.map((a) => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );
    return this.music;
  }

  // VIDEOS

  getVideoOrdered() {
    return this.video;
  }

  getGenreVideos(genre) {
    this.videoCollection = this.afs.collection("publicvideos", (ref) =>
      ref.where("genre", "==", genre).orderBy("createdAt")
    );
    if (this.debug)
      this.videoCollection = this.afs.collection("publicvideos", (ref) =>
        ref.where("genre", "==", genre).orderBy("createdAt").limit(this.limit)
      );
    this.video = this.videoCollection.snapshotChanges().pipe(
      map((actions) => {
        return actions.map((a) => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );
    return this.video;
  }

  getVideo(id) {
    return this.videoCollection.doc(id).valueChanges();
  }

  updateVideo(id, video) {
    return this.videoCollection.doc(id).update(video);
  }

  removeVideo(id) {
    this.videoCollection.doc(id).delete();
  }

  // KARAOKE

  getKaraokeOrdered() {
    return this.karaoke;
  }

  getGenreKaraokes(genre) {
    this.karaoke = this.afs.collection("publickaraokes", (ref) =>
      ref.where("genre", "==", genre).orderBy("createdAt")
    );
    if (this.debug)
      this.karaoke = this.afs.collection("publickaraokes", (ref) =>
        ref.where("genre", "==", genre).orderBy("createdAt").limit(this.limit)
      );
    this.karaoke = this.karaokeCollection.snapshotChanges().pipe(
      map((actions) => {
        return actions.map((a) => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );
    return this.karaoke;
  }

  getKaraoke(id) {
    return this.karaokeCollection.doc(id).valueChanges();
  }

  updateKaraoke(id, karaoke) {
    return this.karaokeCollection.doc(id).update(karaoke);
  }

  removeKaraoke(id) {
    this.karaokeCollection.doc(id).delete();
  }

  // COMBOS

  getComboOrdered() {
    return this.combos;
  }

  getGenreCombos(genre) {
    this.combos = this.afs.collection("publiccombos", (ref) =>
      ref.where("genre", "==", genre).orderBy("createdAt")
    );
    if (this.debug)
      this.combos = this.afs.collection("publiccombos", (ref) =>
        ref.where("genre", "==", genre).orderBy("createdAt").limit(this.limit)
      );
    this.combos = this.combosCollection.snapshotChanges().pipe(
      map((actions) => {
        return actions.map((a) => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );
    return this.combos;
  }

  getCombo(id) {
    return this.combosCollection.doc(id).valueChanges();
  }

  updateCombo(id, combos) {
    return this.combosCollection.doc(id).update(combos);
  }

  removeCombo(id) {
    this.combosCollection.doc(id).delete();
  }

  //USERS
  getUsers() {
    return this.users;
  }

  getStripeUser(stripe_id) {
    let userCol: AngularFirestoreCollection = this.afs.collection(
      "users",
      (ref) => ref.where("stripe_id", "==", stripe_id)
    );
    let user = userCol.snapshotChanges().pipe(
      map((actions) => {
        return actions.map((a) => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );
    return user;
  }

  getUser(id) {
    return this.userCollection.doc(id).valueChanges();
  }

  updateUser(id, user) {
    return this.userCollection.doc(id).update(user);
  }

  removeMembership(id, user) {
    return this.userCollection.doc(id).set(user);
  }

  reduceTokens(id, tokens) {
    let user = {
      tokens,
    };
    return this.userCollection.doc(id).update(user);
  }

  getHome() {
    this.homeCollection = this.afs.collection("home");
    this.home = this.homeCollection.snapshotChanges().pipe(
      map((actions) => {
        return actions.map((a) => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );
    return this.home;
  }

  updateHome(obj) {
    this.homeCollection.doc("r2fLi75yhf5OWti96Dg3").update(obj);
  }

  getPayments() {
    return this.payment;
  }

  createPayment(payment) {
    return this.paymentCollection.add(payment);
  }

  getPremium(id) {
    return this.premiumCollection.doc(id).valueChanges();
  }

  updatePremium(id, premium) {
    return this.premiumCollection.doc(id).update(premium);
  }

  removePremium(id) {
    return this.premiumCollection.doc(id).delete();
  }

  getPremiums() {
    return this.premium;
  }

  createPremium(premium) {
    return this.premiumCollection.add(premium);
  }
}

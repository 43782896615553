import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "src/app/services/auth.service";
import { NgxSpinnerService } from "ngx-spinner";
import { ActiveCampService } from "src/app/services/active-camp.service";
import { DbService } from "src/app/services/db.service";
import * as firebase from "firebase";
import { MysqlService } from "src/app/services/mysql.service";
import Swal from "sweetalert2";
import {
  eventFacebook,
  EventFacebookService,
} from "src/app/services/event-facebook.service";
import { Subscription } from "rxjs";
import { PaypalServices } from "src/app/services/paypal.service";
import { AngularFireFunctions } from "@angular/fire/functions";
import { loadMercadoPago } from "@mercadopago/sdk-js";

declare let paypal;
declare var fbq: any;
declare var stripe: any;
declare var window: any;
declare let Conekta: any;

@Component({
  selector: "app-pay",
  templateUrl: "./pay.component.html",
  styleUrls: ["./pay.component.scss"],
})
export class PayComponent implements OnInit {
  public alertmodal: boolean = false;
  description: string;
  constructor(
    private route: ActivatedRoute,
    private router: ActivatedRoute,
    private auth: AuthService,
    private spinner: NgxSpinnerService,
    private ac: ActiveCampService,
    public db: DbService,
    private mysql: MysqlService,
    private _eventFacebook: EventFacebookService,
    private _router: Router,
    private _paypal: PaypalServices,
    private fns: AngularFireFunctions
  ) {}

  selected = "tarjeta";
  planType = "";
  planPrice = "";
  planPriceMX = "";
  total = "";
  moneda = "";
  couponCode: any = "";
  couponStatus = "";

  isActive = false;
  isVisible = false;
  showCoupon = false;
  name;
  email;
  password;
  phone;

  cardName;
  cardNumber;
  cardDate;
  cardCVV;

  visible = false;
  title = "";
  text = "";
  msg = "";
  link = "dismiss";
  paypalId = "P-9BD74155EV3074210MI5IDFA";
  stripeId = "";
  conektaId = "";
  mercadoPagoId = "";

  disc = "";

  user: any = {
    id: "",
    membership: {},
    oxxoTicket: {
      reference: "0000-0000-0000-00",
      barcode_url: "",
      expires_at: 0,
      price: "0",
    },
    name: "",
    email: "",
  };
  card = {
    month: "",
    year: "",
    number: "",
    name: "",
    cvc: "",
  };

  spei_data = {
    clabe: "",
    price: 0,
  };

  coupon = null;
  loadingOxxo = false;
  showOxxoPay = false;
  paying = false;

  years = [];
  months = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  errors = {
    year: "",
    number: "",
    cvv: "",
    month: "",
  };

  @ViewChild("paypal", { static: true }) paypalElement: ElementRef;
  @ViewChild("paypalSub", { static: true }) paypalSubElement: ElementRef;

  myHome = {
    banner: "",
    button: "",
    headline: "",
    text: "",
    tokens: "",
    wa: "",
    fb: "",
    isVideo: false,
    hideWa: false,
    hideFb: false,
    hide99: false,
    hide149: false,
    hide199: false,
    hide1899: false,
    hide8: false,
    hide12: false,
    hide16: false,
    hide145: false,
    hide30250: false,
    hide45376: false,
    hide60501: false,
    hide548296: false,
  };

  paymentTypes = ["stripe", "conekta", "paypal"];
  /* paymentTypes = ['stripe', 'conekta', 'paypal', 'mercado'] */
  randomIndex = 0;
  randomPaymentType = "stripe";

  async ngOnInit() {
    this.randomIndex = Math.floor(Math.random() * this.paymentTypes.length);
    this.randomPaymentType = this.paymentTypes[this.randomIndex];
    /* this.fns.useEmulator('localhost', 5003) */
    if (this.db.cuponAtive) {
      this.couponCode = "50VIP";
      this.exchangeCoupon();
    }
    let curryear = new Date().getFullYear();
    for (let i = 0; i < 10; i++) {
      this.years.push(curryear + i);
    }
    this.auth.getUser().then((res) => {
      if (!res) return;
      if (!this.paying) {
        if (res != undefined) {
          this.db.getUser(res.uid).subscribe((user: any) => {
            this.user = user;
            this.name = user.name;
            this.email = user.email;
            this.user.id = res.uid;

            const obj = {
              id: this.user.id,
              username: this.user.name,
              email: this.user.email,
              product: this.planType,
              price: this.planPrice,
              owo: this.description,
            };
            this.mysql.createAbandonedCart(obj).subscribe(
              (res) => {
                /* console.log("res", res) */
              },
              (err) => {
                /* console.log("err", err) */
              }
            );
          });
        }
      }
    });
    let resAuth = await this.auth.getUser();
    this.route.params.subscribe((params) => {
      if (params.id) {
        if (params.id === "confirm") {
          this.succesfulPayment(false);
          return;
        }
        console.log(params.id);
        this.db.getHome().subscribe((res) => {
          console.log(res);
          this.myHome = res[0];
          if (this.myHome["hide" + params.id]) {
            this._router.navigate(["/plans"]);
          }
        });
        switch (params.id) {
          /** */
          case "8":
          case "8.00":
            this.planType = "AUDIO";
            this.planPrice = "8";
            this.planPriceMX = "99";
            this.total = "8";
            this.paypalId = "P-5U291984LL312754RMKPKSKY";
            this.stripeId = "price_1ODW5eD2DaxyRogKbz0aYaBr";
            this.description = "Canciones por un mes";
            this.moneda = "USD";
            break;
          case "12":
          case "12.00":
            this.planType = "AUDIO+VIDEO";
            this.planPrice = "12";
            this.planPriceMX = "149";
            this.total = "12";
            this.paypalId = "P-9PL4297158420691JMI5IDEQ";
            this.stripeId = "price_1JIp72D2DaxyRogKbxPAHp7n";
            this.description = "Canciones y videos por un mes";
            this.moneda = "USD";
            break;
          case "16":
          case "16.00":
            this.planType = "FULL";
            this.planPrice = "16";
            this.total = "16";
            this.planPriceMX = "199";
            this.paypalId = "P-5TH84977FL4347616MI5IDEY";
            this.stripeId = "price_1JIp7aD2DaxyRogKfjcybxhv";
            this.description = "Acceso a todo el contenido por un mes";
            this.moneda = "USD";
            break;
          case "145":
          case "145.00":
            this.planType = "FULL Anual";
            this.planPrice = "145";
            this.total = "145";
            this.planPriceMX = "1899";
            this.paypalId = "P-4PX76846NG508541YMLAKG3Q";
            this.stripeId = "price_1JIp4VD2DaxyRogKPWHulWDE";
            this.description = "Acceso a todo el contenido por un año";
            this.moneda = "USD";
            break;
          /**Pesos mexicanos */
          case "99":
          case "99.00":
            this.planType = "AUDIO";
            this.planPrice = "99";
            this.total = "99";
            this.planPriceMX = "99";
            this.paypalId = "P-9BD74155EV3074210MI5IDFA";
            this.stripeId = "price_1HbNhvD2DaxyRogKr0zkk59n";
            this.conektaId = "PLANAUDIO";
            this.mercadoPagoId = "2c938084894f5e430189555cae3001aa";
            this.description = "Canciones por un mes";
            this.moneda = "MXN";
            break;
          case "149":
          case "149.00":
            this.planType = "AUDIO+VIDEO";
            this.planPrice = "149";
            this.planPriceMX = "149";
            this.total = "149";
            this.paypalId = "P-98V54578U8979852CMI5IDFI";
            this.stripeId = "price_1HbNhgD2DaxyRogKbvFIaKHr";
            this.conektaId = "PLANAUDIOVIDEO";
            this.mercadoPagoId = "2c9380848953f8e801895564e93c007a";
            this.description = "Canciones y videos por un mes";
            this.moneda = "MXN";
            break;
          case "199":
          case "199.00":
            this.planType = "FULL";
            this.planPrice = "199";
            this.planPriceMX = "199";
            this.total = "199";
            this.paypalId = "P-59W14844Y1424533VMI5IDFI";
            this.stripeId = "price_1HbNhCD2DaxyRogKAGT3Uq94";
            this.conektaId = "PLANFULL";
            this.mercadoPagoId = "2c938084894f5e430189556662f001af";
            this.description = "Acceso a todo el contenido por un mes";
            this.moneda = "MXN";
            break;
          case "1899":
          case "1899.00":
            this.planType = "FULL Anual";
            this.planPrice = "1899";
            this.total = "1899";
            this.planPriceMX = "1899";
            this.paypalId = "P-6PC4238657966203CMI5IDFQ";
            this.stripeId = "price_1IDQi4D2DaxyRogKcZ5uF5AO";
            this.conektaId = "PLANFULLANUAL";
            this.mercadoPagoId = "2c93808489550b6401895566fafb001f";
            this.description = "Acceso a todo el contenido por un año";
            this.moneda = "MXN";
            break;
          /***Pesos colombianos */
          case "30250":
          case "30250.00":
            this.planType = "AUDIO";
            this.planPrice = "30250";
            this.total = "30250";
            this.planPriceMX = "99";
            this.paypalId = "";
            this.stripeId = "price_1L7rJID2DaxyRogK7IfsCzvm";
            this.description = "Canciones por un mes";
            this.moneda = "COP";
            break;
          case "45376":
          case "45376.00":
            this.planType = "AUDIO+VIDEO";
            this.planPrice = "45376";
            this.total = "45376";
            this.planPriceMX = "149";
            this.paypalId = "";
            this.stripeId = "price_1L7rIwD2DaxyRogKp0mQLyFJ";
            this.description = "Canciones y videos por un mes";
            this.moneda = "COP";
            break;
          case "60501":
          case "60501.00":
            this.planType = "FULL";
            this.planPrice = "60501";
            this.total = "60501";
            this.planPriceMX = "199";
            this.paypalId = "";
            this.stripeId = "price_1L7rIWD2DaxyRogK2h5FkXDD";
            this.description = "Acceso a todo el contenido por un mes";
            this.moneda = "COP";
            break;
          case "548296":
          case "548296.00":
            this.planType = "FULL Anual";
            this.planPrice = "548296";
            this.total = "548296";
            this.planPriceMX = "1899";
            this.paypalId = "";
            this.stripeId = "price_1L7rHlD2DaxyRogK2GC9oc97";
            this.description = "Acceso a todo el contenido por un año";
            this.moneda = "COP";
            break;
        }
        const eventLocal = window.sessionStorage.getItem(
          "plan" + this.planPrice
        );
        console.log("eventLocal: ", eventLocal);
        if (!eventLocal) {
          window.sessionStorage.setItem("plan" + this.planPrice, "true");
          const event: eventFacebook = {
            event_name: "AddToCart",
            event_source_url: "https://uhremix.com/#/payment/" + this.planPrice,
            value: Number(this.planPrice),
            currency: this.moneda,
            ipAddress: "127.0.0.1",
            userAgent: "Chrome 1.16 mobile",
            test: false,
            type: "cart",
            num_items: 1,
            content_category: "Cart",
            content_ids: [this.planPrice],
            content_name: `${this.planPrice}`,
            content_type: "product",
            contents: [
              {
                id: `${this.planPrice}`,
                quantity: 1,
              },
            ],
            status: true,
            em: this.user ? this.user.email : "",
            ph: this.user ? this.user.phone : "",
            fn: this.user ? this.user.name : "",
            subscription_id: "",
          };
          window.MC_PIXEL.fireLogMoneyEvent(
            "AddToCart",
            Number(this.planPrice),
            this.moneda
          );
          this._eventFacebook.sendEventCampaint(
            "AddToCart",
            resAuth.email,
            `Plan: ${this.planPrice} <br/> Currency: ${this.moneda}`
          );
          this._eventFacebook.sendEvent(event);
        }
        if (this.coupon) {
          if (this.coupon.amount_off) {
            this.disc = this.coupon.amount_off;
          } else {
            //@ts-ignore
            this.disc = (this.total * this.coupon.percent_off) / 100;
          }
          //@ts-ignore
          this.total = this.total - this.disc;
          console.log(this.coupon);
        }

        paypal
          .Buttons({
            createSubscription: async (data, actions) => {
              let paypalIds = [
                "P-9BD74155EV3074210MI5IDFA",
                "P-98V54578U8979852CMI5IDFI",
                "P-59W14844Y1424533VMI5IDFI",
                "P-13C767126L087164SMLAKI3A",
              ];
              let selectedPlanId = "";
              console.log(params.id);
              if (params.id) {
                switch (params.id) {
                  case "99":
                  case "99.00":
                    selectedPlanId = paypalIds[0];
                    break;
                  case "149":
                  case "149.00":
                    selectedPlanId = paypalIds[1];
                    break;
                  case "199":
                  case "199.00":
                    selectedPlanId = paypalIds[2];
                    break;
                  case "1899":
                  case "1899.00":
                    selectedPlanId = paypalIds[3];
                    break;
                }
                console.log(selectedPlanId);

                return actions.subscription.create({
                  plan_id: selectedPlanId, // Replace with your plan ID
                });
              }
            },
            onApprove: async (data, actions) => {
              this.cancelSubs();
              alert(
                "El pago se ha realizado con éxito. Te llegará un correo a la brevedad."
              );
              let startDate = new Date();
              let endDate = new Date();
              if (this.planPriceMX != "1899")
                endDate.setDate(endDate.getDate() + 30);
              if (this.planPriceMX == "1899")
                endDate.setDate(endDate.getDate() + 365);
              this.user.membership = {
                planPrice: this.planPriceMX,
                status: "active",
                subscriptionStart: startDate,
                subscriptionEnd: endDate,
                type: "paypal",
              };
              this.user.paypalSubscription = data.subscriptionID;
              await this.db.updateUser(this.user.id, this.user);
              this.succesfulPaymentSimple();
            },
          })
          .render(this.paypalSubElement.nativeElement);
      }
      this.setupPaypal();
    });
    // console.log(this.selected);
    // console.log(this.randomPaymentType);

    /* paypal
      .Buttons({
        createOrder: (data, actions) => {
          // This function sets up the details of the transaction, including the amount and line item details.
          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  value: this.planPriceMX,
                  currency_code: 'MXN',
                },
              },
            ],
          });
        },
        onApprove: (data, actions) => {
          this.cancelSubs()
          return actions.order.capture().then(async (details) => {
            alert(
              'El pago se ha realizado con éxito. Te llegará un correo a la brevedad.'
            );
            let startDate = new Date()
            let endDate = new Date()
            if (this.planPriceMX != "1899") endDate.setDate(endDate.getDate() + 30)
            if (this.planPriceMX == "1899") endDate.setDate(endDate.getDate() + 365)
            this.user.membership = {
              planPrice: this.planPriceMX,
              status: "active",
              subscriptionStart: startDate,
              subscriptionEnd: endDate,
              type: "paypal"
            }
            await this.db.updateUser(this.user.id, this.user)
            this.succesfulPaymentSimple()
          });
        },
      })
      .render(this.paypalElement.nativeElement); */
  }

  setPayment(type) {
    /* console.log(type); */
    this.coupon = null;
    this.total = this.planPrice;
    this.disc = "";
    this.selected = type;
    this.couponStatus = "";
    this.couponCode = "";
    if (this.db.cuponAtive && (type === "tarjeta" || type === "paypal")) {
      this.couponCode = "50VIP";
      this.exchangeCoupon();
    }
  }

  toggleOverlay() {
    this.isVisible = !this.isVisible;
    this.toggleCoupon();
  }

  toggleCoupon() {
    this.showCoupon = !this.showCoupon;
  }

  exchangeCoupon() {
    this.db.getCoupon(this.couponCode).subscribe((res) => {
      if (res.length == 0) {
        this.couponStatus = "error";
        this.couponCode = "Código invalido";
      } else {
        if (res[0].hide) {
          this.couponStatus = "error";
          this.couponCode = "Código invalido";
          return;
        }
        this.couponStatus = "succ";
        this.couponCode = "Código canjeado exitosamente";
        this.coupon = res[0];
        if (res[0].amount_off) {
          this.disc = res[0].amount_off;
        } else {
          //@ts-ignore
          this.disc = (this.total * res[0].percent_off) / 100;
        }
        console.log(this.coupon);
        //@ts-ignore
        this.total = this.total - this.disc;
        console.log(this.coupon);
      }
    });
  }

  async cancelSubs() {
    try {
      if (
        this.user.conektaId &&
        this.user.membership?.type == "conekta" &&
        !(this.selected == "tarjeta" && this.randomPaymentType == "conekta")
      ) {
        let cancelSub = firebase
          .functions()
          .httpsCallable("cancelConektaSubscription");
        let res = await cancelSub({ conektaId: this.user.conektaId });
        return res;
      }
      if (
        this.user?.membership?.subscription_id &&
        this.user.membership?.type !== "stripe"
      ) {
        let delSubscription = firebase
          .functions()
          .httpsCallable("delSubscription");
        let response = await delSubscription(
          this.user.membership.subscription_id
        );
        return response;
      }
      if (
        this.user?.membership?.type == "mercado pago" &&
        this.user?.mercadoPagoId
      ) {
        let cancelMercadoSub = firebase
          .functions()
          .httpsCallable("cancelMercadoSubscription");
        cancelMercadoSub({ id: this.user.mercadoPagoId }).then(async (res) => {
          this.user.membership.cancelled = true;
          await this.db.updateUser(this.user.id, this.user);
        });
      }
      if (
        this.user?.membership?.type == "paypal" &&
        this.user?.paypalSubscription
      ) {
        window.open(
          "'https://www.paypal.com/myaccount/autopay/connect/'+user.paypalSubscription",
          "_blank"
        );
      }
    } catch (err) {
      return console.error(err);
    }
  }

  payWithMercado() {
    let init_point = `https://www.mercadopago.com.mx/subscriptions/checkout?preapproval_plan_id=${this.mercadoPagoId}`;
    window.location.href = init_point;
    /* let amount = this.planPrice
    if(this.planPrice=='99')amount='100'
    let data = {
      plan: this.planType,
      amount: amount,
      backUrl: `https://uhremix.com/#/success?plan=${this.planType}`,
      frequency: "months"
    }
    if(this.planType=='FULL Anual')data.frequency = 'years'
    this.mysql.createMercadoSubscription(data).subscribe((res) => {
      console.log(res);
    }) */
  }

  payWithConekta() {
    this.alertmodal = true;
    let sub_info = {
      cardId: "",
      uid: "",
      conektaId: this.conektaId,
      coupon: undefined,
      planPrice: this.planPriceMX,
    };
    if (!this.user.conektaId) {
      let createCustomer = firebase
        .functions()
        .httpsCallable("createConektaCustomer");
      createCustomer({ name: this.user.name, email: this.user.email }).then(
        async (res) => {
          this.user.conektaId = res.data.id;
          await this.db.updateUser(this.user.id, this.user);
        },
        (err) => {
          Swal.fire({
            title: "Error",
            text: "Error al pagar",
            icon: "error",
            confirmButtonText: "Aceptar",
            position: "center",
          });
          console.error(err);
        }
      );
    }
    let card_object = {
      name: this.card.name,
      number: this.card.number,
      exp_month: this.card.month,
      exp_year: this.card.year,
      cvc: this.card.cvc,
    };
    Conekta.Token.create(
      { card: card_object },
      async (res) => {
        const tokenId = res.id;
        await this.cancelSubs();
        let createSubscription = firebase
          .functions()
          .httpsCallable("createConektaSubscription");
        createSubscription({
          conektaId: this.user.conektaId,
          tokenId: tokenId,
          plan: this.conektaId,
        }).then(
          async (res: any) => {
            if (res.data.status === "active") {
              let startDate = new Date();
              let endDate = new Date();
              if (this.planPriceMX != "1899")
                endDate.setDate(endDate.getDate() + 30);
              if (this.planPriceMX == "1899")
                endDate.setDate(endDate.getDate() + 365);
              this.user.conektaSubId = res.data.id;
              this.user.membership = {
                planPrice: sub_info.planPrice,
                status: "active",
                subscriptionStart: startDate,
                subscriptionEnd: endDate,
                type: "conekta",
              };
              await this.db.updateUser(this.user.id, this.user);
              await this.db.createPayment({
                ...this.user.membership,
                userId: this.user.id,
              });
              this.alertmodal = false;
              this.succesfulPaymentSimple();
            } else {
              this.alertmodal = false;
              Swal.fire({
                title: "Error",
                text: "Hubo un error, por favor revisa los datos de tu tarjeta o intenta más tarde.",
                icon: "error",
                confirmButtonText: "Aceptar",
                position: "center",
              });
            }
          },
          (err) => {
            this.alertmodal = false;
            Swal.fire({
              title: "Error",
              text: "Hubo un error, por favor revisa los datos de tu tarjeta o intenta más tarde.",
              icon: "error",
              confirmButtonText: "Aceptar",
              position: "center",
            });
            console.error(err);
          }
        );
      },
      (err) => {
        this.alertmodal = false;
        Swal.fire({
          title: "Error",
          text: "Tu tarjeta fue rechazada",
          icon: "error",
          confirmButtonText: "Aceptar",
          position: "center",
        });
        console.error(err);
      }
    );
  }

  pay() {
    let obj = {
      cardId: "",
      uid: "",
      stripeId: this.stripeId,
      coupon: undefined,
      planPrice: this.planPriceMX,
    };
    console.log(obj);
    if (this.selected === "paypal") {
      this.subscriptionpaypal();
      return;
    }
    this.alertmodal = true;
    let newPaymentMethod = firebase
      .functions()
      .httpsCallable("newPaymentMethod");
    newPaymentMethod({ card: this.card }).then(
      (res) => {
        console.log(res.data);
        if (res.data.code) {
          switch (res.data.code) {
            case "invalid_expiry_month":
              this.errors.month = "Mes invalido";
              break;
            case "card_declined":
              this.errors.number = "Tareta invalida";
              Swal.fire({
                title: "Error",
                text: "Tu tarjeta fue rechazada",
                icon: "error",
                confirmButtonText: "Aceptar",
                position: "center",
              });
              break;
            case "incorrect_number":
              this.errors.number = "Tareta invalida";
              Swal.fire({
                title: "Error",
                text: "Tu tarjeta fue rechazada",
                icon: "error",
                confirmButtonText: "Aceptar",
                position: "center",
              });
              break;

            default:
              this.errors.number = "Tareta invalida";
              Swal.fire({
                title: "Error",
                text: "Tu tarjeta fue rechazada",
                icon: "error",
                confirmButtonText: "Aceptar",
                position: "center",
              });
              break;
          }
          this.alertmodal = false;
        } else {
          this.submit(res.data.id);
        }
      },
      (error) => {
        this.errors.number = "Tareta invalida";
        Swal.fire({
          title: "Error",
          text: "Tu tarjeta fue rechazada",
          icon: "error",
          confirmButtonText: "Aceptar",
          position: "center",
        });
        console.log(error);
      }
    );
  }

  async subscriptionpaypal() {
    try {
      this.spinner.show();
      let res = await this._paypal.subscription(
        this.paypalId,
        this.user,
        this.disc
      );
      console.log(res);
      const user = {
        paypalSubscription: res.subscription_id,
        /* membership: {
          planPrice: this.planPriceMX,
          type: "paypal",
          status: "active",
        }, */
      };
      await this.db.updateUser(this.user.id, user);
      /* this.succesfulPayment(false); */
      window.open(res.link.href, "_self");
      /*
      window.MC_PIXEL.fireLogMoneyEvent(
        "Subscribe",
        Number(this.planPrice),
        this.moneda
      );
      this.mysql.deleteAbandonedCart(this.user.id).subscribe(
        (res) => {
          localStorage.setItem("reload", "true");
          //fbq('track', 'Purchase', {currency: this.moneda, value: parseInt(this.planPrice)});
          fbq("track", "Subscribe", {
            value: this.planPrice,
            currency: this.moneda,
            predicted_ltv: this.planPrice,
          });
          window.location.assign("/#/success");
        },
        (err) => {
          localStorage.setItem("reload", "true");
          //fbq('track', 'Purchase', {currency: this.moneda, value: parseInt(this.planPrice)});
          fbq("track", "Subscribe", {
            value: this.planPrice,
            currency: this.moneda,
            predicted_ltv: this.planPrice,
          });
          window.location.assign("/#/success");
        }
      ); */
    } catch (error) {
      this.spinner.hide();
      console.log(error);
    }
  }

  changeMonth(m) {
    this.errors = {
      year: "",
      number: "",
      cvv: "",
      month: "",
    };
  }
  changeYear(y) {
    this.errors = {
      year: "",
      number: "",
      cvv: "",
      month: "",
    };
  }

  submit(id) {
    let addPaymentMethod = firebase
      .functions()
      .httpsCallable("addPaymentMethodTest");
    this.auth.getUser().then((res) => {
      if (!res) return;
      console.log("paying");
      let obj = {
        cardId: id,
        uid: res.uid,
        stripeId: this.stripeId,
        coupon: undefined,
        planPrice: this.planPriceMX,
      };
      if (this.coupon) obj.coupon = this.coupon.id;
      addPaymentMethod({ ...obj }).then(
        (res) => {
          this.nextStep(res.data, obj);
        },
        (error) => {
          console.log(error);
          this.alertmodal = false;
          window.alert(error);
        }
      );
    });
  }

  async nextStep(subscription, data): Promise<void> {
    try {
      let retrieveInvoices = firebase
        .functions()
        .httpsCallable("retrieveInvoices");
      let retrievePaymentIntent = firebase
        .functions()
        .httpsCallable("retrievePaymentIntent");
      let delSubscriptionIntent = firebase
        .functions()
        .httpsCallable("delSubscription");
      let retrieveSubscription = firebase
        .functions()
        .httpsCallable("retrieveSubscription");
      let invoice = (await retrieveInvoices(subscription.latest_invoice)).data
        .data;
      console.log(invoice);
      let paymentIntent = (await retrievePaymentIntent(invoice.payment_intent))
        .data.data;
      console.log(paymentIntent);
      if (paymentIntent.status === "requires_action") {
        let confirmCardPayment = await stripe.confirmCardPayment(
          paymentIntent.client_secret,
          {
            payment_method: paymentIntent.payment_method,
          }
        );
        console.log(confirmCardPayment);
        if (confirmCardPayment.paymentIntent) {
          paymentIntent = confirmCardPayment.paymentIntent;
        } else {
          paymentIntent = (await retrievePaymentIntent(invoice.payment_intent))
            .data.data;
        }
        console.log(paymentIntent);
      }
      let code = paymentIntent.last_payment_error?.code
        ? paymentIntent.last_payment_error?.code
        : "Tu tarjeta fue rechazada";
      if (
        (paymentIntent.status === "requires_payment_method" ||
          paymentIntent.status === "processing" ||
          paymentIntent.status === "requires_capture" ||
          paymentIntent.status === "requires_confirmation" ||
          paymentIntent.status === "canceled") &&
        subscription.status !== "incomplete_expired"
      ) {
        let delSubscription = (await delSubscriptionIntent(subscription.id))
          .data.data;
        console.log(delSubscription);
        paymentIntent = (await retrievePaymentIntent(invoice.payment_intent))
          .data.data;
        console.log(paymentIntent);
      }
      console.log(subscription.id);
      subscription = (await retrieveSubscription(subscription.id)).data.data;
      console.log(subscription);
      if (
        paymentIntent.status === "succeeded" &&
        subscription.status === "active"
      ) {
        await this.cancelSubs();
        let membership = {
          subscriptionEnd: new Date(subscription.current_period_end * 1000),
          subscriptionStart: new Date(subscription.current_period_start * 1000),
          status: subscription.status,
          planPrice: this.planPriceMX,
          type: "stripe",
          subscription_id: subscription.id,
          userId: data.uid,
        };
        await this.db.afs
          .collection("users")
          .doc(data.uid)
          .update({ membership });
        this.alertmodal = false;
        this.succesfulPayment(true);
      } else {
        this.alertmodal = false;
        Swal.fire({
          title: "Error",
          text: "Tu tarjeta fue rechazada",
          icon: "error",
          confirmButtonText: "Aceptar",
          position: "center",
        });
      }
      return;
    } catch (error) {
      console.log(error);
    }
  }

  succesfulPaymentSimple() {
    try {
      window.MC_PIXEL.fireLogMoneyEvent(
        "Subscribe",
        Number(this.planPrice),
        this.moneda
      );
    } catch (err) {}
    Swal.fire({
      title: "Pago realizado exitosamente",
      icon: "success",
      confirmButtonText: "Aceptar",
      position: "center",
    });
    localStorage.setItem("reload", "true");
    //fbq('track', 'Purchase', {currency: this.moneda, value: parseInt(this.planPrice)});
    try {
      fbq("track", "Subscribe", {
        value: this.planPrice,
        currency: this.moneda,
        predicted_ltv: this.planPrice,
      });
    } catch (err) {}
    window.location.assign("/#/success");
  }

  succesfulPayment(stripe) {
    window.MC_PIXEL.fireLogMoneyEvent(
      "Subscribe",
      Number(this.planPrice),
      this.moneda
    );
    Swal.fire({
      title: "Pago realizado exitosamente",
      icon: "success",
      confirmButtonText: "Aceptar",
      position: "center",
    });
    this.mysql.deleteAbandonedCart(this.user.id).subscribe(
      (res) => {
        localStorage.setItem("reload", "true");
        //fbq('track', 'Purchase', {currency: this.moneda, value: parseInt(this.planPrice)});
        fbq("track", "Subscribe", {
          value: this.planPrice,
          currency: this.moneda,
          predicted_ltv: this.planPrice,
        });
        window.location.assign("/#/success");
      },
      (err) => {
        localStorage.setItem("reload", "true");
        //fbq('track', 'Purchase', {currency: this.moneda, value: parseInt(this.planPrice)});
        fbq("track", "Subscribe", {
          value: this.planPrice,
          currency: this.moneda,
          predicted_ltv: this.planPrice,
        });
        window.location.assign("/#/success");
      }
    );
  }

  setupPaypal() {
    /* paypal
      .Buttons({
        style: {
          shape: "pill",
          color: "blue",
          layout: "horizontal",
          label: "paypal",
        }, */
    /*         createOrder: (data: any, actions: any) => {
          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  currency_code: 'USD',
                  value: 50,||
                },
              },
            ],
          });
        }, */
    /* createSubscription: (data, actions) => {
          return actions.subscription.create({
            plan_id: this.paypalId,
          });
        },
        onApprove: (data, actions) => {
          console.log(data);
          console.log("oa");
          this.auth.getUser().then((res) => {
            if (!res) return;
            const user = {
              paypalSubscription: data.subscriptionID,
              membership: {
                planPrice: this.planPriceMX,
                type: "paypal",
                status: "active",
              },
            };
            this.db.updateUser(res.uid, user).then((res) => {
              this.succesfulPayment(false);
            });
          });
        },
        onCancel: () => {
          this.setPayment("tarjeta");
        },
        onClick: () => {
          this.selected = "paypal";
          this.coupon = null;
          this.total = this.planPrice;
          this.disc = "";
          this.couponStatus = "";
          this.couponCode = "";
        },
        onError: (err) => {
          console.log(err);
        },
      })
      .render(this.paypalElement.nativeElement); */
  }

  async payWithSpei() {
    this.alertmodal = true;
    /* console.log(this.user); */
    let price = parseInt(this.planPrice);
    let plan = "UH" + this.planType;
    let generateSpeiOrder = firebase
      .functions()
      .httpsCallable("conektaSpeiOrder");
    generateSpeiOrder({
      name: this.user.name,
      phone: this.user.phone,
      email: this.user.email,
      price,
      plan,
    }).then(
      (res: any) => {
        this.cancelSubs();
        let pay_method = res.data.charges.data[0].payment_method;
        this.spei_data.clabe = pay_method.clabe;
        this.spei_data.price = res.data.amount / 100;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  payWithOxxo() {
    this.alertmodal = true;
    if (
      // this.user.oxxoTicket == undefined ||
      // this.user.oxxoTicket.expires_at < Date.now() / 1000 ||
      // this.user.oxxoTicket.price != this.planPrice ||
      // this.user.membership != undefined
      true
    ) {
      let name = this.user.name;
      let email = this.user.email;
      let price = parseInt(this.planPrice);
      let plan = "UH" + this.planType;
      this.mysql.createOxxoPay(name, email, plan, price).subscribe(
        (res: any) => {
          this.cancelSubs();
          this.showOxxoPay = true;
          this.alertmodal = false;
          this.user.oxxoTicket = res.charges.data[0].payment_method;
          let usr = {
            oxxoTicket: this.user.oxxoTicket,
          };
          usr.oxxoTicket.price = this.planPrice;
          this.db.updateUser(this.user.id, usr);
          this.sendOxxoMail(this.user);
          console.log(res.charges.data[0].payment_method);
        },
        (err) => {
          this.alertmodal = false;
          let error = "";
          try {
            error = JSON.stringify(err);
          } catch (error) {
            console.log(error);
          }
          window.alert(
            `Hubo un problema al generar tu ticket, porfavor refresca la página o ponte en contacto con nosotros. ERROR: ${error}`
          );
          console.log(err);
        }
      );
    } else {
      this.showOxxoPay = true;
      this.alertmodal = false;
      this.sendOxxoMail(this.user);
    }
  }

  sendOxxoMail(user) {
    let SOM = firebase.functions().httpsCallable("sendOxxoMail");
    SOM({ user }).then(
      (res) => {
        console.log(res);
      },
      (err) => {
        console.log(err);
      }
    );
  }
}

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyDo5eoqVlVNRojCiD2OqVhDgGKWFrjjeHk",
    authDomain: "djulises-89fef.firebaseapp.com",
    databaseURL: "https://djulises-89fef.firebaseio.com",
    projectId: "djulises-89fef",
    storageBucket: "djulises-89fef.appspot.com",
    messagingSenderId: "775545258836",
    appId: "1:775545258836:web:402fd8bdcf535a182ffec1",
    measurementId: "G-DGMKK0KTFL"
  },
  ac: "57126e4ccd44ae58da2a4ea52aa87fa7870ecc352bd94e118b018afc5b4cd737606b1347",
  // endpoint:"http://144.217.74.59:3000",
  // endpoint:"http://localhost:3000",
  endpoint: "https://uhremix.com/api",
  emailRedirect: "http://localhost:4201",

  limit: "50",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

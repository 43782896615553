<div class="container">
  <div class="first-column">
    <h2>Forma de pago</h2>
    <div
      class="pay-option"
      [ngClass]="{ active: selected === 'tarjeta' }"
      (click)="setPayment('tarjeta')"
    >
      <span><span class="min"></span></span>
      <p>Tarjeta de credito/débito</p>
    </div>
    <div
      class="card-form"
      *ngIf="randomPaymentType != 'mercado' && randomPaymentType != 'paypal'"
    >
      <div class="card-element">
        <p>Nombre en la Tarjeta</p>
        <input [(ngModel)]="card.name" name="card.name" />
      </div>
      <div class="card-element">
        <p>Número de la tarjeta</p>
        <input
          [(ngModel)]="card.number"
          name="card.number"
          mask="0000-0000-0000-0000"
          [ngClass]="{ cerror: errors.number != '' }"
        />
      </div>
      <div class="three">
        <div class="card-element">
          <p>Mes de venc.</p>
          <select
            [(ngModel)]="card.month"
            (change)="changeMonth(card.month)"
            name="card.month"
            [ngClass]="{ cerror: errors.month != '' }"
          >
            <option *ngFor="let m of months; let i = index" [value]="i + 1">
              {{ m }} ({{ i + 1 }})
            </option>
          </select>
          <p *ngIf="errors.month != ''" class="red">{{ errors.month }}</p>
        </div>
        <div class="card-element">
          <p>Año de venc.</p>
          <select
            [(ngModel)]="card.year"
            (change)="changeMonth(card.year)"
            name="card.year"
            [ngClass]="{ cerror: errors.year != '' }"
          >
            <option *ngFor="let y of years" [value]="y">{{ y }}</option>
          </select>
        </div>
        <div class="card-element">
          <p>CVC</p>
          <input
            placeholder="123"
            type="password"
            [(ngModel)]="card.cvc"
            name="card.cvc"
            [ngClass]="{ cerror: errors.cvv != '' }"
          />
        </div>
      </div>
    </div>
    <div
      class="pay-option"
      *ngIf="moneda !== 'COP' && randomPaymentType != 'paypal'"
      [ngClass]="{
        active: selected == 'paypal'
      }"
    >
      <div class="uh_paypal" (click)="setPayment('paypal')"></div>
      <span><span class="min"></span></span>
      <p>Paypal</p>
    </div>
    <!-- <div
      *ngIf="moneda === 'MXN'"
      class="pay-option"
      [ngClass]="{ active: selected == 'mercado' }"
      (click)="setPayment('mercado')"
    >
      <span><span class="min"></span></span>
      <p>Mercado Pago</p>
    </div> -->
    <div
      *ngIf="moneda === 'MXN' && !this.db.cuponAtive"
      class="pay-option"
      [ngClass]="{ active: selected == 'oxxo' }"
      (click)="setPayment('oxxo')"
      (click)="payWithOxxo()"
    >
      <span><span class="min"></span></span>
      <p>Oxxo</p>
    </div>
    <div
      *ngIf="moneda === 'MXN' && !this.db.cuponAtive"
      class="pay-option"
      [ngClass]="{ active: selected == 'spei' }"
      (click)="setPayment('spei')"
      (click)="payWithSpei()"
    >
      <span><span class="min"></span></span>
      <p>SPEI</p>
    </div>
  </div>

  <div class="second-column">
    <h2>Resumen de compra</h2>
    <div class="cart">
      <div class="element">
        <div class="row bold">
          <p>{{ planType }}</p>
          <p class="r">{{ planPrice | currency }} {{ moneda }}</p>
        </div>
        <div class="row light">
          <p>{{ description }}</p>
          <p class="r">x1</p>
        </div>
      </div>
      <div class="fader"></div>
    </div>
    <div
      class="disccount"
      *ngIf="selected == 'tarjeta' || selected == 'paypal'"
    >
      <p>Descuento</p>
      <div class="input">
        <input
          [ngClass]="{
            cerror: couponStatus == 'error',
            cgood: couponStatus == 'succ'
          }"
          class="coupon"
          placeholder="Ingresa aquí el código"
          (keyup.enter)="exchangeCoupon()"
          [(ngModel)]="couponCode"
        />
        <!-- <span (click)="exchangeCoupon()"></span> -->
        <button (click)="exchangeCoupon()" class="full outline">
          Canjear cupón
        </button>
      </div>
    </div>
    <div>
      <div class="row light">
        <p>Subtotal:</p>
        <p class="r">
          {{ planPrice | currency }} <span>{{ moneda }} </span>
        </p>
      </div>
      <div class="row blue" *ngIf="disc != ''">
        <p>Descuento:</p>
        <p class="r">- {{ disc | currency }} {{ moneda }}</p>
      </div>

      <div class="row bold">
        <p>Total</p>
        <p class="r">{{ total | currency }} {{ moneda }}</p>
      </div>
      <button
        class="full blue"
        (click)="pay()"
        [class.hidden]="
          !(selected == 'tarjeta' && randomPaymentType == 'stripe') &&
          selected != 'paypal'
        "
      >
        Pagar con {{ selected }}
      </button>
      <button
        class="full blue"
        (click)="payWithConekta()"
        *ngIf="selected == 'tarjeta' && randomPaymentType == 'conekta'"
      >
        Pagar con Tarjeta
      </button>
      <button
        class="full mercado-button"
        (click)="payWithMercado()"
        *ngIf="selected == 'mercado'"
      >
        <img src="/assets/icons/mercadopagobutton.svg" alt="" />
      </button>
      <div
        #paypalSub
        class=""
        [class.hidden]="selected != 'tarjeta' || randomPaymentType != 'paypal'"
      ></div>
      <p
        style="font-size: 12px; text-align: center"
        *ngIf="selected == 'mercado' && planPrice == '99'"
      >
        Mercado pago cobrará una comisión de $1 (Total: $100 MXN)
      </p>
      <div #paypal class="hidden"></div>
    </div>
  </div>
</div>

<div class="oxxo_pay" [ngClass]="{ active: showOxxoPay }" *ngIf="showOxxoPay">
  <div class="overlai" (click)="showOxxoPay = false"></div>
  <div class="ticket">
    <div class="logos">
      <img class="uh" src="/assets/logo_b.svg" />
      <img src="/assets/payments/oxxoLogo.png" />
    </div>
    <div class="up">
      <p class="text">Monto a pagar</p>
      <h2>$ {{ planPrice | number }} {{ moneda }}</h2>
      <div class="reference">
        <p class="text">Referencia</p>
        <h2>{{ user.oxxoTicket.reference }}</h2>
      </div>
      <img [src]="user.oxxoTicket.barcode_url" />
      <button class="full blue" routerLink="/music">
        Ver el contenido de la página
      </button>
      <button class="outline blue" (click)="showOxxoPay = false">
        Usar otro método de pago (cerrar ventana)
      </button>
    </div>
    <div class="down">
      <p class="text">Instrucciones</p>
      <ol>
        <li>Acude a la tienda OXXO más cercana.</li>
        <li>
          Indica en caja que quieres realizar un pago de
          <span class="bold">OXXOPay</span>.
        </li>
        <li>
          Dicta al cajero el número de referencia en esta ficha para que la
          teclée directamente en la pantalla de venta.
        </li>
        <li>Realiza el pago correspondiente con dinero en efectivo.</li>
        <li>
          Al confirmar tu pago, el cajero te engregará un comprobante impreso.
          <span class="bold"
            >En él podrás verificar que se haya realizado correctamente.</span
          >
          Conserva este comprobante de pago.
        </li>
        <li>
          Paga cada mes con
          <span class="bold">este mismo número de referencia</span>.
        </li>
        <li>
          Este número de referencia tiene validez de
          <span class="bold">un año</span> ,cuando sea invalido la página te
          generara uno nuevo.
        </li>
      </ol>
    </div>
  </div>
</div>
<div class="space"></div>
<ngx-spinner
  bdColor="rgba(10, 14, 31,0.8)"
  size="medium"
  color="#0066ff"
></ngx-spinner>

<div class="alertmodal" *ngIf="alertmodal">
  <ng-container
    *ngIf="
      selected == 'oxxo' ||
      (selected == 'spei' && !spei_data.clabe) ||
      selected == 'tarjeta'
    "
  >
    <div class="alertmodal-back"></div>
    <div class="alertmodal-main">
      <div>
        <p>
          Cargando, por favor espere <br />
          no cierre esta ventana
        </p>
      </div>
      <div>
        <img class="loading-icon" src="/assets/icons/loading.svg" alt="" />
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="selected == 'spei' && spei_data.clabe">
    <div class="alertmodal-back" (click)="alertmodal = false"></div>
    <div class="alertmodal-main ps">
      <div class="ps-header">
        <div class="ps-reminder">Ficha digital. No es necesario imprimir.</div>
        <div class="ps-info">
          <div class="ps-brand">
            <img src="/assets/payments/spei_brand.png" alt="Banorte" />
          </div>
          <div class="ps-amount">
            <h3>Monto a pagar</h3>
            <h2>$ {{ spei_data.price }} <sup>MXN</sup></h2>
            <p>Utiliza exactamente esta cantidad al realizar el pago.</p>
          </div>
        </div>
        <div class="ps-reference">
          <h3>CLABE</h3>
          <h1>{{ spei_data.clabe }}</h1>
        </div>
      </div>
      <div class="ps-instructions">
        <h3>Instrucciones</h3>
        <ol>
          <li>Accede a tu banca en línea.</li>
          <li>
            Da de alta la CLABE en esta ficha.
            <strong>El banco deberá de ser STP</strong>.
          </li>
          <li>
            Realiza la transferencia correspondiente por la cantidad exacta en
            esta ficha, <strong>de lo contrario se rechazará el cargo</strong>.
          </li>
          <li>
            Al confirmar tu pago, el portal de tu banco generará un comprobante
            digital.
            <strong
              >En el podrás verificar que se haya realizado
              correctamente.</strong
            >
            Conserva este comprobante de pago.
          </li>
        </ol>
        <div class="ps-footnote">
          Al completar estos pasos recibirás un correo de
          <strong>UHREMIXES</strong> confirmando tu pago.
        </div>
      </div>
    </div>
  </ng-container>
</div>

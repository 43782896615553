import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { DbService } from "src/app/services/db.service";
@Component({
  selector: "app-premium",
  templateUrl: "./premium.component.html",
  styleUrls: ["./premium.component.scss"],
})
export class PremiumComponent implements OnInit {
  backupItems = [];
  items = [];
  searchWord = "";

  couponName = "";
  discamount = "";
  mxamount = "";
  duration = "";

  constructor(private spinner: NgxSpinnerService, private db: DbService) {}
  ngOnInit(): void {
    this.db.getPremiums().subscribe((res) => {
      this.items = res;
      this.backupItems = res;
      console.log("res: ", res);
    });
  }

  create() {}

  delete(id) {
    console.log(id);
    this.db.removePremium(id).then((res) => {
      console.log(res);
    });
  }

  searchFor() {
    console.log(this.searchWord);
    if (this.searchWord == "") {
      this.items = this.backupItems;
    } else {
      this.items = this.items.filter((current) => {
        if (current.title && this.searchWord) {
          if (
            current.title.toLowerCase().indexOf(this.searchWord.toLowerCase()) >
            -1
          ) {
            return true;
          }
          return false;
        }
      });
    }
  }
}

import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { numbers } from "./phoneNumbersj";
import Swal from "sweetalert2";
import * as firebase from "firebase/app";
import { AuthService } from "src/app/services/auth.service";
import { DbService } from "src/app/services/db.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-adminnewuser",
  templateUrl: "./adminnewuser.component.html",
  styleUrls: ["./adminnewuser.component.scss"],
})
export class AdminnewuserComponent implements OnInit {
  public numbers = numbers;

  public planForm = new FormGroup({
    subscriptionStart: new FormControl("", Validators.required),
    subscriptionEnd: new FormControl("", Validators.required),
    planPrice: new FormControl("", Validators.required),
    type: new FormControl("", Validators.required),
  });
  public generalForm = new FormGroup({
    name: new FormControl("", Validators.required),
    email: new FormControl("", Validators.required),
    password: new FormControl("", Validators.required),
    code: new FormControl("", Validators.required),
    phone: new FormControl("", Validators.required),
  });

  constructor(
    private auth: AuthService,
    private spinner: NgxSpinnerService,
    private db: DbService,
    private router: Router
  ) {}

  ngOnInit(): void {}

  public async newUser() {
    if (this.generalForm.invalid) {
      Swal.fire({
        title: "Formulario invalido",
        text: "Todos los campos del formulario general son obligatorios",
        icon: "error",
        confirmButtonText: "Aceptar",
        position: "center",
      });
      return;
    }
    let result;
    if (this.planForm.valid) {
      result = await Swal.fire({
        title: "Crear usuario",
        text: "Crearas un usuario con un plan activo",
        icon: "question",
        confirmButtonText: "Aceptar",
        position: "center",
        showCancelButton: true,
        showConfirmButton: true,
      });
    } else {
      result = await Swal.fire({
        title: "Crear usuario",
        text: "Crearas un usuario sin plan activo",
        icon: "question",
        confirmButtonText: "Aceptar",
        position: "center",
        showCancelButton: true,
        showConfirmButton: true,
      });
    }
    if (!result.isConfirmed) {
      return;
    }
    this.spinner.show();
    let value = this.planForm.value;
    let general = this.generalForm.value;
    let subscriptionStart = new Date(value.subscriptionStart);
    subscriptionStart.setDate(subscriptionStart.getDate() + 1);
    let subscriptionEnd = new Date(value.subscriptionEnd);
    subscriptionEnd.setDate(subscriptionEnd.getDate() + 1);
    try {
      let newUser = await firebase.functions().httpsCallable("createNewUser");
      let response: any = await newUser({
        email: general.email,
        password: general.password,
      });
      console.log(response.data.success);
      if (!response.data.success) {
        Swal.fire({
          title: "Error al crear el usuario",
          text: response.data.data,
          icon: "error",
          confirmButtonText: "Aceptar",
          position: "center",
        });
        return;
      }
      const id = response.data.data;
      let membership = {
        subscriptionStart,
        subscriptionEnd,
        planPrice: value.planPrice,
        type: value.type,
        status: "active",
        subscription_id: "",
        userId: id,
        amount: this.planForm.controls.planPrice.value,
        paymentIntent_id: "",
        customer_id: "",
        isPay: true,
        cancelled: false,
      };
      let payment = {
        ...membership,
        customer_id: "",
        paymentIntent_id: "",
      };
      let user = {
        membership: this.planForm.valid ? membership : null,
        name: general.name,
        email: general.email,
        phone: general.code + general.phone,
        tokens: this.auth.tokens,
        isVerified: true,
      };
      console.log(user);
      await this.db.afs.collection("users").doc(id).set(user);
      if (this.planForm.valid) {
        await this.db.createPayment(payment);
      }
      setTimeout(() => {
        this.spinner.hide();
        this.router.navigate(["admin/users/", id, user.name]);
        Swal.fire({
          title: "Usuario creado",
          text: "Usuario creado con exito",
          icon: "success",
          confirmButtonText: "Aceptar",
          position: "center",
        });
      }, 1000);
    } catch (error) {
      this.spinner.hide();
      Swal.fire({
        title: "Error al crear el usuario",
        text: JSON.stringify(error),
        icon: "error",
        confirmButtonText: "Aceptar",
        position: "center",
      });
    }
  }
}

<div class="background" *ngIf="!completeProfile"></div>
<div [ngClass]="{ container: !completeProfile }">
  <img src="/assets/logo.svg" *ngIf="!completeProfile" />
  <!-- <form (ngSubmit)="signUp()" class="form"> -->
  <div>
    <form class="form pb-0" [ngClass]="{ completeProfile: completeProfile }">
      <p *ngIf="!completeProfile">Rellena tus datos personales</p>
      <input required [(ngModel)]="name" [formControl]="form.controls.name" name="name" placeholder="Nombre"
        type="text" />
      <p class="input-error" *ngIf="form.get('name').touched && form.get('name').hasError('required')">Campo obligatorio
      </p>
      <p class="input-error" *ngIf="form.get('name').touched && form.get('name').hasError('pattern')">
        Solo se permite los siguientes caracteres. (a-z A-Z)
      </p>
      <input required [(ngModel)]="mail" [formControl]="form.controls.mail" name="mail" placeholder="Correo"
        type="email" *ngIf="!completeProfile" />
      <p class="input-error" *ngIf="form.get('mail').touched && form.get('mail').hasError('required')">Campo obligatorio
      </p>
      <p class="input-error" *ngIf="form.get('mail').touched && form.get('mail').hasError('email')">Ingresa un correo
        electronico valido</p>
      <input required [(ngModel)]="password" [formControl]="form.controls.password" name="password"
        placeholder="Contraseña" type="password" *ngIf="!completeProfile" />
      <p class="input-error" *ngIf="form.get('password').touched && form.get('password').hasError('required')">Campo
        obligatorio
      </p>
      <p class="input-error" *ngIf="form.get('password').touched && form.get('password').hasError('minlength')">Solo se
        permite como minimo 6 caracteres</p>
      <div class="phone">
        <input required [(ngModel)]="phone_code" [formControl]="form.controls.phone_code" list="phone-coodes"
          name="browser" id="browser" />
        <datalist id="phone-coodes">
          <option *ngFor="let n of numbers" [value]="n.code">
            {{ n.display }}
          </option>
        </datalist>
        <input required [(ngModel)]="phone" [formControl]="form.controls.phone" name="phone" placeholder="Celular"
          type="phone" maxlength="10" minlength="10" />
      </div>
      <p class="input-error" *ngIf="form.get('phone_code').touched && form.get('phone_code').hasError('required')">El
        Codigo del telefono es un campo obligatorio</p>
      <p class="input-error" *ngIf="form.get('phone').touched && form.get('phone').hasError('required')">El Telefono es
        un campo
        obligatorio</p>
      <p class="input-error" *ngIf="form.get('phone').touched && form.get('phone').hasError('pattern')">Solo se permite
        los siguientes caracteres. (0-9)</p>
      <p class="input-error" *ngIf="form.get('phone').touched && form.get('phone').hasError('minlength')">Solo se
        permite como minimo 10 caracteres</p>
      <p class="input-error" *ngIf="form.get('phone').touched && form.get('phone').hasError('maxlength')">Solo se
        permite como maximo 10 caracteres</p>
    </form>
    <div [ngClass]="{ completeProfile: completeProfile }" class="form">
      <div class="mcwidget-checkbox" data-widget-id="16621268"></div>
      <button (click)="signUp()" class="fulla">
        <!-- (click)="MessengerFacebook.getWidget(16621268).submit()" -->
        {{ !completeProfile ? "Registrarse" : "Completar registro" }}
      </button>
      <!--   <div class="logins" *ngIf="!completeProfile">
        <div class="google" (click)="signGoogle()"></div>
          <div class="facebook" (click)="signFacebook()"></div>
      </div> -->
      <p class="small center">
        Al
        {{ !completeProfile ? "registrarse" : "completar el registro" }} aceptas
        los
        <a class="bold" href="/#/terminos-y-condiciones" target="_blank">Terminos de uso</a>
        y la
        <a class="bold" href="/#/aviso-de-privacidad" target="_blank">Pólitica de privacidad</a>
      </p>

      <p class="end" *ngIf="!completeProfile">Ya tienes cuenta?</p>
      <a class="blue pointer underline" routerLink="/login" *ngIf="!completeProfile">Iniciar Sesión</a>
    </div>
  </div>
</div>
<app-modal [visible]="visible" [text]="text" [title]="title" [link]="link"></app-modal>
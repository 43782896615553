import { Component, OnInit } from "@angular/core";
import { DbService } from "src/app/services/db.service";
import { MysqlService } from "src/app/services/mysql.service";

@Component({
  selector: "app-admingenres",
  templateUrl: "./admingenres.component.html",
  styleUrls: ["./admingenres.component.scss"],
})
export class AdmingenresComponent implements OnInit {
  genre = "";
  genres = [];
  constructor(private db: DbService, private mysql: MysqlService) {}
  ngOnInit(): void {
    this.mysql.getGenre().subscribe((res: any) => {
      this.genres = res.genres;
    });
  }
  createGenre() {
    if (this.genre != "") {
      this.mysql.addGenre(this.genre).subscribe(
        (res) => {
          console.log(res);
          this.genres.unshift({ genre: this.genre });
          this.genre = "";
        },
        (error) => {
          console.log(error);
          alert("Nombre ya existe, o esta vacio");
        }
      );
    }
  }

  del(genre, index) {
    console.log(genre, index);
    this.mysql.deleteGenre(genre).subscribe(
      (res) => {
        console.log(res)
        this.genres.splice(index, 1);
      },
      (err) => {
        console.log(err);
        alert("Ocurrio un error al eliminar, revisa que no haya un producto usando este genero");
      }
    );
    
  }
}

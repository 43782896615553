<div
  class="navbar scrollDown"
  [ngClass]="{ scrollDown: isScrollingDown, start: scrollPos === 0 }"
>
  <div class="bg">
    <img routerLink="/" src="/assets/logo.svg" />
    <img
      class="menu right"
      src="/assets/icons/menu.svg"
      (click)="show = !show"
    />
    <!-- USER IS NOT SIGNED -->
    <div class="right nweb" [ngClass]="{ show: show }" *ngIf="!userIsSignedIn">
      <!-- <a routerLinkActive="active" routerLink="/contact">Contáctanos</a> -->
      <!-- <a href="https://uhremixes.com/" target="_blank">Tienda</a> -->
      <a routerLinkActive="active" routerLink="/plans">Precios</a>
      <a routerLinkActive="active" routerLink="/music">Música</a>
      <a routerLinkActive="active" routerLink="/videos">Videos</a>
      <a routerLinkActive="active" routerLink="/karaoke">Karaoke</a>
      <a routerLinkActive="active" routerLink="/combosvid">Combos Videos</a>
      <a routerLinkActive="active" routerLink="/combosmus">Combos Música</a>
      <!-- <a routerLinkActive="active" routerLink="/premium">Premium</a> -->
      <a routerLinkActive="active" routerLink="/login">Iniciar Sesión</a>
      <a routerLinkActive="active" routerLink="/signup" class="fulla"
        >Registrarse</a
      >
    </div>

    <!-- USER IS SIGNED NOT ADMIN-->
    <div
      class="right nweb"
      [ngClass]="{ show: show }"
      *ngIf="userIsSignedIn && !user?.isAdmin"
    >
      <!-- <a routerLinkActive="active" routerLink="/contact">Contáctanos</a> -->
      <a
        routerLinkActive="active"
        *ngIf="user?.membership == undefined"
        routerLink="/plans"
        >Precios</a
      >
      <!-- <a href="https://uhremixes.com/" target="_blank">Tienda</a> -->
      <a routerLinkActive="active" routerLink="/music">Música</a>
      <a routerLinkActive="active" routerLink="/videos">Videos</a>
      <a routerLinkActive="active" routerLink="/karaoke">Karaoke</a>
      <a routerLinkActive="active" routerLink="/combosvid">Combos Videos</a>
      <a routerLinkActive="active" routerLink="/combosmus">Combos Música</a>
      <!-- <a routerLinkActive="active" routerLink="/premium">Premium</a> -->
      <a routerLinkActive="active" routerLink="/playlist">Mi Playlist</a>
      <a routerLinkActive="active" routerLink="/myaccount" class="fulla"
        >Mi Cuenta</a
      >
    </div>

    <div
      class="right nweb"
      [ngClass]="{ show: show }"
      *ngIf="userIsSignedIn && user?.isAdmin"
    >
      <a routerLinkActive="active" routerLink="/admin/home">Home</a>
      <a routerLinkActive="active" routerLink="/admin/marketing">Marketing</a>
      <a routerLinkActive="active" routerLink="/admin/email">E-mail</a>
      <a routerLinkActive="active" routerLink="/admin/music">Productos</a>
      <!--    <a routerLinkActive="active" routerLink="/admin/videos">Videos</a>
      <a routerLinkActive="active" routerLink="/admin/karaoke">Karaoke</a>
      <a routerLinkActive="active" routerLink="/admin/combos">Combos</a> -->

      <a routerLinkActive="active" routerLink="/admin/upload">Subir</a>
      <!-- <a routerLinkActive="active" routerLink="/admin/premium">Premium</a> -->
      <a routerLinkActive="active" routerLink="/admin/genres">Géneros</a>
      <a routerLinkActive="active" routerLink="/admin/users">Usuarios</a>
      <a routerLinkActive="active" routerLink="/admin/charges">Pagos</a>
      <a routerLinkActive="active" routerLink="/admin/coupons">Cupones</a>

      <a class="fulla" (click)="logout()">Cerrar Sesión</a>
    </div>
  </div>
</div>

<div class="plans">
  <h2 class="center">Elige el mejor plan para ti...</h2>
  <br />
  <div class="text-center">
    <p class="bold">Ver precios en</p>
  </div>
  <div class="container-currency">
    <div class="type-currency">
      <p>Pesos Mexicanos</p>
      <i (click)="selected_type_currency = ''" *ngIf="selected_type_currency === 'mxn'" class="fa-solid fa-circle"></i>
      <i (click)="selected_type_currency = 'mxn'" *ngIf="selected_type_currency !== 'mxn'"
        class="fa-regular fa-circle"></i>
    </div>
    <div class="type-currency">
      <p>Dólares</p>
      <i (click)="selected_type_currency = ''" *ngIf="selected_type_currency === 'usd'" class="fa-solid fa-circle"></i>
      <i (click)="selected_type_currency = 'usd'" *ngIf="selected_type_currency !== 'usd'"
        class="fa-regular fa-circle"></i>
    </div>
    <div class="type-currency">
      <p>Pesos Colombianos</p>
      <i (click)="selected_type_currency = ''" *ngIf="selected_type_currency === 'cop'" class="fa-solid fa-circle"></i>
      <i (click)="selected_type_currency = 'cop'" *ngIf="selected_type_currency !== 'cop'"
        class="fa-regular fa-circle"></i>
    </div>
  </div>
  <br />
  <br />
  <table class="web">
    <thead>
      <tr>
        <td></td>
        <ng-container *ngFor="let plan of plans">
          <td>
            <div class="table_header">
              <p class="bold">{{ plan.name }}</p>
              <p class="value" [ngClass]="{'sizecop': selected_type_currency === 'cop'}">
                <span>$ </span> {{ (selected_type_currency === 'mxn' ? plan.price : selected_type_currency === 'usd' ?
                plan.priceUSD : plan.priceCOP) | number}}
              </p>
              <p class="dim"><span class="text-uppercase">{{ selected_type_currency }}</span>/{{ plan.repeat }}</p>
            </div>
          </td>
        </ng-container>
      </tr>
      <tr *ngFor="let row of valuesNames; let i = index" class="row">
        <td>{{ row }}</td>
        <ng-container *ngFor="let plan of plans">
          <td class="check">
            <span [ngClass]="{ true: plan.values[i] }"></span>
          </td>
        </ng-container>
      </tr>
      <tr>
        <td></td>
        <ng-container *ngFor="let plan of plans">
          <td>
            <a class="fulla" (click)="inicioCompra()" [routerLink]="
                plan.link + ( selected_type_currency === 'mxn' ? plan.price : selected_type_currency === 'usd' ?
                plan.priceUSD : plan.priceCOP)
              ">Comprar</a>
          </td>
        </ng-container>
      </tr>
    </thead>
  </table>

  <ng-container *ngFor="let plan of plans">
    <div class="plan mobile">
      <p class="bold">{{ plan.name }}</p>
      <p class="value">
        <span>$ </span> {{ (selected_type_currency === 'mxn' ? plan.price : selected_type_currency === 'usd' ?
        plan.priceUSD : plan.priceCOP) | number}}
      </p>
      <p class="dim"><span class="text-uppercase">{{ selected_type_currency }}</span>/{{ plan.repeat }}</p>
      <p class="txt" *ngFor="let val of plan.values; let i = index">
        <span class="txt" *ngIf="val">{{ valuesNames[i] }}</span>
      </p>
      <a class="fulla" (click)="inicioCompra()" [routerLink]="
          plan.link + ( selected_type_currency === 'mxn' ? plan.price : selected_type_currency === 'usd' ?
          plan.priceUSD : plan.priceCOP)
        ">Comprar {{ plan.name }}</a>
    </div>
  </ng-container>
</div>
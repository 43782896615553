<div class="container">
  <h3>{{ id === "add" ? "Crear" : "Actualizar" }}</h3>
  <uploader [genre]="" title="Imagen" type="premium" (myImage)="emitedImage($event)"></uploader>

  <p>Título</p>
  <input [(ngModel)]="premium.title" name="tok" placeholder="Título" (keyup)="messagePreviewChange()" />

  <p>Link</p>
  <input [(ngModel)]="premium.link" name="tok2" placeholder="Ingresa el link" (keyup)="messagePreviewChange()" />

  <p>ID de carpeta</p>
  <p class="description">
    Al ingresar el ID de carpeta se habilitará la descarga directa del zip
  </p>
  <input [(ngModel)]="premium.idFolder" placeholder="Ingresa el id de la carpeta" (keyup)="messagePreviewChange()" />

  <p>Demo</p>
  <input [(ngModel)]="premium.demo" name="premium.demo" placeholder="Ingresa el demo" />

  <p>Fecha</p>
  <select id="premium.month" placeholder="Mes" [(ngModel)]="premium.month" name="premium.month" class="selector">
    <option *ngFor="let m of months; let i = index" [value]="i">{{ m }}</option>
  </select>
  <select id="premium.year" placeholder="Año" [(ngModel)]="premium.year" name="premium.year" class="selector">
    <option *ngFor="let y of years; let i = index" [value]="y">{{ y }}</option>
  </select>

  <p>Info</p>
  <textarea id="headline" rows="6" [(ngModel)]="premium.info" name="headline"
    placeholder="Ingresa el contenido"></textarea>

  <p>Imagen</p>
  <input [(ngModel)]="premium.image" name="tok3" placeholder="Ingresa el link" />

  <p>Activo</p>
  <select id="premium.hide" placeholder="Ocultar" [(ngModel)]="premium.hide" name="premium.hide" class="selector">
    <option value="">Si</option>
    <option value="Si">No</option>
  </select>
  <button (click)="saveChanges()" class="full blue">Guardar</button>
</div>
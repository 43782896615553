import { BrowserModule } from "@angular/platform-browser";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";

import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

import { LoginComponent } from "./pages/login/login.component";
import { HomeComponent } from "./pages/home/home.component";
import { NavbarComponent } from "./components/navbar/navbar.component";
import { SignupComponent } from "./pages/signup/signup.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { NgxSpinnerModule } from "ngx-spinner";
import { ToastrModule } from "ngx-toastr";

import { environment } from "../environments/environment";
// import { AngularFireModule } from 'angularfire2';
// import { AngularFireDatabaseModule } from 'angularfire2/database';
// import { AngularFireAuthModule } from 'angularfire2/auth';

import { AuthService } from "./services/auth.service";
import { MusicComponent } from "./pages/music/music.component";
import { VideosComponent } from "./pages/videos/videos.component";
import { ModalComponent } from "./components/modal/modal.component";

import { AngularFireModule } from "@angular/fire";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { AdminmusicComponent } from "./pages/adminmusic/adminmusic.component";
import { AdminvideosComponent } from "./pages/adminvideos/adminvideos.component";
import { DragNDropDirective } from "./directives/drag-n-drop.directive";
import { UploaderComponent } from "./components/uploader/uploader.component";
import { UploadTaskComponent } from "./components/upload-task/upload-task.component";
import { ToggleComponent } from "./components/toggle/toggle.component";
import { AdminwvideosComponent } from "./pages/adminwvideos/adminwvideos.component";
import { AdminwmusicComponent } from "./pages/adminwmusic/adminwmusic.component";
import { AdmingenresComponent } from "./pages/admingenres/admingenres.component";
import { AdminusersComponent } from "./pages/adminusers/adminusers.component";

import * as firebase from "firebase";
import { AccountComponent } from "./pages/account/account.component";
import { PlansComponent } from "./pages/plans/plans.component";
import { ContactComponent } from "./pages/contact/contact.component";
import { NoRightClickDirective } from "./directives/no-right-click.directive";

import { MatVideoModule } from "mat-video";
import { TerminosComponent } from "./terminos/terminos.component";
import { AvisoComponent } from "./aviso/aviso.component";
import { ReembolsoComponent } from "./reembolso/reembolso.component";
import { CargosComponent } from "./pages/cargos/cargos.component";
import { AdminkaraokeComponent } from "./pages/adminkaraoke/adminkaraoke.component";
import { AdmincombosComponent } from "./pages/admincombos/admincombos.component";
import { KaraokeComponent } from "./pages/karaoke/karaoke.component";
import { CombosComponent } from "./pages/combos/combos.component";

import { Ng2TelInputModule } from "ng2-tel-input";
import { PlaylistComponent } from "./pages/playlist/playlist.component";
import { WaveComponent } from "./component/wave/wave.component";
import { AdminhomeComponent } from "./pages/adminhome/adminhome.component";
import { ChoosePlanComponent } from "./pages/choose-plan/choose-plan.component";
import { PayComponent } from "./pages/pay/pay.component";
import { CouponsComponent } from "./pages/coupons/coupons.component";
import { MailComponent } from "./pages/mail/mail.component";
import { PremiumComponent } from "./pages/premium/premium.component";
import { PremiumDetailComponent } from "./pages/premium-detail/premium-detail.component";
import { PremiumUserComponent } from "./pages/premium-user/premium-user.component";
import { CombosMusicaComponent } from "./pages/combos-musica/combos-musica.component";
import { FirebaseInterceptor } from "./interceptors/firebase.interceptor";
import { SharedModule } from "./shared.modulets";
import { SuccessPageComponent } from "./success-page/success-page.component";
import { NgxMaskModule, IConfig } from "ngx-mask";
import { FacebookComponent } from "./pages/facebook/facebook.component";
import { ConfirmEmailComponent } from "./pages/confirm-email/confirm-email.component";
import { CompleteProfileComponent } from "./pages/complete-profile/complete-profile.component";
import { AdminuserdetailComponent } from "./pages/adminuserdetail/adminuserdetail.component";
import { AdminnewuserComponent } from "./pages/adminnewuser/adminnewuser.component";
import { HttpClientModule } from "@angular/common/http";
import { LoginV2Component } from "./v2/login/login.component";
import { SignupV2Component } from "./v2/signup/signup.component";

firebase.initializeApp(environment.firebaseConfig);

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    NavbarComponent,
    SignupComponent,

    MusicComponent,
    VideosComponent,
    ModalComponent,
    AdminmusicComponent,
    AdminvideosComponent,
    DragNDropDirective,
    UploaderComponent,
    UploadTaskComponent,
    ToggleComponent,
    AdminwvideosComponent,
    AdminwmusicComponent,
    AdmingenresComponent,
    AdminusersComponent,
    AccountComponent,
    PlansComponent,
    ContactComponent,
    NoRightClickDirective,
    TerminosComponent,
    AvisoComponent,
    ReembolsoComponent,
    CargosComponent,
    AdminkaraokeComponent,
    AdmincombosComponent,
    KaraokeComponent,
    CombosComponent,
    PlaylistComponent,
    WaveComponent,
    AdminhomeComponent,
    ChoosePlanComponent,
    PayComponent,
    CouponsComponent,
    MailComponent,
    PremiumComponent,
    PremiumDetailComponent,
    PremiumUserComponent,
    CombosMusicaComponent,
    SuccessPageComponent,
    FacebookComponent,
    ConfirmEmailComponent,
    CompleteProfileComponent,
    AdminuserdetailComponent,
    AdminnewuserComponent,
    LoginV2Component,
    SignupV2Component,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    FormsModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    MatVideoModule,
    Ng2TelInputModule,
    SharedModule,
    NgxMaskModule.forRoot(),
    HttpClientModule,
  ],
  providers: [AuthService],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}

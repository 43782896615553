import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { AuthService } from "src/app/services/auth.service";
import { DbService } from "src/app/services/db.service";
import * as firebase from "firebase/app";
import { NgxSpinnerService } from "ngx-spinner";
import { ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import Swal from "sweetalert2";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { numbers } from "../signup/phoneNumbersj";
import { AngularFireFunctions } from "@angular/fire/functions";
declare var Stripe;
declare var paypal;

@Component({
  selector: "app-account",
  templateUrl: "./account.component.html",
  styleUrls: ["./account.component.scss"],
})
export class AccountComponent implements OnInit {
  public alertmodal: boolean = false;

  public editInfo: boolean = false;

  public numbers = numbers;

  public authUser: any;

  public form: FormGroup = new FormGroup({
    name: new FormControl(""),
    code: new FormControl(""),
    phone: new FormControl(""),
    email: new FormControl(""),
    repeat_email: new FormControl(""),
    ant_password: new FormControl(""),
    password: new FormControl(""),
    repeat_password: new FormControl(""),
  });

  @ViewChild("paypal", { static: true }) paypalElement: ElementRef;

  user = {
    id: "",
    name: "",
    email: "",
    stripe_id: "",
    paypalSubscription: "",
    conektaId: "",
    mercadoPagoId: "",
    membership: undefined,
    phone: "",
  };

  plan = {
    status: "Activo",
    type: "",
    name: "Paquete Gratuito",
    price: "Gratis",
    upgrade: true,
  };

  constructor(
    public auth: AuthService,
    public db: DbService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
  ) {}

  async ngOnInit() {
    this.auth.afAuth.user.subscribe((userAuth) => {
      this.authUser = userAuth;
    });
    this.auth.getUser().then((res) => {
      if (!res) return;
      this.db.getUser(res.uid).subscribe((user: any) => {
        this.user = user;
        console.log(this.user);
        this.form.patchValue({
          name: user.name,
          phone: user.phone ? user.phone.slice(3, user.phone.length) : "",
          code: user.phone ? user.phone.slice(0, 3) : "",
        });
        this.user.id = res.uid;

        if (this.user.membership != undefined) {
          // if (this.user.membership != undefined) {
          if (this.user.membership.status == "active") {
            this.plan = {
              name: "Paquete AUDIO+VIDEO",
              price: "149",
              upgrade: true,
              status: this.user.membership.status,
              type: "",
            };
          }
          switch (String(this.user.membership.planPrice)) {
            case "99":
            case "99.00":
            case "8":
            case "8.00":
            case "30250":
            case "30250.00":
              this.plan = {
                name: "Paquete AUDIO",
                price: "99",
                upgrade: true,
                status: this.user.membership.status,
                type: "",
              };
              break;
            case "149":
            case "149.00":
            case "12.00":
            case "12":
            case "45376":
            case "45376.00":
              this.plan = {
                name: "Paquete AUDIO+VIDEO",
                price: "149",
                upgrade: true,
                status: this.user.membership.status,
                type: "",
              };
              break;
            case "199":
            case "199.00":
            case "16":
            case "16.00":
            case "60501":
            case "60501.00":
              this.plan = {
                name: "Paquete FULL",
                price: "199",
                upgrade: false,
                status: this.user.membership.status,
                type: "",
              };
              break;
            case "1899":
            case "1899.00":
            case "145.00":
            case "145":
            case "548296":
            case "548296.00":
              this.plan = {
                name: "Paquete FULL Anual",
                price: "1899",
                upgrade: false,
                status: this.user.membership.status,
                type: "",
              };
              break;
          }
          if (this.user.membership.type != undefined) {
            this.plan.type = this.user.membership.type;
          } else {
            this.plan.type = "stripe";
          }
        }
        console.log(this.user.membership);
      });
    });
  }

  recoverPassword() {
    this.auth.recoverPassword(this.user.email);
    alert(
      "Revisa tu correo, te hemos enviado un link para restablecer tu contraseña"
    );
  }

  logout() {
    localStorage.setItem("reload", "true");
    this.auth.logout();
  }

  openDashboard() {
    this.spinner.show();
    let sid = this.user.stripe_id;
    console.log(sid);
    let customer_portal = firebase.functions().httpsCallable("customer_portal");
    customer_portal({ stripe_id: sid }).then((res) => {
      console.log(res);
      this.spinner.hide();
      window.open(res.data.url, "_blank");
    });
  }

  async cancelConektaSubscription(){
    let result = await Swal.fire({
      title: "Cancelar renovación",
      text: "¿Seguro que quieres cancelar la renovación?",
      icon: "question",
      confirmButtonText: "Si, Acepto",
      cancelButtonText: "No, Cancelar",
      showCancelButton: true,
      showConfirmButton: true,
      position: "center",
    });
    if (!result.isConfirmed) {
      return;
    }
    let cancelSub = firebase.functions().httpsCallable('cancelConektaSubscription')
    cancelSub({conektaId: this.user.conektaId}).then(async (res) => {
      console.log(res)
      this.user.membership.cancelled = true
      await this.db.updateUser(this.user.id,this.user)
      Swal.fire({
        title: "Subcripcion cancelada",
        text: "La renovacion ha sido cancelada con éxito",
        icon: "success",
        confirmButtonText: "Aceptar",
        showConfirmButton: true,
        position: "center",
      });
    })
  }

  async cancelMercadoSubscription(){
    console.log('Mercado');
    let result = await Swal.fire({
      title: "Cancelar renovación",
      text: "¿Seguro que quieres cancelar la renovación?",
      icon: "question",
      confirmButtonText: "Si, Acepto",
      cancelButtonText: "No, Cancelar",
      showCancelButton: true,
      showConfirmButton: true,
      position: "center",
    });
    if (!result.isConfirmed) {
      return;
    }
    let cancelSub = firebase.functions().httpsCallable('cancelMercadoSubscription')
    cancelSub({id: this.user.mercadoPagoId}).then(async (res) => {
      console.log(res)
      this.user.membership.cancelled = true
      await this.db.updateUser(this.user.id,this.user)
      Swal.fire({
        title: "Subcripcion cancelada",
        text: "La renovacion ha sido cancelada con éxito",
        icon: "success",
        confirmButtonText: "Aceptar",
        showConfirmButton: true,
        position: "center",
      });
    })
  }

  public async cancelSubscription(): Promise<void> {
    if (!this.user?.membership?.subscription_id) {
      Swal.fire({
        title: "Error",
        text: "No cuentas con una subscripcion",
        icon: "error",
        confirmButtonText: "Aceptar",
        showConfirmButton: true,
        position: "center",
      });
      return;
    }
    try {
      let result = await Swal.fire({
        title: "Cancelar renovación",
        text: "¿Seguro que quieres cancelar la renovación?",
        icon: "question",
        confirmButtonText: "Si, Acepto",
        cancelButtonText: "No, Cancelar",
        showCancelButton: true,
        showConfirmButton: true,
        position: "center",
      });
      if (!result.isConfirmed) {
        return;
      }
      this.alertmodal = true;
      /* Agregar modal de cargando */
      let delSubscription = firebase
        .functions()
        .httpsCallable("delSubscription");
      let response = await delSubscription(
        this.user.membership.subscription_id
      );
      if (!response.data.success) {
        if (response.data.data?.code === "resource_missing") {
          let membership = this.user.membership;
          membership.cancelled = true;
          await this.db.afs
            .collection("users")
            .doc(this.user.id)
            .update({ membership });
          this.alertmodal = false;
          Swal.fire({
            title: "Subcripcion cancelada",
            text: "La renovacion ha sido cancelada con éxito",
            icon: "success",
            confirmButtonText: "Aceptar",
            showConfirmButton: true,
            position: "center",
          });
          return;
        }
        this.alertmodal = false;
        Swal.fire({
          title: "Error",
          text: "No hemos podido cancelar tu subcripcion intentalo mas tarde",
          icon: "error",
          confirmButtonText: "Aceptar",
          showConfirmButton: true,
          position: "center",
        });
        return;
      }
      let membership = this.user.membership;
      membership.cancelled = true;
      await this.db.afs
        .collection("users")
        .doc(this.user.id)
        .update({ membership });
      this.alertmodal = false;
      Swal.fire({
        title: "Subcripcion cancelada",
        text: "La renovacion ha sido cancelada con éxito",
        icon: "success",
        confirmButtonText: "Aceptar",
        showConfirmButton: true,
        position: "center",
      });
    } catch (error) {
      this.alertmodal = false;
      Swal.fire({
        title: "Error",
        text: "No hemos podido cancelar tu subcripcion intentalo mas tarde",
        icon: "error",
        confirmButtonText: "Aceptar",
        showConfirmButton: true,
        position: "center",
      });
    }
  }

  public async saveInfo(): Promise<void> {
    let error = "";
    const { name, phone, email, ant_password, password, code } =
      this.form.value;
    try {
      error = await this.updateInfo(ant_password, name, phone, code, error);
      if (password) {
        error = await this.changePassword(ant_password, password, error);
      }
      if (email) {
        let errorMail = await this.changeEmail(email, ant_password, error);
        if (!errorMail) {
          await this.db.updateUser(this.user.id, { email });
        }
        error = errorMail;
      }
      if (error !== "Tu contraseña es incorrecta" && error !== "") {
        this.editInfo = false;
        Swal.fire({
          title: "Información actualizada",
          text: JSON.stringify(error),
          icon: "success",
          confirmButtonText: "Aceptar",
          showConfirmButton: true,
          position: "center",
        });
        this.form.reset();
      } else if (!error) {
        this.editInfo = false;
        Swal.fire({
          title: "Información actualizada",
          text: "Hemos actualizado tus datos con éxito",
          icon: "success",
          confirmButtonText: "Aceptar",
          showConfirmButton: true,
          position: "center",
        });
        this.form.reset();
      } else {
        Swal.fire({
          title: "Error al actualizar",
          text: JSON.stringify(error),
          icon: "error",
          confirmButtonText: "Aceptar",
          showConfirmButton: true,
          position: "center",
        });
      }
      this.editarInfo();
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: "No hemos podido actualizar tus datos: " + JSON.stringify(error),
        icon: "error",
        confirmButtonText: "Aceptar",
        showConfirmButton: true,
        position: "center",
      });
    }
  }

  public updateInfo(ant_password, name, phone, code, error): Promise<string> {
    return new Promise((resolve) => {
      this.auth.afAuth
        .signInWithEmailAndPassword(this.authUser.email, ant_password)
        .then(() => {
          this.db
            .updateUser(this.user.id, { name, phone: code + phone })
            .then(() => {
              resolve("");
            })
            .catch((err) => {
              error = "No hemos podido actualizar tus datos";
              resolve(error);
            });
        })
        .catch((err) => {
          error = "Tu contraseña es incorrecta";
          resolve(error);
        });
    });
  }

  public changePassword(ant_password, password, error): Promise<string> {
    return new Promise((resolve) => {
      this.auth.afAuth
        .signInWithEmailAndPassword(this.authUser.email, ant_password)
        .then(() => {
          this.authUser
            .updatePassword(password)
            .then(() => {
              resolve("");
            })
            .catch((err) => {
              error = "No hemos podido actualizar tus datos";
              resolve(error);
            });
        })
        .catch((err) => {
          error = "Tu contraseña es incorrecta";
          resolve(error);
        });
    });
  }

  public changeEmail(email, ant_password, error): Promise<string> {
    return new Promise((resolve) => {
      this.auth.afAuth
        .signInWithEmailAndPassword(this.authUser.email, ant_password)
        .then(() => {
          this.authUser
            .updateEmail(email)
            .then(() => {
              resolve("");
            })
            .catch((err) => {
              error = "No hemos podido actualizar tus datos";
              resolve(error);
            });
        })
        .catch((err) => {
          error = "Tu contraseña es incorrecta";
          resolve(error);
        });
    });
  }

  editarInfo() {
    this.form.patchValue({
      name: this.user.name,
      phone: this.user.phone
        ? this.user.phone.slice(3, this.user.phone.length)
        : "",
      code: this.user.phone ? this.user.phone.slice(0, 3) : "",
    });
  }
}

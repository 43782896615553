<form class="search-form" action="none" method="dialog">
  <h3>Videos:</h3>
  <input
    name="search"
    placeholder="Buscar"
    (keyup.enter)="searchFor()"
    [(ngModel)]="searchWord"
  />
</form>

<div class="sel-container">
  <label for="genre">Buscar por genero músical:</label>

  <select
    [(ngModel)]="genre"
    (change)="changeGenre(genre)"
    name="genre"
    class="selector"
  >
    <option value="">Todos</option>
    <option *ngFor="let c of genresList" [value]="c.genre">
      {{ c.genre }}
    </option>
  </select>
</div>

<div class="table">
  <!-- <section class="paginator" *ngIf="numberOfPages.length > 0">
        <div class="paginator-left" (click)="paginatorControl.setValue(paginatorControl.value-1)" [ngClass]="{'paginator-disabled': pageSelected < 2}">
            <i class="fas fa-caret-left"></i>
        </div>
        <div *ngFor="let item of numberOfPages; index as i">
            <div class="paginator-number"  (click)="paginatorControl.setValue(i+1)" *ngIf="(i < pageSelected + 2 && i > pageSelected - 5) || (pageSelected < 4 && i < 6) || (pageSelected > numberOfPages.length - 3 && i > numberOfPages.length - 7)"  [ngClass]="{'paginator-active': pageSelected === i + 1}">
                {{i + 1}}
            </div>
        </div>
        <div class="paginator-right" (click)="paginatorControl.setValue(paginatorControl.value+1)" [ngClass]="{'paginator-disabled': pageSelected >= numberOfPages.length}">
            <i class="fas fa-caret-right"></i>
        </div>
        <div class="paginator-input">
            <select [formControl]="paginatorControl">
                <option *ngFor="let item of numberOfPages; index as i" [value]="i + 1">{{i+1}}</option>
            </select>
        </div>
    </section> -->
  <span *ngFor="let video of videos; let i = index" class="vid-cont">
    <div class="row videos">
      <!-- <div class="row videos" (mouseover)="playVideo(video.id)" (mouseleave)="pauseVideo(video.id)"> -->
      <!-- <video (click)='setSelectedVideo(video)' preload="metadata" class='web' appNoRightClick [id]="'a-'+video.id"
                loop [src]="endpoint + '/open/'+video.id" width="620">
            </video> -->
      <img
        class="web pointer"
        [src]="endpoint + '/screen/' + video.id"
        (click)="setSelectedVideo(video)"
      />
      <span (click)="setSelectedVideo(video)" class="play mobile"></span>
      <h4 class="title">
        {{ video.name }}
        <span class="genre tag">{{ video.genre }}, {{ video.date }}</span>
      </h4>
      <ng-container *ngIf="(!user?.membership || !user.id) && user.tokens < 1">
        <a
          *ngIf="
            !user?.membership || user?.membership.planPrice < 149 || !user.id
          "
          (click)="alert = true"
          class="download"
        ></a>
      </ng-container>
      <ng-container *ngIf="user.membership != undefined">
        <span
          *ngIf="user.membership.planPrice >= 199 && contar(video.id)"
          class="addPlaylist"
          [ngClass]="{ active: includesPlaylist(video.id) != -1 }"
          (click)="updatePlaylist(video)"
        ></span>

        <a
          *ngIf="
            hasPaid == 'active' &&
            contar(video.id) &&
            user.membership.planPrice >= 149
          "
          (click)="downloadFile(i)"
          class="download"
          [ngClass]="{
            gif: video.active,
            download: !video.active && includesId(video.id) == -1,
            check: !video.active && includesId(video.id) != -1
          }"
        ></a>
      </ng-container>
      <ng-container *ngIf="user.membership == undefined">
        <a
          *ngIf="hasPaid == 'active' && contar(video.id)"
          (click)="downloadFile(i)"
          class="download"
          [ngClass]="{
            gif: video.active,
            download: !video.active && includesId(video.id) == -1,
            check: !video.active && includesId(video.id) != -1
          }"
        ></a>
      </ng-container>
    </div>
  </span>
  <section class="paginator" *ngIf="numberOfPages.length > 0">
    <div
      class="paginator-left"
      (click)="paginatorControl.setValue(paginatorControl.value - 1)"
      [ngClass]="{ 'paginator-disabled': pageSelected < 2 }"
    >
      <i class="fas fa-caret-left"></i>
    </div>
    <div *ngFor="let item of numberOfPages; index as i">
      <div
        class="paginator-number"
        (click)="paginatorControl.setValue(i + 1)"
        *ngIf="
          (i < pageSelected + 2 && i > pageSelected - 5) ||
          (pageSelected < 4 && i < 6) ||
          (pageSelected > numberOfPages.length - 3 &&
            i > numberOfPages.length - 7)
        "
        [ngClass]="{ 'paginator-active': pageSelected === i + 1 }"
      >
        {{ i + 1 }}
      </div>
    </div>
    <div
      class="paginator-right"
      (click)="paginatorControl.setValue(paginatorControl.value + 1)"
      [ngClass]="{ 'paginator-disabled': pageSelected >= numberOfPages.length }"
    >
      <i class="fas fa-caret-right"></i>
    </div>
    <div class="paginator-input">
      <select [formControl]="paginatorControl">
        <option *ngFor="let item of numberOfPages; index as i" [value]="i + 1">
          {{ i + 1 }}
        </option>
      </select>
    </div>
  </section>
</div>

<div
  class="video-overlay modal-alerta"
  *ngIf="showVideo"
  oncontextmenu="return false;"
>
  <div class="modal-alerta-black" (click)="closeVideo()"></div>
  <div class="overlay" (click)="closeVideo()"></div>
  <div class="video-player">
    <div class="title">
      <p class="bold">{{ selectedVideo.name }}</p>
      <p class="dim">{{ selectedVideo.genre }}, {{ selectedVideo.date }}</p>
      <span class="close" (click)="closeVideo()"></span>
    </div>
    <video
      [src]="endpoint + '/open/' + selectedVideo.id"
      muted
      autoplay
      controls
      style="width: 100%"
      controlsList="nodownload"
      id="video"
    ></video>
    <!-- <mat-video [autoplay]="true" [overlay]="true" [fullscreen]="true" [download]="false" [quality]="false"
            color="primary" spinner="dot" [src]="endpoint + '/open/'+selectedVideo.id">
        </mat-video> -->
  </div>
</div>

<div class="modal-alerta" *ngIf="alert">
  <div class="modal-alerta-black" (click)="alert = false"></div>
  <div class="modal-alerta-text">
    <span class="closemodal" (click)="alert = false">x</span>
    <p *ngIf="user.id">Para poder descargar este archivo selecciona un plan</p>
    <p *ngIf="!user.id">Inicia sesión para poder descargar el archivo</p>
    <button *ngIf="user.id" (click)="router.navigate(['/plans'])">
      Seleccionar plan
    </button>
    <div class="buttonContainer">
      <button *ngIf="!user.id" (click)="router.navigate(['/login'])">
        Iniciar sesión
      </button>
    </div>
    <div class="buttonContainer">
      <button *ngIf="!user.id" (click)="router.navigate(['/signup'])">
        Crear una cuenta
      </button>
    </div>
  </div>
</div>
<ngx-spinner
  bdColor="rgba(10, 14, 31,0.8)"
  size="medium"
  color="#0066ff"
></ngx-spinner>

import { NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FirebaseInterceptor } from './interceptors/firebase.interceptor';
 @NgModule({
   imports: [
     CommonModule,
     HttpClientModule,
   ],
   exports: [
    CommonModule,
    HttpClientModule,
  ],
 providers: [
  { provide: HTTP_INTERCEPTORS, useClass: FirebaseInterceptor, multi: true },
  ]
 })
export class SharedModule { } 
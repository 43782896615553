import { Component, OnInit } from "@angular/core";
import { DbService } from "src/app/services/db.service";
import { AuthService } from "src/app/services/auth.service";
import { take } from "rxjs/operators";
import { NgxSpinnerService } from "ngx-spinner";
import Swal from "sweetalert2";
import * as firebase from "firebase";

@Component({
  selector: "app-premium-user",
  templateUrl: "./premium-user.component.html",
  styleUrls: ["./premium-user.component.scss"],
})
export class PremiumUserComponent implements OnInit {
  hasPaid = false;
  premiums = [];
  payments = [];
  user = {
    id: "",
    tokens: 0,
    vidIds: [],
    membership: {
      planPrice: 0,
    },
    musicIds: [],
    comboIds: [],
    karaokeIds: [],
    playlist: [],
  };

  public generateZip: boolean = false;

  constructor(
    private db: DbService,
    private auth: AuthService,
    private spinner: NgxSpinnerService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.db
      .getPremiums()
      .pipe(take(1))
      .subscribe((res) => {
        this.premiums = res;
      });

    this.auth.getUser().then((res) => {
      if (!res) return;
      this.db
        .getUser(res.uid)
        .pipe(take(1))
        .subscribe((user: any) => {
          this.user = user;
          this.user.id = res.uid;
          this.hasPaid = false;
          if (user.membership != undefined) {
            if (
              user.membership.planPrice == 199 ||
              user.membership.planPrice == 1899 ||
              user.membership.planPrice == 199.0 ||
              user.membership.planPrice == 1899.0 ||
              user.membership.planPrice == 16.0 ||
              user.membership.planPrice == 145.0 ||
              user.membership.planPrice == 16 ||
              user.membership.planPrice == 145 ||
              user.membership.planPrice == 60501 ||
              user.membership.planPrice == 548296 ||
              user.membership.planPrice == 60501.0 ||
              user.membership.planPrice == 548296.0
            ) {
              this.hasPaid = true;
              this.db
                .getUserpayments(this.user.id)
                .pipe(take(1))
                .subscribe((payments) => {
                  this.payments = payments;
                  this.filterProducts(payments);
                });
            }
          }
        });
    });
  }

  transformDate(date) {
    let d;
    if (date != undefined) d = new Date(date.seconds * 1000);
    if (date == undefined) d = new Date();
    return d;
  }

  filterProducts(payments) {
    let products = this.premiums;
    let date = new Date();
    let month = date.getMonth();
    let day = date.getDate();
    for (const product of products) {
      if (product.month == undefined) product.show = true;
      for (const payment of payments) {
        let dateSubStart = this.transformDate(payment.subscriptionStart);
        let dateSubEnd = this.transformDate(payment.subscriptionEnd);
        let monthStart = dateSubStart.getMonth();
        let yearStart = dateSubStart.getFullYear();
        let dayEnd = dateSubEnd.getDate();
        let monthEnd = dateSubEnd.getMonth();
        let yearEnd = dateSubEnd.getFullYear();
        let planMonth =
          payment.planPrice == 199 ||
          payment.planPrice == 199.0 ||
          payment.planPrice == 16.0 ||
          payment.planPrice == 16 ||
          payment.planPrice == 60501;
        let planYear =
          payment.planPrice == 1899 ||
          payment.planPrice == 1899.0 ||
          payment.planPrice == 145.0 ||
          payment.planPrice == 145 ||
          payment.planPrice == 548296;
        if (
          (monthStart == month || (monthEnd == month && day <= dayEnd)) &&
          (yearStart == product.year || yearEnd == product.year) &&
          planMonth
        ) {
          product.show = true;
        }
        if (
          (yearStart == product.year ||
            (monthEnd >= product.month && yearEnd == product.year)) &&
          planYear
        ) {
          product.show = true;
        }
      }
    }
  }

  public async download(premium) {
    if (!premium.idFolder) {
      window.open(premium.link);
      return;
    }
    try {
      this.generateZip = true;
      const getDownloadLink = firebase
        .functions()
        .httpsCallable("getDownloadFiles");
      let res: any = await getDownloadLink({
        folderID: premium.idFolder,
        folderName: premium.title,
      });
      console.log("res: ", res);
      let href = res.data;
      var link = document.createElement("a");
      link.href = href;
      link.download = href.substr(href.lastIndexOf("/") + 1);
      link.click();
      console.log("openLink");
      this.generateZip = false;
    } catch (error) {
      this.generateZip = false;
      Swal.fire({
        title: "Error",
        text: JSON.stringify(error),
        icon: "error",
        confirmButtonText: "Aceptar",
        position: "center",
      });
    }
  }

  public async downloadDemo(premium) {
    if (!premium.idFolderDemo) {
      window.open(premium.demo);
      return;
    }
    try {
      this.spinner.show();
      const getDownloadLink = firebase
        .functions()
        .httpsCallable("getDownloadFiles");
      let res: any = await getDownloadLink({
        folderID: premium.idFolderDemo,
        folderName: premium.title,
      });
      console.log("res: ", res);
      let href = res.data;
      var link = document.createElement("a");
      link.href = href;
      link.download = href.substr(href.lastIndexOf("/") + 1);
      link.click();
      console.log("openLink");
      this.spinner.hide();
    } catch (error) {
      this.spinner.hide();
      Swal.fire({
        title: "Error",
        text: JSON.stringify(error),
        icon: "error",
        confirmButtonText: "Aceptar",
        position: "center",
      });
    }
  }
}

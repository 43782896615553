<div class="container">
  <div class="video">
    <ng-container *ngIf="myHome.isVideo">
      <video
        class="web"
        appNoRightClick
        id="bunny"
        #homeVideo
        muted
        loop
        preload="metadata"
        [src]="myHome.banner"
      ></video>
      <img
        class="mobile"
        src="https://firebasestorage.googleapis.com/v0/b/djulises-89fef.appspot.com/o/home%2Fuhsplash.png?alt=media&token=b5241b1d-9dab-460b-acc9-24c58a109646"
      />
    </ng-container>
    <img *ngIf="!myHome.isVideo" [src]="myHome.banner" />
    <div class="title">
      <h3>{{ myHome.headline }}</h3>
      <p>{{ myHome.text }}</p>
      <a class="fulla" routerLink="/signup">{{ myHome.button }}</a>
    </div>
  </div>

  <div class="sections">
    <div class="section" *ngFor="let s of sections">
      <div class="text">
        <div class="stitle">
          <img [src]="s.icon" />
          <h3>{{ s.title }}</h3>
        </div>
        <p>{{ s.text }}</p>
      </div>
      <img class="web example" [src]="s.image" />
    </div>
  </div>

  <div class="images">
    <h2>
      En UHREMIXES tenemos siempre las mejores canciones, videos, karaokes y
      playlist para tu comodidad.
    </h2>
    <img
      src="https://firebasestorage.googleapis.com/v0/b/djulises-89fef.appspot.com/o/home%2Fvideos.png?alt=media&token=120782ff-c16b-412c-8f90-3ea61fff2092"
    />
  </div>

  <div class="genres">
    <div class="block text">
      <h3>Todos los generos que se te puedan ocurrir</h3>
      <p>
        En UHREMIXES tenemos generos desde las epocas, raggae, perreo, rock,
        reggaeton y mucho más
      </p>
      <a class="fulla" routerLink="/signup">Crea una cuenta</a>
    </div>
    <img
      class="block"
      src="https://firebasestorage.googleapis.com/v0/b/djulises-89fef.appspot.com/o/home%2Fcolors.png?alt=media&token=4386c4df-784e-4c93-b0dd-606057382ae9"
    />
  </div>

  <div class="genres">
    <img
      class="shadow wide web"
      src="https://firebasestorage.googleapis.com/v0/b/djulises-89fef.appspot.com/o/home%2Fscreen.png?alt=media&token=e322cc1d-9a04-4f4e-b408-2541859811cb"
    />
    <div class="min text web">
      <h3>Fácil de usar</h3>
      <p>
        Ve siempre lo más nuevo, ya sean videos, canciones, karaokes, listas.
        Crea tus playlists de descarga, y luego descargalas todas de un solo
        click
      </p>
      <a class="fulla" routerLink="/signup">Crea una cuenta</a>
    </div>

    <div class="block text mobile">
      <h3>Fácil de usar</h3>
      <p>
        Ve siempre lo más nuevo, ya sean videos, canciones, karaokes, listas.
        Crea tus playlists de descarga, y luego descargalas todas de un solo
        click
      </p>
      <a class="fulla" routerLink="/signup">Crea una cuenta</a>
    </div>
    <img
      class="shadow block mobile"
      src="https://firebasestorage.googleapis.com/v0/b/djulises-89fef.appspot.com/o/home%2Fscreen.png?alt=media&token=e322cc1d-9a04-4f4e-b408-2541859811cb"
    />
  </div>

  <div class="plans">
    <h2 class="center">Elige el mejor plan para ti...</h2>
    <br />
    <div class="switch-box">
      <p class="bold titulo">Ver precios en</p>
    </div>
    <div class="container-currency">
      <div class="type-currency">
        <p>Pesos Mexicanos</p>
        <i
          (click)="selected_type_currency = ''"
          *ngIf="selected_type_currency === 'mxn'"
          class="fa-solid fa-circle"
        ></i>
        <i
          (click)="selected_type_currency = 'mxn'"
          *ngIf="selected_type_currency !== 'mxn'"
          class="fa-regular fa-circle"
        ></i>
      </div>
      <div class="type-currency">
        <p>Dólares</p>
        <i
          (click)="selected_type_currency = ''"
          *ngIf="selected_type_currency === 'usd'"
          class="fa-solid fa-circle"
        ></i>
        <i
          (click)="selected_type_currency = 'usd'"
          *ngIf="selected_type_currency !== 'usd'"
          class="fa-regular fa-circle"
        ></i>
      </div>
      <div class="type-currency">
        <p>Pesos Colombianos</p>
        <i
          (click)="selected_type_currency = ''"
          *ngIf="selected_type_currency === 'cop'"
          class="fa-solid fa-circle"
        ></i>
        <i
          (click)="selected_type_currency = 'cop'"
          *ngIf="selected_type_currency !== 'cop'"
          class="fa-regular fa-circle"
        ></i>
      </div>
    </div>
    <br />
    <br />
    <table class="web">
      <thead>
        <tr>
          <td></td>
          <td *ngFor="let plan of plans">
            <div class="table_header">
              <p class="bold">{{ plan.name }}</p>
              <p class="value">
                <span>$ </span>
                {{
                  (selected_type_currency === "mxn"
                    ? plan.price
                    : selected_type_currency === "usd"
                    ? plan.priceUSD
                    : plan.priceCOP
                  ) | number
                }}
              </p>
              <p class="dim">
                <span class="text-uppercase">{{ selected_type_currency }}</span
                >/{{ plan.repeat }}
              </p>
            </div>
          </td>
        </tr>
        <tr *ngFor="let row of valuesNames; let i = index" class="row">
          <td>{{ row }}</td>
          <td *ngFor="let plan of plans" class="check">
            <span [ngClass]="{ true: plan.values[i] }"></span>
          </td>
        </tr>
        <tr>
          <td></td>
          <td *ngFor="let plan of plans">
            <a class="fulla" routerLink="/signup">Comprar</a>
          </td>
        </tr>
      </thead>
    </table>

    <div *ngFor="let plan of plans" class="plan mobile">
      <p class="bold">{{ plan.name }}</p>
      <p class="value">
        <span>$ </span>
        {{
          (selected_type_currency === "mxn"
            ? plan.price
            : selected_type_currency === "usd"
            ? plan.priceUSD
            : plan.priceCOP
          ) | number
        }}
      </p>
      <p class="dim">
        <span class="text-uppercase">{{ selected_type_currency }}</span
        >/{{ plan.repeat }}
      </p>
      <p class="txt" *ngFor="let val of plan.values; let i = index">
        <span class="txt" *ngIf="val">{{ valuesNames[i] }}</span>
      </p>
      <a class="fulla" routerLink="/signup">Comprar {{ plan.name }}</a>
    </div>
  </div>

  <div class="footer">
    <p>Ulises Hernandez Video Remixes © 2020</p>
    <div class="left">
      <a href="https://www.facebook.com/uhremix" target="_blank"
        ><img src="/assets/icons/facebook.svg"
      /></a>
    </div>
  </div>
</div>

<div
    style="position:relative;background-color:#0a0e1f; color:white; padding: 40px; font-family: 'Raleway', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue' , sans-serif;">
    <img src="https://www.uhremix.com/assets/logo.svg">
    <div
        style="position:relative;background-color:#0e1327; color:white; margin:40px; border-radius:10px; padding: 40px; font-family: 'Raleway', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue' , sans-serif;">
        <h2 style="border-bottom: 1px solid rgba(255,255,255,.1); padding-bottom: 20px;">Confirma tu correo</h2>
        <p>Bienvenido a UHREMIXES solo estas a un paso de poder disfrutar del mejor contenido para DJ's, haz click en el
            enlace de abajo para confirmar tu identidad</p>
        <a *ngIf="!navegadorPitero"
            style=" cursor: pointer; font-weight: bold; background-color:#0066ff; margin-top: 40px; width: 100%; border-radius: 5px;  padding: 20px 0; color: white; text-decoration: none; text-align: center; display: block;"
            (click)="send()">Verificar correo</a>


        <div  *ngIf="navegadorPitero">
            <p>Ingresa tu correo</p>
            <input style="width: 100%;" [(ngModel)]="email" name="email">
            <a 
            style=" cursor: pointer; font-weight: bold; background-color:#0066ff; margin-top: 40px; width: 100%; border-radius: 5px;  padding: 20px 0; color: white; text-decoration: none; text-align: center; display: block;"
            (click)="try()">Verificar correo</a>
        </div>
        <p *ngIf="error!=''" style="color: red;">*{{error}}</p>

    </div>
    <p style="text-align: center; opacity: .5; margin:0;">© 2020 UHRemixes</p>
    <p style="text-align: center; opacity: .5; margin:0;">Todos los derechos reservados</p>
</div>

<app-modal [visible]="visible" [text]="text" [title]="title"></app-modal>
<form class="search-form" action="none" method="dialog">
  <h3>Cupones:</h3>
  <input
    name="search"
    placeholder="Buscar"
    (input)="searchFor()"
    [(ngModel)]="searchWord"
    name="searchWord"
  />
</form>

<form class="flex">
  <input placeholder="Nombre" [(ngModel)]="couponName" name="name" />
  <input
    class="small"
    placeholder="MXN OFF"
    [(ngModel)]="mxamount"
    (input)="discamount = ''"
    name="amount"
  />
  <input
    class="small"
    placeholder="% OFF "
    [(ngModel)]="discamount"
    (input)="mxamount = ''"
    name="amount2"
  />
  <button
    class="button blue full"
    [ngClass]="{ disbaled: discamount === '' && mxamount === '' }"
    (click)="create()"
  >
    Crear cupón
  </button>
</form>

<div class="table">
  <div class="content">
    <div *ngFor="let item of coupons">
      <div class="d-flex justify-content-between">
        <div class="d-flex">
          <p>{{ item.name }}</p>
          <p *ngIf="item.percent_off != null" class="discount">
            {{ item.percent_off }}% de descuento
          </p>
          <p *ngIf="item.amount_off != null" class="discount">
            {{ item.amount_off }}mxn de descuento
          </p>
        </div>
        <div class="d-flex">
          <i
            class="far fa-eye active-color"
            *ngIf="!item.hide"
            (click)="desactivar(item)"
          ></i>
          <i
            class="fas fa-eye-slash inactive-color"
            *ngIf="item.hide"
            (click)="activar(item)"
          ></i>
          <i
            class="far fa-trash-alt denger-color"
            (click)="delete(item.uid)"
          ></i>
        </div>
      </div>
    </div>
  </div>
</div>
<ngx-spinner
  bdColor="rgba(10, 14, 31,0.8)"
  size="medium"
  color="#0066ff"
></ngx-spinner>

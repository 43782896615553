import { Component, OnInit } from "@angular/core";
import { DbService } from "src/app/services/db.service";
import { ActivatedRoute, Router } from "@angular/router";
import { FormControl } from "@angular/forms";
import * as firebase from "firebase";

@Component({
  selector: "app-premium-detail",
  templateUrl: "./premium-detail.component.html",
  styleUrls: ["./premium-detail.component.scss"],
})
export class PremiumDetailComponent implements OnInit {
  constructor(
    private db: DbService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  public date = new FormControl("");
  public plan = new FormControl("");
  public plan99 = new FormControl("");
  public plan149 = new FormControl("");
  public plan199 = new FormControl("");
  public plan1899 = new FormControl("");

  public message = new FormControl(
    "Hemos agregado un nuevo paquete %nombrepaquete%,  https://uhremix.com/#/premium"
  );

  public messagepreview =
    "Hemos agregado un nuevo paquete %nombrepaquete%,  https://uhremix.com/#/premium";

  id = "";
  premium = {
    image: "",
    info: "",
    link: "",
    demo: "",
    title: "",
    year: 2021,
    month: 0,
    createdAt: new Date(),
    hide: "",
    idFolder: "",
  };

  months = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  years = [];
  ngOnInit(): void {
    let date = new Date();
    const year = date.getFullYear();
    this.premium.year = year;

    this.years.push(year);
    this.years.push(year + 1);
    this.years.push(year + 2);
    this.years.push(year + 3);

    this.route.params.subscribe((params) => {
      this.id = params["id"];
      console.log(this.id);
      if (this.id != "add") {
        this.db.getPremium(this.id).subscribe((res: any) => {
          console.log(res);
          this.premium = res;
          this.premium.hide = this.premium.hide ? "si" : "";
        });
      }
    });
    this.message.valueChanges.subscribe((value) => {
      let message = value;
      message = message.replace("%nombrepaquete%", this.premium.title);
      this.messagepreview = message;
    });
    this.plan.valueChanges.subscribe((value) => {
      if (value === "inactivos") {
        this.plan99.setValue("");
        this.plan149.setValue("");
        this.plan199.setValue("");
        this.plan1899.setValue("");
      }
    });
  }

  messagePreviewChange() {
    let message = this.message.value;
    message = message.replace("%nombrepaquete%", this.premium.title);
    /*   message = message.replace("%link%", this.premium.link); */
    this.messagepreview = message;
  }

  emitedImage(imageUrl) {
    console.log(imageUrl);
    this.premium.image = imageUrl;
  }

  async saveChanges() {
    this.premium.year = Number(this.premium.year);
    this.premium.month = Number(this.premium.month);
    if (this.id == "add") {
      this.db.createPremium(this.premium).then((res) => {
        console.log(res);
        this.router.navigateByUrl("/admin/premium");
      });
    } else {
      this.db.updatePremium(this.id, this.premium).then((res) => {
        console.log(res);
        this.router.navigateByUrl("/admin/premium");
      });
    }
  }

  public async sendMessage(): Promise<void> {
    const plans = [];
    if (this.plan99.value) {
      plans.push("99");
      plans.push("8");
      plans.push("30250");
    }
    if (this.plan149.value) {
      plans.push("149");
      plans.push("12");
      plans.push("45376");
    }
    if (this.plan199.value) {
      plans.push("199");
      plans.push("16");
      plans.push("60501");
    }
    if (this.plan1899.value) {
      plans.push("1899");
      plans.push("145");
      plans.push("548296");
    }
    const request = {
      filters: {
        date: this.date.value,
        plan: this.plan.value,
        plans,
      },
      data: this.messagepreview,
    };
    console.log(request);
    let sendMessageFunction = firebase.functions().httpsCallable("sendMessage");
    try {
      let response = await sendMessageFunction(request);
      console.log(response);
    } catch (error) {
      console.log("error: ", error);
    }
  }
}

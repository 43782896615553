<br>
<br>
<h3 class="bold center">Aviso de Privacidad “UH Remix” </h3>
<div class="content_med">
    <p>En cumplimiento a lo establecido por la Ley Federal de Protección de Datos Personales en Posesión de Particulares
        y su reglamento (en lo sucesivo la “Ley”) y que coadyuvan con nuestro objetivo de proteger sus datos personales,
        es que:
    </p>

    <br>
    <p class="bold">UHRemix</p>

    <p>Domicilio</p>
    <p> Aquiles 169 fracc delta la piedad Michoacan Mexico</p>

    <p>Es responsable de recabar sus datos personales, del tratamiento que se le dé a los mismos y de su protección y
        hace de su conocimiento, que los datos personales y/o patrimoniales de personas físicas que le son solicitados,
        serán utilizados exclusivamente para la realización de actividades concernientes a la posible relación
        comercial, o bien sobre la relación comercial ya existente que sostiene con usted, como:</p>
    <ul>
        <li> Participación en el proceso de selección de clientes o proveedores que realizamos. </li>
        <li> Mantener nuestra lista de los contactos de clientes y proveedores autorizados. </li>
        <li> Emisión y consulta de referencias comerciales. </li>
        <li> Llevar un registro y cotización de los de bienes y/o servicios cuyo suministro otorgamos o requerimos.
        </li>
        <li> Cumplimiento de nuestras políticas o procedimientos internos respecto a clientes y proveedores, así como
            informarle de cambios al respecto. </li>
        <li> Atención de dudas y sugerencias. </li>
        <li> Cobro o pago de contraprestaciones y facturación. </li>
        <li> Elaboración de contratos y/u órdenes de compra. </li>
        <li> Actualización de bases de datos. </li>
        <li> Mantener una comunicación que nos permita evaluar la calidad de los bienes y/o servicios que otorgamos o
            requerimos. </li>
        <li> Invitaciones a eventos sociales que organicemos.
    </ul>
    <p>En forma accesoria los datos personales podrán ser utilizados con fines mercadotécnicos, publicidad, prospección comercial, así como para la elaboración de perfil de clientes para el desarrollo y ofrecimiento de nuevos productos, realización de encuestas, creación o implementación de procesos analíticos y estadísticos necesarios o convenientes relacionados con los bienes y/o servicios cuyo suministro otorgamos o requerimos.</p>

    <p>Para las finalidades antes mencionadas y dependiendo del tipo de contrato, bien, servicio o monto de los mismos, es que pudiéramos requerir entre otros los siguientes datos de identificación personales o de la empresa que representa (incluyendo los derivados de sus características físicas): nombre, domicilio, teléfonos, correos electrónicos, estudios académicos, historial crediticio, afiliaciones, edad, estado civil, apoderados legales, poderes, acta constitutiva, comprobante de domicilio del negocio (recibo de luz, teléfono, agua o cable), comprobante de domicilio particular del apoderado, CURP, copia de la identificación oficial del apoderado y las firmas autorizadas, y cédula del RFC en copia simple, o en su caso, declaraciones anuales de ISR  debidamente presentadas, contrato de arrendamiento en el caso de no ser propio el local.</p>

    <p>Se realiza el tratamiento de los datos solicitados anteriormente (datos personales y/o patrimoniales, de clientes, proveedores y contacto de éstos) de conformidad con los principios de licitud, consentimiento, información, calidad, finalidad, lealtad, proporcionalidad y responsabilidad en términos de lo dispuesto en la Ley.</p>

    <p>Le informamos que, con la finalidad de impedir el acceso y revelación no autorizada, mantener la exactitud de los datos y garantizar la utilización correcta de la información, aplicamos los procedimientos físicos, tecnológicos y administrativos apropiados para proteger la información que recabamos. La información personal y patrimonial que nos proporciona, de conformidad con la Legislación, se guarda en bases de datos controladas y con acceso limitado</p>
    <p>Se le informa que podrá ejercer sus derechos de Acceso, Rectificación, Cancelación y Oposición (ARCO) de sus datos y que nos proporcionó, siempre y cuando su relación comercial con nosotros está saldada, a través del formulario que podrá obtenerse mediante solicitud a la siguiente dirección electrónica: https://www.uhremix.com  así como en el domicilio y teléfono señalado al inicio del presente aviso.</p>
    <p>Asimismo, le informamos que sus datos personales pueden ser transferidos y tratados dentro de nuestra empresa y de terceras personas ya sean físicas o morales que de alguna manera existe una relación de cliente, proveeduría o de cualquier otra cosa.</p>
    <p>En este sentido, su información puede ser, para que estas empresas adquieran o le vendan bienes y/o servicios cuyo suministro otorgamos o requerimos.</p>
    <p>Cualquier modificación a este aviso de privacidad podrá consultarla en https://www.uhremix.com Nos reservamos el derecho de efectuar en cualquier momento modificaciones o actualizaciones al presente aviso de privacidad para la prestación u ofrecimiento de nuestros bienes y/o servicios, para la atención de modificaciones legislativas, regulatorias o jurisprudenciales, políticas internas, prácticas del mercado o por cualquier otra razón. Cualquier cambio que se realice a este aviso de privacidad, será incorporado al mismo y será dado a conocer en alguno de los siguientes medios: (i) anuncios visibles en nuestros establecimientos o centros de atención a clientes; o (ii) trípticos o folletos disponibles en nuestros establecimientos o centros de atención a clientes; o (iii) nuestra página de internet https://www.uhremix.com</p>
    <p class="right">Fecha de última actualización 28/07/2020</p>
</div>




import { Component, OnInit } from "@angular/core";
import { DbService } from "src/app/services/db.service";
import { NgxSpinnerService } from "ngx-spinner";
import { FormControl, FormGroup } from "@angular/forms";
import { ExportServices } from "src/app/services/export.services";

@Component({
  selector: "app-adminusers",
  templateUrl: "./adminusers.component.html",
  styleUrls: ["./adminusers.component.scss"],
})
export class AdminusersComponent implements OnInit {
  users = [];
  filterUsers = [];
  memberships = "all";
  backupUsers = [];
  searchWord = "";
  fechabusqueda: any = "";
  fechabusquedafinal: any = "";

  public form = new FormGroup({
    fechainicial: new FormControl(""),
    fechafinal: new FormControl(""),
    email: new FormControl(""),
    filter: new FormControl("todas"),
  });

  constructor(
    private db: DbService,
    private spinner: NgxSpinnerService,
    private _export: ExportServices
  ) {}

  async ngOnInit() {
    this.form.controls.email.valueChanges.subscribe((value) => {
      if (value) {
        this.form.patchValue({
          filter: "todas",
          fechainicial: "",
          fechafinal: "",
        });
        this.fechabusquedafinal = "";
        this.fechabusqueda = "";
      }
    });
    this.spinner.show();
    let dateUser = new Date();
    dateUser.setHours(0, 0, 0, 0);
    dateUser.setDate(dateUser.getDate() - 30);
    let today = new Date();
    let day = today.getDate();
    let month: any = today.getMonth() + 1;
    month = month < 10 ? "0" + month : month;
    let year = today.getFullYear();
    this.fechabusquedafinal = new Date(`${month}/${day}/${year} 00:00`);
    this.fechabusqueda = dateUser;
    const ref = this.db.afs
      .collection("users")
      .ref.where("creationTime", ">", dateUser)
      .orderBy("creationTime", "desc");
    const colletion = await ref.get();
    const documents = colletion.docs;
    const data = documents.map((d) => ({
      ...d.data(),
      id: d.id,
      cancelTime: d.data().cancelTime
        ? this.transformDate(d.data().cancelTime)
        : "",
      cDate: this.transformDate(d.data().creationTime),
      show: true /* d.data().cancelTime ? false : true */,
      startDate: d.data().membership
        ? this.transformDate(d.data().membership.subscriptionStart)
        : "",
      endDate: d.data().membership
        ? this.transformDate(d.data().membership.subscriptionEnd)
        : "",
    }));
    this.users = data;
    this.filterUsers = data;
    this.spinner.hide();
  }

  public async changeMemb(): Promise<void> {
    this.spinner.show();
    const value = this.form.value;
    if (value.email) {
      const ref = this.db.afs
        .collection("users")
        .ref.where("email", "==", value.email);
      const colletion = await ref.get();
      const documents = colletion.docs;
      const data = documents.map((d) => ({
        ...d.data(),
        id: d.id,
        cancelTime: d.data().cancelTime
          ? this.transformDate(d.data().cancelTime)
          : "",
        cDate: this.transformDate(d.data().creationTime),
        show: true,
        startDate: d.data().membership
          ? this.transformDate(d.data().membership.subscriptionStart)
          : "",
        endDate: d.data().membership
          ? this.transformDate(d.data().membership.subscriptionEnd)
          : "",
      }));
      this.users = data;
      this.filterUsers = this.users;
    } else {
      if (!value.fechainicial || !value.fechafinal) {
        alert("La fecha inicial y final son obligatorios");
        this.spinner.hide();
        return;
      }
      console.log("entre aqui");
      let desde: any = new Date(value.fechainicial);
      let hasta: any = new Date(value.fechafinal);
      desde.setDate(desde.getDate() + 1);
      hasta.setDate(hasta.getDate() + 1);
      desde.setHours(0, 0, 0, 0);
      hasta.setHours(23, 59, 59, 59);
      this.fechabusqueda = desde;
      this.fechabusquedafinal = hasta;
      const ref = this.db.afs
        .collection("users")
        .ref.where("creationTime", ">", desde)
        .where("creationTime", "<", hasta)
        .orderBy("creationTime", "desc");
      const colletion = await ref.get();
      const documents = colletion.docs;
      const data = documents.map((d) => ({
        ...d.data(),
        id: d.id,
        cancelTime: d.data().cancelTime
          ? this.transformDate(d.data().cancelTime)
          : "",
        cDate: this.transformDate(d.data().creationTime),
        show: true,
        startDate: d.data().membership
          ? this.transformDate(d.data().membership.subscriptionStart)
          : "",
        endDate: d.data().membership
          ? this.transformDate(d.data().membership.subscriptionEnd)
          : "",
      }));
      console.log(data);
      this.users = data;
      this.filterUsers = this.users;
      switch (value.filter) {
        case "activas":
          this.filterUsers = this.filterUsers.filter(
            (fu) => fu.membership?.status === "active"
          );
          break;
        case "inactivas":
          this.filterUsers = this.filterUsers.filter(
            (fu) => fu.membership?.status !== "active"
          );
          break;
        case "canceladas":
          this.filterUsers = this.filterUsers.filter((fu) => fu.cancelTime);
          break;
      }
    }
    this.spinner.hide();
    if (this.filterUsers.length < 1) {
      alert("No hay usuarios con los filtros seleccionados");
    }
  }

  transformDate(date) {
    let d;
    if (date != undefined) d = new Date(date.seconds * 1000);
    if (date == undefined) d = new Date();
    let options = { day: "numeric", month: "long", year: "numeric" };
    return d.toLocaleDateString("es-ES", options);
  }

  giveDays(user) {
    let today = new Date();
    let nextMonth = new Date();
    nextMonth.setMonth(today.getMonth() + 1);

    console.log(today, nextMonth);
    let p = prompt(
      "Estas seguro de dar 30 días más de membresia (FULL) a: " +
        user.name +
        " escribe 'aceptar'",
      ""
    );
    if (p == "aceptar") {
      if (user.membership == undefined) user.membership = {};
      user.membership.planPrice = 199;
      user.membership.status = "active";
      user.membership.type = "uh";
      user.membership.subscriptionEnd = nextMonth;
      user.membership.subscriptionStart = today;
      console.log(user.membership);
      this.db.updateUser(user.id, { membership: user.membership });
      this.db.createPayment({
        planPrice: 199,
        type: "uh",
        subscriptionEnd: nextMonth,
        subscriptionStart: today,
        userId: user.id,
      });
    }
  } ///
  givePoints(user) {
    console.log(user.tokens);
    let p = prompt(
      "Estas seguro de dar 100 descargas gratis a: " +
        user.name +
        " escribe 'aceptar'",
      ""
    );
    if (p == "aceptar") {
      if (user.tokens == undefined) {
        user.tokens = 100;
      } else {
        user.tokens += 100;
      }
      this.db.updateUser(user.id, { tokens: user.tokens });
    }
  } ///

  searchFor() {
    console.log(this.searchWord);
    if (this.searchWord == "") {
      this.users = this.backupUsers;
      this.memberships = "all";
    } else {
      this.users = this.users.filter((current) => {
        if (current.email && this.searchWord) {
          if (
            current.email.toLowerCase().indexOf(this.searchWord.toLowerCase()) >
            -1
          ) {
            return true;
          }
          return false;
        }
      });
    }
  }

  public cleanForm() {
    this.form.patchValue({
      filter: "todas",
      fechainicial: "",
      fechafinal: "",
      email: "",
    });
    this.fechabusquedafinal = "";
    this.fechabusqueda = "";
  }

  public exportAll(): void {
    let data = [];
    this.filterUsers.forEach((fc) => {
      data.push({
        Fecha: fc.creationTime,
        Correo: fc.email,
        Nombre: fc.name,
        Telefono: fc.phone,
        "Plan Tipo":  fc.membership?.type ? fc.membership.type : '',
        "Plan Estado": fc.membership?.status ? fc.membership.status : '',
        "Plan Precio": fc.membership?.planPrice ? fc.membership.planPrice : '',
        "Plan Inicio": fc.startDate,
        "Plan Final": fc.endDate,
        "Plan Fecha Cancelado": fc.cancelTime,
      });
    });
    this._export.export(data, "usuarios");
  }
}

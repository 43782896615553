import { Component, OnInit } from "@angular/core";
import { Router, NavigationStart } from "@angular/router";
import { AuthService } from "src/app/services/auth.service";
import { DbService } from "src/app/services/db.service";
import { MysqlService } from "./services/mysql.service";
import * as firebase from "firebase";
import { IpService } from "./services/ip.service";
import { HttpClient } from "@angular/common/http";

declare var window: any;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  public modalPromo: boolean = false;

  title = "UHRemixes";
  showFB;
  user = {
    id: "",
    ip: "",
    creationTime: undefined,
  };
  showNavbar = true;
  showSpacer = true;
  alert = {
    text: "",
    color: "",
    link: "/",
  };
  myHome = {
    banner: "",
    button: "",
    headline: "",
    text: "",
    tokens: "",
    wa: "",
    fb: "",
    isVideo: false,
    hideFb: false,
    hideWa: false,
  };

  constructor(
    router: Router,
    public db: DbService,
    private auth: AuthService,
    private sql: MysqlService,
    private ip: IpService,
    private mysql: MysqlService,
    private _http: HttpClient
  ) {
    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        console.log(db.previousPage);
        if (!db.previousPage) {
          db.previousPage = event.url;
        }
        console.log(db.previousPage);
        this.getUser();
        if (
          event.url == "/login" ||
          event.url == "/signup" ||
          event.url == "/confirm-email"
        ) {
          this.showSpacer = false;
          this.showNavbar = false;
        } else if (event.url == "/signup") {
          this.showNavbar = false;
          localStorage.setItem("reload", "true");
        } else {
          this.showSpacer = true;
          this.showNavbar = true;
        }
      }
    });
  }

  async ngOnInit() {
    window.localStorage.removeItem("modalLogin");
    this.openModalPromo(10);
    this.openModalLogin(35);
    if (this.checkFb()) return;
    this.getLinks();
    this.getUser();
    this.validateUser();
  }

  public couponsLoading: boolean = false;

  public async openModalPromo(time): Promise<void> {
    if (!this.couponsLoading) {
      this.couponsLoading = true;
      const couponsCollection = await this.db.afs
        .collection("coupons")
        .ref.get();
      if (couponsCollection.empty) {
        return;
      }
      const docs = couponsCollection.docs;
      const coupons = docs.map((d) => ({
        ...d.data(),
      }));
      let showModal = false;
      coupons.forEach((d) => {
        if (!d.hide) {
          showModal = true;
        }
      });
      if (!showModal) {
        return;
      }
    }
    const userAuth = await this.auth.getUser();
    let openModal = false;
    if (userAuth) {
      let doc = await this.db.afs
        .collection("users")
        .doc(userAuth.uid)
        .ref.get();
      let data = doc.data();
      if (data.tokens < 1 && !data.membership && !data.isAdmin) {
        openModal = true;
      }
    }
    if (openModal) {
      setTimeout(() => {
        this.db.modalPromo = true;
        this.openModalPromo(600);
      }, time * 1000);
    } else {
      setTimeout(() => {
        this.openModalPromo(600);
      }, time * 1000);
    }
  }

  public async openModalLogin(time): Promise<void> {
    const userAuth = await this.auth.getUser();
    let openModal = false;
    if (!userAuth) {
      openModal = true;
    }
    if (openModal) {
      setTimeout(() => {
        this.db.modalLogin = true;
        this.openModalLogin(600);
      }, time * 1000);
    } else {
      this.openModalLogin(600);
    }
  }

  public async validateUser(): Promise<void> {
    const userAuth = await this.auth.getUser();
    if (userAuth) {
      let doc = await this.db.afs
        .collection("users")
        .doc(userAuth.uid)
        .ref.get();
      let document = doc.data();
      document.id = doc.id;
      let result: any = await this.ip.getIPAddressPromise();
      const ipAddress = result.ip;
      const userAgent = navigator.userAgent;
      if (
        document.ipAddress !== ipAddress ||
        document.userAgent !== userAgent
      ) {
        let data = {
          ipAddress,
          userAgent,
        };
        await this.db.afs.collection("users").doc(userAuth.uid).update(data);
      }
    }
  }

  getLinks() {
    this.db.getHome().subscribe((res) => {
      this.myHome = res[0];
    });
  }

  checkFb() {
    //@ts-ignore
    var ua = navigator.userAgent || navigator.vendor || window.opera;
    if (ua.indexOf("FBAN") > -1 || ua.indexOf("FBAV") > -1) {
      this.showFB = true;
      return true;
    }
    return false;
  }

  getUser() {
    this.auth.getUser().then((res: any) => {
      if (res) {
        this.db.getUser(res.uid).subscribe((user: any) => {
          let planPrice = user?.membership?.planPrice;
          let newPlanPrice;
          if (planPrice) {
            if (
              planPrice == "8" ||
              planPrice == "8.00" ||
              planPrice == "99.00" ||
              planPrice == "30250" ||
              planPrice == "30250.00"
            ) {
              newPlanPrice = "99";
            } else if (
              planPrice == "12" ||
              planPrice == "12.00" ||
              planPrice == "149.00" ||
              planPrice == "45376" ||
              planPrice == "45376.00"
            ) {
              newPlanPrice = "149";
            } else if (
              planPrice == "16" ||
              planPrice == "16.00" ||
              planPrice == "199.00" ||
              planPrice == "60501" ||
              planPrice == "60501.00"
            ) {
              newPlanPrice = "199";
            } else if (
              planPrice == "145" ||
              planPrice == "145.00" ||
              planPrice == "1899.00" ||
              planPrice == "548296" ||
              planPrice == "548296.00"
            ) {
              newPlanPrice = "1899";
            }
            if (newPlanPrice) {
              let membership = {
                ...user.membership,
                planPrice: newPlanPrice,
              };
              this.db.afs
                .collection("users")
                .doc(res.uid)
                .update({ membership });
            }
          }
          if (user == undefined) return;
          this.user = user;
          this.checkIP(res.uid);
          this.createTime(res);
          if (user.membership == undefined) this.hasNoMembership(user, res.uid);
          this.checkMembership(user, res.uid);
          if (
            user.membership != undefined &&
            this.checkSubscriptionEnd(user.membership)
          ) {
            user.id = res.uid;
            this.cancelMembership(user);
          }
        });
      }
    });
  }

  checkSubscriptionEnd(membership) {
    const end = new Date(membership.subscriptionEnd?.seconds * 1000);
    const start = new Date();
    return end < start;
  }

  checkIP(userId) {
    if (this.user.ip == undefined) {
      this.ip.getIPAddress().subscribe((myip: any) => {
        this.user.ip = myip.ip;
        this.db.updateUser(userId, this.user);
      });
    } else {
      this.ip.getIPAddress().subscribe((myip: any) => {
        this.db.getUserIP(myip.ip).subscribe((users) => {
          if (users.length > 1) this.db.updateUser(userId, { tokens: 0 });
        });
      });
    }
  }

  createTime(res) {
    if (this.user.creationTime == undefined) {
      this.db.updateUser(res.uid, {
        creationTime: new Date(res.metadata.creationTime),
      });
    }
  }

  hasNoMembership(user, userId) {
    if (!user.isAdmin) {
      this.alert = {
        text: `Te quedan ${user.tokens} descargas disponibles`,
        color: "blue",
        link: "/",
      };
    }
    if (user.tokens == 0) {
      if (user.has0Downloads == undefined) {
        let nodownloads = firebase.functions().httpsCallable("limitDownloads");
        nodownloads({ ...user, id: userId });
        let u = { has0Downloads: true };
        this.db.updateUser(userId, u);
      }
      if (!user.isAdmin) {
        this.alert = {
          text: `Para seguir descargando adquiere un plan`,
          color: "blue",
          link: "/plans",
        };
      }
    }
  }

  checkMembership(user, userId) {
    if (user.membership == undefined) return;
    if (user.membership.status == "undefined") {
      this.alert = {
        text: `Conoce nuestros planes`,
        color: "blue",
        link: "/plans",
      };
    } else if (user.membership.status == "CANCELLED") {
      user.id = userId;
      this.cancelMembership(user);
    } else if (user.membership.status != "active") {
      this.alert = {
        text: `Revisa tu método de pago`,
        color: "red",
        link: "/myaccount",
      };
    }
  }

  cancelMembership(user) {
    user.membership.status = "ended";
    delete user.membership;
    user.cancelTime = new Date();
    this.db.removeMembership(user.id, user);
    let cancel = firebase.functions().httpsCallable("cancelSubscription");
    cancel({ ...user }).finally(() => window.location.reload());
  }

  public closeModalPromo() {
    this.db.modalPromo = false;
  }

  public closeModalLogin() {
    this.db.modalLogin = false;
  }

  public callEvent() {
    window.MC_PIXEL.fireLogConversionEvent("buy_button_clicked");
    console.log("test");
    alert("test");
  }
}

import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-modal",
  templateUrl: "./modal.component.html",
  styleUrls: ["./modal.component.scss"],
})
export class ModalComponent implements OnInit {
  @Input() visible = false;
  @Input() title = "";
  @Input() text = "";
  @Input() link = "";

  constructor() {}
  ngOnInit(): void {}

  dismiss() {
    this.visible = false;
  }
}

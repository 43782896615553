import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-wave",
  templateUrl: "./wave.component.html",
  styleUrls: ["./wave.component.scss"],
})
export class WaveComponent implements OnInit {
  constructor() {}
  @Input() showWaves = false;

  loader = "";
  ngOnInit(): void {}
  toggleWaves(){
    this.showWaves = !this.showWaves
  }
}

import { Component, OnInit } from "@angular/core";
declare let bowser;

@Component({
  selector: "app-facebook",
  templateUrl: "./facebook.component.html",
  styleUrls: ["./facebook.component.scss"],
})
export class FacebookComponent implements OnInit {
  constructor() {}

  msg;
  ngOnInit(): void {
    //@ts-ignore

  }
}

<div class="container" [ngClass]="{'visible':visible}">
    <div *ngIf="link!='dismiss'" [routerLink]="link" class="overlay"></div>
    <div *ngIf="link!='dismiss'" class="alert">
        <h2>{{title}}</h2>
        <p>{{text}}</p>
        <button [routerLink]="link" class="full">Aceptar</button>
    </div>
    <div *ngIf="link=='dismiss'" (click)="dismiss()" class="overlay"></div>
    <div *ngIf="link=='dismiss'" class="alert">
        <h2>{{title}}</h2>
        <p>{{text}}</p>
        <button (click)="dismiss()" class="full">Aceptar</button>
    </div>
</div>
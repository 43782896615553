<form class="search-form" action="none" method="dialog">
    <h3>Combos (video):</h3>
    <input name="search" placeholder="Buscar" (keyup.enter)="searchFor()" [(ngModel)]="searchWord">
</form>

<div class="sel-container">
    <label for="genre">Buscar por genero músical:</label>

    <select [(ngModel)]='genre' (change)="changeGenre(genre)" name="genre" class="selector">
        <option value="">Todos</option>
        <option *ngFor="let c of genresList" [value]="c.genre">{{c.genre}}</option>
    </select>

</div>

<div class="table">
    <!-- <section class="paginator" *ngIf="numberOfPages.length > 0">
        <div class="paginator-left" (click)="paginatorControl.setValue(paginatorControl.value-1)" [ngClass]="{'paginator-disabled': pageSelected < 2}">
            <i class="fas fa-caret-left"></i>
        </div>
        <div *ngFor="let item of numberOfPages; index as i">
            <div class="paginator-number"  (click)="paginatorControl.setValue(i+1)" *ngIf="(i < pageSelected + 2 && i > pageSelected - 5) || (pageSelected < 4 && i < 6) || (pageSelected > numberOfPages.length - 3 && i > numberOfPages.length - 7)"  [ngClass]="{'paginator-active': pageSelected === i + 1}">
                {{i + 1}}
            </div>
        </div>
        <div class="paginator-right" (click)="paginatorControl.setValue(paginatorControl.value+1)" [ngClass]="{'paginator-disabled': pageSelected >= numberOfPages.length}">
            <i class="fas fa-caret-right"></i>
        </div>
        <div class="paginator-input">
            <select [formControl]="paginatorControl">
                <option *ngFor="let item of numberOfPages; index as i" [value]="i + 1">{{i+1}}</option>
            </select>
        </div>
    </section> -->
    <div *ngFor="let song of songs ; let i = index">
        <div class="row">
            <span [ngClass]="{'active':selected == song.id }" (click)="getInfo(song.id)" class="list"></span>
            <h4 class="title">{{song.name}} <span class="genre tag">{{song.genre}}, {{song.date}}</span>
                <div class="info" *ngIf="selected == song.id">
                    <div class="overlay" appMousePosition
                    *ngIf="hasPaid=='active' && user.comboIds != undefined && contar(song.id) && user.membership.planPrice >= 149"
                    (click)="downloadFile(i)">
                    <span [ngClass]="{'gif':song.active}" class="down-button" [style.top.px]="top"
                        [style.left.px]="left">Descargar Combo
                        {{song.name}}
                    </span>
                </div>
                    <p *ngFor="let item of info  ; let j = index">- {{item}}</p>
                </div>
            </h4>

            <ng-container *ngIf="!user?.membership || !user.id">
                <a *ngIf="!user?.membership || user?.membership.planPrice >= 149 || !user.id" (click)="alert = true" class="download"></a>
            </ng-container>

            <ng-container *ngIf="user.membership!= undefined">
                <a *ngIf="hasPaid=='active' && user.comboIds != undefined && contar(song.id) && user.membership.planPrice >= 149"
                    (click)="downloadFile(i)" class="download"
                    [ngClass]="{'gif':song.active ,'download':!song.active && !user.comboIds.includes(song.id), 'check':!song.active && user.comboIds.includes(song.id) }"></a>
            </ng-container>

            <ng-container *ngIf="user.membership== undefined">
                <a *ngIf="hasPaid=='active' && user.comboIds != undefined && contar(song.id) " (click)="downloadFile(i)"
                    class="download"
                    [ngClass]="{'gif':song.active ,'download':!song.active && !user.comboIds.includes(song.id), 'check':!song.active && user.comboIds.includes(song.id) }"></a>
            </ng-container>
        </div>
    </div>
    <section class="paginator" *ngIf="numberOfPages.length > 0">
        <div class="paginator-left" (click)="paginatorControl.setValue(paginatorControl.value-1)" [ngClass]="{'paginator-disabled': pageSelected < 2}">
            <i class="fas fa-caret-left"></i>
        </div>
        <div *ngFor="let item of numberOfPages; index as i">
            <div class="paginator-number"  (click)="paginatorControl.setValue(i+1)" *ngIf="(i < pageSelected + 2 && i > pageSelected - 5) || (pageSelected < 4 && i < 6) || (pageSelected > numberOfPages.length - 3 && i > numberOfPages.length - 7)"  [ngClass]="{'paginator-active': pageSelected === i + 1}">
                {{i + 1}}
            </div>
        </div>
        <div class="paginator-right" (click)="paginatorControl.setValue(paginatorControl.value+1)" [ngClass]="{'paginator-disabled': pageSelected >= numberOfPages.length}">
            <i class="fas fa-caret-right"></i>
        </div>
        <div class="paginator-input">
            <select [formControl]="paginatorControl">
                <option *ngFor="let item of numberOfPages; index as i" [value]="i + 1">{{i+1}}</option>
            </select>
        </div>
    </section>
</div>

<div class="modal-alerta" *ngIf="alert">
    <div class="modal-alerta-black" (click)='alert = false'></div>
    <div class="modal-alerta-text">
        <span class="closemodal"  (click)='alert = false'>x</span>
        <p *ngIf="user.id">Para poder descargar este archivo selecciona un plan</p>
        <p *ngIf="!user.id">Inicia sesión para poder descargar el archivo</p>
        <button *ngIf="user.id" (click)="router.navigate(['/plans']);">Seleccionar plan</button>
        <div class="buttonContainer"><button *ngIf="!user.id" (click)="router.navigate(['/login']);">Iniciar sesión</button></div>
        <div class="buttonContainer"><button *ngIf="!user.id" (click)="router.navigate(['/signup']);">Crear una cuenta</button></div>
    </div>
</div>

<ngx-spinner bdColor="rgba(10, 14, 31,0.8)" size="medium" color="#0066ff"></ngx-spinner>
<app-wave [showWaves]="loading"></app-wave>
import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { AuthService } from "../../services/auth.service";
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from "@angular/router";
import * as firebase from "firebase/app";
import { ActiveCampService } from "src/app/services/active-camp.service";
import { DbService } from "src/app/services/db.service";

import { numbers } from "./phoneNumbersj";
import {
  eventFacebook,
  EventFacebookService,
} from "src/app/services/event-facebook.service";
import { FormControl, FormGroup, Validators } from "@angular/forms";

declare var window: any;
declare var MC: any;

@Component({
  selector: "app-signup",
  templateUrl: "./signup.component.html",
  styleUrls: ["./signup.component.scss"],
})
export class SignupComponent implements OnInit {
  // @ViewChild("paypal", { static: true }) paypalElement: ElementRef;
  // stripe = Stripe("pk_live_51H2kRwD2DaxyRogKitmJ84qwTawXAgSKLHp03DWPpvvTTuiaO2jSOy9C15gi7ABRA1aIoQBRQUQNzBMY4g04eApZ00ydEXq9R4");

  @Input() completeProfile;
  @Output() complete = new EventEmitter();

  isActive = false;
  name;
  mail;
  password;
  phone;
  phone_code = "+521";

  public MessengerFacebook = MC;

  cardName;
  cardNumber;
  cardDate;
  cardCVV;

  visible = false;
  title = "";
  text = "";
  link = "dismiss";

  card: any;
  numbers = numbers;

  public form: FormGroup = new FormGroup({
    password: new FormControl("", [
      Validators.required,
      Validators.minLength(6),
    ]),
    name: new FormControl("", [
      Validators.required,
      Validators.pattern("[a-zA-Z ]*"),
    ]),
    mail: new FormControl("", [Validators.required, Validators.email]),
    phone: new FormControl("", [
      Validators.required,
      Validators.pattern("[0-9]*"),
      Validators.minLength(10),
      Validators.maxLength(10),
    ]),
    phone_code: new FormControl("", [Validators.required]),
  });

  constructor(
    private auth: AuthService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private ac: ActiveCampService,
    private db: DbService,
    private _eventFacebook: EventFacebookService
  ) {}

  dismiss() {
    this.visible = false;
  }

  ngOnInit(): void {
    if (this.db.previousPage !== "/signup") {
      window.location.reload();
    } else {
      this.db.previousPage = "";
    }
  }

  toggleActive() {
    this.isActive = !this.isActive;
  }

  async signUp() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.visible = true;
      this.title = "Error";
      this.text = "Por favor completa el formulario";
      return;
    }
    let p = this.phone_code + this.phone;
    this.visible = false;
    this.spinner.show();
    if (this.phone == undefined) {
      this.spinner.hide();
      this.visible = true;
      this.title = "Error";
      this.text = "Por favor ingresa un número valido";
    } else {
      if (this.completeProfile) {
        this.auth.getUser().then((data) => {
          if (data) {
            return this.db.afs
              .collection("usersTemp")
              .doc(data.uid)
              .ref.get()
              .then((doc) => {
                let userTemp = doc.data();
                if (userTemp) {
                  let request = {
                    uid: data.uid,
                    user: {
                      name: this.name,
                      email: userTemp.email,
                      stripe_id: userTemp.stripe_id,
                      phone: p,
                      tokens: this.auth.tokens,
                      isVerified: true,
                      membership: userTemp.membership,
                    },
                    payment: userTemp.payment,
                  };
                  this.createUserManyChat(request.user);
                  let createUser = firebase
                    .functions()
                    .httpsCallable("createUser");
                  createUser(request)
                    .then(() => {
                      const event: eventFacebook = {
                        event_name: "CompleteRegistration",
                        event_source_url: "https://uhremix.com/#/signup",
                        value: null,
                        currency: null,
                        ipAddress: "127.0.0.1",
                        userAgent: "Chrome 1.16 mobile",
                        test: false,
                        type: "signup",
                        num_items: 1,
                        content_category: "Registro",
                        content_ids: null,
                        content_name: null,
                        content_type: null,
                        contents: null,
                        status: true,
                        em: userTemp.email,
                        ph: p,
                        fn: this.name,
                        subscription_id: "",
                      };
                      window.MC_PIXEL.fireLogConversionEvent(
                        "CompleteRegistration"
                      );
                      this._eventFacebook.sendEvent(event);
                      this.spinner.hide();
                      /* this.auth.afAuth.signOut().then(() => { */
                      window.location.assign("/#/music");
                      /* window.location.reload(); */
                      /* }); */
                    })
                    .catch(() => {
                      this.spinner.hide();
                      /* this.auth.afAuth.signOut(); */
                      window.location.assign("/#/music");
                      /* window.location.reload(); */
                    });
                } else {
                  this.spinner.hide();
                  this.visible = true;
                  this.title = "Error";
                  this.text =
                    "Error al obtener el usuario vinculado con tu correo electronico";
                }
              });
          }
          this.spinner.hide();
          this.visible = true;
          this.title = "Error";
          this.text =
            "Error al obtener el usuario vinculado con tu correo electronico";
        });
      } else {
        this.auth.signupUser(this.mail, this.password, this.name, p).then(
          (res) => {
            const event: eventFacebook = {
              event_name: "CompleteRegistration",
              event_source_url: "https://uhremix.com/#/signup",
              value: null,
              currency: null,
              content_type: null,
              ipAddress: "127.0.0.1",
              userAgent: "Chrome 1.16 mobile",
              test: false,
              type: "signup",
              num_items: 1,
              content_category: "Registro",
              content_ids: null,
              content_name: null,
              contents: null,
              status: true,
              em: this.mail,
              ph: p,
              fn: this.name,
              subscription_id: "",
            };
            let user = {
              name: this.name,
              email: this.mail,
              phone: p,
            };
            this.createUserManyChat(user);
            window.MC_PIXEL.fireLogConversionEvent("CompleteRegistration");
            this._eventFacebook.sendEvent(event);
            this.spinner.hide();
            window.location.assign("/#/choose-plan");
            // this.visible = true;
            // this.title = "Usuario Creado Exitosamente";
            // this.text = "Te ha llegado un correo de confirmación";
            //@ts-ignore
          },
          (error) => {
            this.spinner.hide();
            this.visible = true;
            this.title = "Error";
            this.text = error.message;
          }
        );
      }
    }
  }

  telInputObject(obj) {}

  getNumber(obj) {
    this.phone = obj;
  }

  async signFacebook() {
    this.spinner.show();
    this.auth.signupUserFacebook().then(
      (res) => {
        const event: eventFacebook = {
          event_name: "CompleteRegistration",
          event_source_url: "https://uhremix.com/#/signup",
          value: null,
          currency: null,
          content_type: null,
          ipAddress: "127.0.0.1",
          userAgent: "Chrome 1.16 mobile",
          test: false,
          type: "signup",
          num_items: 1,
          content_category: "Paypal",
          content_ids: null,
          content_name: null,
          contents: null,
          status: true,
          em: "",
          ph: "",
          fn: "",
          subscription_id: "",
        };
        window.MC_PIXEL.fireLogConversionEvent("CompleteRegistration");
        this._eventFacebook.sendEvent(event);
        this.isActive = true;
        this.spinner.hide();
        //@ts-ignore
        window.location.assign("/#/choose-plan");
      },
      (error) => {
        this.spinner.hide();
        this.visible = true;
        this.title = "Error";
        this.text = error.message;
      }
    );
  }

  async signGoogle() {
    this.spinner.show();
    this.auth.signupUserGoogle().then(
      (res) => {
        const event: eventFacebook = {
          event_name: "CompleteRegistration",
          event_source_url: "https://uhremix.com/#/signup",
          value: null,
          currency: null,
          content_type: null,
          ipAddress: "127.0.0.1",
          userAgent: "Chrome 1.16 mobile",
          test: false,
          type: "signup",
          num_items: 1,
          content_category: "Paypal",
          content_ids: null,
          content_name: null,
          contents: null,
          status: true,
          em: "",
          ph: "",
          fn: "",
          subscription_id: "",
        };
        window.MC_PIXEL.fireLogConversionEvent("CompleteRegistration");
        this._eventFacebook.sendEvent(event);
        this.isActive = true;
        this.spinner.hide();
        //@ts-ignore
        window.location.assign("/#/choose-plan");
      },
      (error) => {
        this.spinner.hide();
        this.visible = true;
        this.title = "Error";
        this.text = error.message;
      }
    );
  }

  public async createUserManyChat(user) {
    try {
      let createUserManyChat = firebase
        .functions()
        .httpsCallable("createContactManyChat");
      let data = {
        first_name: user.name,
        last_name: "",
        phone: user.phone,
        whatsapp_phone: "user.phone",
        email: user.email,
        gender: "",
        has_opt_in_sms: true,
        has_opt_in_email: true,
        consent_phrase: "NA",
      };
      console.log(data);
      let res = await createUserManyChat(data);
      console.log(res);
      this._eventFacebook.sendEventCampaint(
        "createContactManyChat",
        user.email,
        ``
      );
    } catch (error) {
      console.log("error: ", error);
    }
  }
}

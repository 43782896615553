import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AppComponent } from "./app.component";
import { LoginComponent } from "./pages/login/login.component";
import { HomeComponent } from "./pages/home/home.component";
import { SignupComponent } from "./pages/signup/signup.component";
import { MusicComponent } from "./pages/music/music.component";
import { VideosComponent } from "./pages/videos/videos.component";
import { AuthGuardGuard } from "./guards/auth-guard.guard";
import { AdminGuard } from "./guards/admin.guard";
import { AdminmusicComponent } from "./pages/adminmusic/adminmusic.component";
import { AdminvideosComponent } from "./pages/adminvideos/adminvideos.component";
import { AdminwvideosComponent } from "./pages/adminwvideos/adminwvideos.component";
import { AdminwmusicComponent } from "./pages/adminwmusic/adminwmusic.component";
import { AdmingenresComponent } from "./pages/admingenres/admingenres.component";
import { AdminusersComponent } from "./pages/adminusers/adminusers.component";
import { AccountComponent } from "./pages/account/account.component";
import { PlansComponent } from "./pages/plans/plans.component";
import { ContactComponent } from "./pages/contact/contact.component";
import { TerminosComponent } from "./terminos/terminos.component";
import { AvisoComponent } from "./aviso/aviso.component";
import { ReembolsoComponent } from "./reembolso/reembolso.component";
import { CargosComponent } from "./pages/cargos/cargos.component";
import { HomeGuardGuard } from "./guards/home-guard.guard";
import { AdminkaraokeComponent } from "./pages/adminkaraoke/adminkaraoke.component";
import { AdmincombosComponent } from "./pages/admincombos/admincombos.component";
import { CombosComponent } from "./pages/combos/combos.component";
import { KaraokeComponent } from "./pages/karaoke/karaoke.component";
import { PlaylistComponent } from "./pages/playlist/playlist.component";
import { WaveComponent } from "./component/wave/wave.component";
import { AdminhomeComponent } from "./pages/adminhome/adminhome.component";
import { ChoosePlanComponent } from "./pages/choose-plan/choose-plan.component";
import { PayComponent } from "./pages/pay/pay.component";
import { CouponsComponent } from "./pages/coupons/coupons.component";
import { MailComponent } from "./pages/mail/mail.component";
import { PremiumComponent } from "./pages/premium/premium.component";
import { PremiumDetailComponent } from "./pages/premium-detail/premium-detail.component";
import { PremiumUserComponent } from "./pages/premium-user/premium-user.component";
import { CombosMusicaComponent } from "./pages/combos-musica/combos-musica.component";
import { SuccessPageComponent } from "./success-page/success-page.component";
import { FacebookComponent } from "./pages/facebook/facebook.component";
import { ConfirmEmailComponent } from "./pages/confirm-email/confirm-email.component";
import { CompleteProfileComponent } from "./pages/complete-profile/complete-profile.component";
import { AdminuserdetailComponent } from "./pages/adminuserdetail/adminuserdetail.component";
import { AdminnewuserComponent } from "./pages/adminnewuser/adminnewuser.component";
import { LoginV2Component } from "./v2/login/login.component";
import { SignupV2Component } from "./v2/signup/signup.component";

const routes: Routes = [
  { path: "", component: HomeComponent, canActivate: [HomeGuardGuard] },
  // { path: "waves", component: WaveComponent},
  { path: "login", component: LoginComponent },
  { path: "completeProfile", component: CompleteProfileComponent },
  { path: "signup", component: SignupComponent },
  // { path: "confirm-email", component: ConfirmEmailComponent },
  /* { path: "contact", component: ContactComponent }, */
  { path: "plans", component: PlansComponent },
  { path: "terminos-y-condiciones", component: TerminosComponent },
  { path: "aviso-de-privacidad", component: AvisoComponent },
  {
    path: "success",
    component: SuccessPageComponent,
    canActivate: [AuthGuardGuard],
  },
  { path: "rembolso", component: ReembolsoComponent },

  {
    path: "choose-plan",
    component: ChoosePlanComponent,
    canActivate: [AuthGuardGuard],
  },
  {
    path: "payment/:id",
    component: PayComponent,
    canActivate: [AuthGuardGuard],
  },

  {
    path: "myaccount",
    component: AccountComponent,
    canActivate: [AuthGuardGuard],
  },
  {
    path: "myaccount/:modal",
    component: AccountComponent,
    canActivate: [AuthGuardGuard],
  },

  { path: "music", component: MusicComponent },
  { path: "music/genre/:genre", component: MusicComponent },
  { path: "music/genre/:genre/:searchword", component: MusicComponent },

  { path: "videos", component: VideosComponent },
  { path: "videos/genre/:genre", component: VideosComponent },

  { path: "karaoke", component: KaraokeComponent },
  { path: "karaoke/genre/:genre", component: KaraokeComponent },

  { path: "combosvid", component: CombosComponent },
  { path: "combosmus", component: CombosMusicaComponent },
  { path: "combos/genre/:genre", component: CombosComponent },

  { path: "premium", component: HomeComponent, canActivate: [HomeGuardGuard] },

  {
    path: "playlist",
    component: PlaylistComponent,
    canActivate: [AuthGuardGuard],
  },

  {
    path: "admin/music",
    component: AdminwmusicComponent,
    canActivate: [AdminGuard],
  },
  {
    path: "admin/videos",
    component: AdminwvideosComponent,
    canActivate: [AdminGuard],
  },
  {
    path: "admin/videos/genre/:genre",
    component: AdminwvideosComponent,
    canActivate: [AdminGuard],
  },

  {
    path: "admin/karaoke",
    component: AdminkaraokeComponent,
    canActivate: [AdminGuard],
  },
  {
    path: "admin/karaoke/genre/:genre",
    component: AdminkaraokeComponent,
    canActivate: [AdminGuard],
  },

  {
    path: "admin/combos",
    component: AdmincombosComponent,
    canActivate: [AdminGuard],
  },
  {
    path: "admin/combos/genre/:genre",
    component: AdmincombosComponent,
    canActivate: [AdminGuard],
  },

  {
    path: "admin/upload",
    component: AdminmusicComponent,
    canActivate: [AdminGuard],
  },
  {
    path: "admin/genres",
    component: AdmingenresComponent,
    canActivate: [AdminGuard],
  },
  {
    path: "admin/users",
    component: AdminusersComponent,
    canActivate: [AdminGuard],
  },
  {
    path: "admin/users/:id/:name",
    component: AdminuserdetailComponent,
    canActivate: [AdminGuard],
  },
  {
    path: "admin/newuser",
    component: AdminnewuserComponent,
    canActivate: [AdminGuard],
  },
  {
    path: "admin/charges",
    component: CargosComponent,
    canActivate: [AdminGuard],
  },
  {
    path: "admin/charges/:email",
    component: CargosComponent,
    canActivate: [AdminGuard],
  },
  {
    path: "admin/coupons",
    component: CouponsComponent,
    canActivate: [AdminGuard],
  },

  {
    path: "admin/home",
    component: AdminhomeComponent,
    canActivate: [AdminGuard],
  },
  { path: "admin/email", component: MailComponent, canActivate: [AdminGuard] },

  {
    path: "admin/premium",
    component: PremiumComponent,
    canActivate: [AdminGuard],
  },
  {
    path: "admin/premium/:id",
    component: PremiumDetailComponent,
    canActivate: [AdminGuard],
  },
  {
    path: "admin/marketing",
    loadChildren: () =>
      import("./pages/adminsms/adminsms.module").then((m) => m.AdminsmsModule),
  },
  {
    path: "links/:url",
    loadChildren: () =>
      import("./pages/links/links.module").then((m) => m.LinksModule),
  },

  // { path: "facebook", component: FacebookComponent},
  { path: "v2/login", component: LoginV2Component },
  { path: "v2/signup", component: SignupV2Component },
  { path: "**", component: HomeComponent, canActivate: [HomeGuardGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}

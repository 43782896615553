<main>
  <article>
    <div class="headertitle">
      <div>
        <h2>Usuario nuevo</h2>
      </div>
    </div>
  </article>
  <article class="dobleColumna">
    <div class="columna">
      <h2 class="seccion">General</h2>
      <div>
        <form [formGroup]="generalForm" class="generalform">
          <input placeholder="Nombre" type="text" formControlName="name"><br />
          <input placeholder="Correo" type="email" formControlName="email"> <br />
          <input placeholder="Contraseña" type="password" formControlName="password"><br />
          <div class="phone">
            <input list="phone-coodes" name="browser" id="browser" formControlName="code" placeholder="codigo">
            <datalist id="phone-coodes">
              <option *ngFor="let n of numbers" [value]="n.code">{{n.display}}</option>
            </datalist>
            <input placeholder="Celular" type="phone" maxlength="10" minlength="10" formControlName="phone">
          </div>
        </form>
      </div>
    </div>
    <div class="columna">
      <h2 class="seccion">Agregar plan </h2>
      <div>
        <form [formGroup]="planForm">
          <div>
            <input type="date" placeholder="Inicio de suscripcion" formControlName="subscriptionStart" />
          </div>
          <div>
            <input type="date" placeholder="Fin de suscripcion" formControlName="subscriptionEnd" />
          </div>
          <select formControlName="planPrice">
            <option value="">Plan</option>
            <option value="99">99</option>
            <option value="149">149</option>
            <option value="199">199</option>
            <option value="1899">1899</option>
          </select><br />
          <select formControlName="type">
            <option value="">Tipo</option>
            <option value="stripe">stripe</option>
            <option value="paypal">paypal</option>
            <option value="oxxo">oxxo</option>
            <option value="uh">uh</option>
          </select><br />
        </form>
      </div>
    </div>
  </article>
  <article class="control">
    <button (click)="newUser()">Agregar usuario</button>
  </article>
</main>
import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { AuthService } from "../services/auth.service";
import { DbService } from "../services/db.service";
import { PaypalServices } from "../services/paypal.service";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-success-page",
  templateUrl: "./success-page.component.html",
  styleUrls: ["./success-page.component.scss"],
})
export class SuccessPageComponent implements OnInit {
  constructor(
    private _paypal: PaypalServices,
    private _db: DbService,
    private _auth: AuthService,
    private _spinner: NgxSpinnerService,
    private route: ActivatedRoute
  ) { }

  timeleft = 5;

  public loading = true;

  async ngOnInit(): Promise<any> {
    this._spinner.show();
    let userAuth = await this._auth.getUser();
    if (!userAuth) {
      this.downloadTimer();
      this._spinner.hide();
    }
    let hasUpdatedMembership = false
    this._db.getUser(userAuth.uid).subscribe(async (value: any) => {
      if(!hasUpdatedMembership){
        this.route.queryParams.subscribe(async (params) => {
          if (params.plan) {
            hasUpdatedMembership = true
            let startDate = new Date()
            let endDate = new Date()
            let planPrice = '0'
            switch (params.plan) {
              case 'PLANAUDIO':
                planPrice = '99'
                break
              case 'PLANAUDIOVIDEO':
                planPrice = '149'
                break
              case 'PLANFULL':
                planPrice = '199'
                break
              case 'PLANFULLANUAL':
                planPrice = '1899'
                break
              default:
                planPrice = '0'
                break
            }
            if (planPrice != '0') {
              if (planPrice != "1899") endDate.setDate(endDate.getDate() + 30)
              if (planPrice == "1899") endDate.setDate(endDate.getDate() + 365)
              value.membership = {
                planPrice: planPrice,
                status: "active",
                subscriptionStart: startDate,
                subscriptionEnd: endDate,
                type: "mercado pago"
              }
              if(params.preapproval_id)value.mercadoPagoId = params.preapproval_id
              await this._db.updateUser(value.id, value)
              await this._db.createPayment({ ...value.membership, userId: value.id })
            }
          }
        });
      }
      if (!value) {
        this.downloadTimer();
        this._spinner.hide();
      }
      if (value.membership.status === "active") {
        this.downloadTimer();
        this._spinner.hide();
        return;
      } else {
        setTimeout(() => {
          window.location.reload();
        }, 10000);
      }
    });
  }

  downloadTimer() {
    this.loading = false;
    setTimeout(() => {
      if (this.timeleft == 0) {
        window.location.assign("/#/music");
      } else {
        this.timeleft -= 1;
        this.downloadTimer();
      }
    }, 1000);
  }
}

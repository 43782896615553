import { Component, OnInit, HostListener } from "@angular/core";
import { AuthService } from "src/app/services/auth.service";
import { DbService } from "src/app/services/db.service";
import * as firebase from "firebase/app";
import { first } from "rxjs/operators";
import { ActiveCampService } from "src/app/services/active-camp.service";
import { NavigationStart, Router } from "@angular/router";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit {
  constructor(private db: DbService, private auth: AuthService, router: Router) {
    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        this.show = false;
      }
    });
  }
  show = false;
  scrollPos = 0;
  isScrollingDown = false;
  userIsSignedIn = false;
  user = {
    isAdmin: false,
    membership: {
      planPrice: 0,
    },
  };

  @HostListener("window:scroll", ["$event"])
  doSomething(event) {
    if (this.scrollPos == 0 || this.scrollPos < window.pageYOffset) {
      this.isScrollingDown = true;
    } else {
      this.isScrollingDown = false;
    }
    this.scrollPos = window.pageYOffset;
  }

  ngOnInit() {
    this.auth.getUser().then((res) => {
if(!res) return
      if (res != undefined) {
        this.db.getUser(res.uid).subscribe((user: any) => {
          this.userIsSignedIn = true;
          this.user = user;
          if(user == undefined) return
          if (this.user.isAdmin == undefined) this.user.isAdmin = false;
          if (this.user.membership != undefined) this.user.membership.planPrice = 0;
        });
      }
    });
  }

  logout() {
    this.show = false;
    this.auth.logout();
    this.userIsSignedIn = false;
  }
}

<main *ngIf="user">
  <article>
    <div class="headertitle">
      <div>
        <h2>General</h2>
      </div>
      <div class="accionesgenerales">
        <button *ngIf="!user.disabled" (click)="enabledUser(true)">
          Desactivar cuenta
        </button>
        <button *ngIf="user.disabled" (click)="enabledUser(false)">
          Activar cuenta
        </button>
        <button (click)="deleteUser()">Eliminar cuenta</button>
      </div>
    </div>
    <p>
      <strong>Nombre:</strong> <br /><span>{{ user.name }}</span>
    </p>
    <p>
      <strong>Correo electronico:</strong><br />
      <span>{{ user.email }}</span>
    </p>
    <p>
      <strong>Contraseña:</strong><br />
      <input type="text" [formControl]="password" placeholder="*********" />
      <br /><span class="link" (click)="updateUser()">Cambiar contraseña</span>
    </p>
  </article>
  <article class="dobleColumna">
    <div class="columna">
      <h2 class="seccion">Plan actual</h2>
      <div *ngIf="!user?.membership" class="sinPlan">
        No tiene un plan activo
      </div>
      <div *ngIf="user?.membership">
        <p>
          <strong>Inicio de subscripcion: </strong>{{ user?.membership.subscriptionStart | date }}
        </p>
        <p>
          <strong>Fin de subscripcion:</strong>
          {{ user?.membership.subscriptionEnd | date }}
        </p>
        <p><strong>Plan:</strong> {{ user?.membership.planPrice }}</p>
        <p *ngIf="subscription?.price?.currency">
          <strong>Moneda:</strong>
          <span class="moneda">{{ subscription.price.currency }}</span>
        </p>
        <p *ngIf="amount"><strong>Precio:</strong> {{ amount | currency }}</p>
        <p><strong>Tipo:</strong> {{ user?.membership.type }}</p>
        <p>
          <strong>Status:</strong>
          {{
          user?.membership.cancelled
          ? "Renovacion cancelada"
          : user.membership.status
          }}
        </p>
        <button style="margin-top: 20px" (click)="cancelPlan()">
          Cancelar plan
        </button>
      </div>
    </div>
    <div class="columna">
      <h2 class="seccion">
        Agregar nuevo plan
        <span *ngIf="
            user?.membership?.status === 'active' &&
            user.membership?.subscriptionStart <
              user.membership?.subscriptionEnd &&
            !user.membership?.cancelled
          " class="planactive">Actualmente tiene un plan activo</span>
      </h2>
      <div [ngClass]="{
          planinactive:
            user?.membership?.status === 'active' &&
            user.membership?.subscriptionStart <
              user.membership?.subscriptionEnd &&
            !user.membership?.cancelled
        }">
        <form [formGroup]="planForm">
          <div>
            <input type="date" placeholder="Inicio de suscripcion" formControlName="subscriptionStart" />
          </div>
          <div>
            <input type="date" placeholder="Fin de suscripcion" formControlName="subscriptionEnd" />
          </div>
          <select formControlName="planPrice">
            <option value="">Plan</option>
            <option value="99">99</option>
            <option value="149">149</option>
            <option value="199">199</option>
            <option value="1899">1899</option>
          </select>
          <br />
          <select formControlName="type">
            <option value="">Tipo</option>
            <option value="stripe">stripe</option>
            <option value="paypal">paypal</option>
            <option value="oxxo">oxxo</option>
            <option value="uh">uh</option>
          </select><br />
        </form>
        <button (click)="updatePlan()">Actualizar plan</button>
      </div>
    </div>
    <div class="columna">
      <h2 class="seccion">
        Actualizar plan (Solo stripe)
        <span *ngIf="
            user?.membership?.status === 'active' &&
            user.membership?.subscriptionStart <
              user.membership?.subscriptionEnd &&
            !user.membership?.cancelled &&
            user.membership?.type !== 'stripe' &&
            user.membership?.stripe_id
          " class="planactive">Actualmente no tiene un plan activo de stripe</span>
      </h2>
      <div [ngClass]="{
          planinactive:
            user?.membership?.status === 'active' &&
            user.membership?.subscriptionStart <
              user.membership?.subscriptionEnd &&
            !user.membership?.cancelled &&
            user.membership?.type === 'stripe' &&
            user.membership?.stripe_id
        }">
        <form [formGroup]="planStripeForm">
          <select formControlName="planPrice">
            <option value="">Plan</option>
            <option value="price_1HbNhvD2DaxyRogKr0zkk59n">99</option>
            <option value="price_1HbNhgD2DaxyRogKbvFIaKHr">149</option>
            <option value="price_1HbNhCD2DaxyRogKAGT3Uq94">199</option>
            <option value="price_1IDQi4D2DaxyRogKcZ5uF5AO">1899</option>
            <option value="price_1JIp66D2DaxyRogKUYBuIs1W">8</option>
            <option value="price_1JIp72D2DaxyRogKbxPAHp7n">12</option>
            <option value="price_1JIp7aD2DaxyRogKfjcybxhv">16</option>
            <option value="price_1JIp4VD2DaxyRogKPWHulWDE">145</option>
            <option value="price_1L7rJID2DaxyRogK7IfsCzvm">30250</option>
            <option value="price_1L7rIwD2DaxyRogKp0mQLyFJ">45376</option>
            <option value="price_1L7rIWD2DaxyRogK2h5FkXDD">60501</option>
            <option value="price_1L7rHlD2DaxyRogK2GC9oc97">548296</option>
          </select>
        </form>
        <button (click)="upgradePlan()"
          [disabled]="user.membership?.type !== 'stripe' || !planStripeForm.controls.planPrice.valid">
          Actualizar plan
        </button>
      </div>
    </div>
  </article>
  <div class="tableee">
    <article>
      <h2 class="seccion">Pagos</h2>
    </article>
    <div class="content">
      <div class="row" *ngFor="let item of charges">
        <span class="status" [ngClass]="{
            stripe: item.type == 'stripe',
            oxxo: item.type == 'oxxo',
            paypal: item.type == 'paypal',
            uh: item.type == 'uh'
          }"  *ngIf="item.type != 'mercado pago'"></span>
          <span *ngIf="item.type == 'mercado pago'" style="background-color: white; width: 50px; height: 29px; margin-inline: 10px; padding: 5px; border-radius: 5px;">
            <img src="/assets/icons/mercadopago.png" alt="" style="width: 50px;"> 
          </span>
        <p class="phone bold">${{ item.planPrice }}.00 <span>mxn</span></p>
        <p class="name">Inicio: {{ item.subscriptionStart }}</p>
        <p class="name">Fin: {{ item.subscriptionEnd }}</p>
      </div>
    </div>
  </div>
  <div class="buttonmorepayments">
    <button [routerLink]="'/admin/charges/' + user.email">Ver mas pagos</button>
  </div>
</main>
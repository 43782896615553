<div class="container">

    <p>Inserta <strong>{{key1}}</strong>: para que en el correo aparezca el precio pagado</p>
    <p>Inserta <strong>{{key2}}</strong>: para que en el correo aparezca el plan </p>
    <textarea id="text" [(ngModel)]="text" (input)="change()" rows="5" name="text"  placeholder="Ingresa el texto"></textarea>
    <button (click)="saveChanges()" class="full blue">Guardar</button>


    <div style="height: 100%; width: 100%;  padding-top: 50px;">
        <div style=" background-color:#0a0e1f ; padding: 100px 0px;"> 
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 55 46"
                style="enable-background:new 0 0 55 46" xml:space="preserve">
                <style type="text/css">
                    .st0 {
                        opacity: 0.7;
                        fill: white;
                        enable-background: new;
                    }

                    .st1 {
                        fill: white;
                    }

                    .st2 {
                        opacity: 0.8;
                        fill: white;
                        enable-background: new;
                    }
                </style>
                <g>
                    <g>
                        <path class="st0" d="M15.3,8L15.3,8l-0.2-0.2L15.3,8L15.3,8z" />
                        <path class="st1" d="M0.6,1L0.5,1.1l27,15.8l0.1-0.2L0.6,1z" />
                        <path class="st1" d="M23.1,2L23,2.1l31.4,18.1l0.1-0.2L23.1,2z" />
                        <path class="st0"
                            d="M20.8,13.9l-0.3,15.2c0,0.2,0,0.4,0,0.5v0.1c0,0.2,0,0.3-0.1,0.5v0.1c0,0.1-0.1,0.3-0.1,0.4v0.1 c-0.1,0.1-0.1,0.2-0.1,0.3v0.1c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.1,0.2-0.2,0.2c0,0,0,0-0.1,0.1s-0.2,0.1-0.2,0.2 c0,0,0,0-0.1,0h-0.1c-0.1,0-0.1,0.1-0.2,0.1c0,0,0,0-0.1,0l0,0l0,0h-0.1l0,0h-0.1h-0.1c0,0,0,0-0.1,0l0,0l0,0c-0.1,0-0.1,0-0.2,0 s-0.1,0-0.2,0h-0.1h-0.7c0.1,0,0.2,0,0.3,0h0.1h0.1c0.1,0,0.1,0,0.1,0h0.1h0.1c0.1,0,0.2-0.1,0.3-0.1l0,0c0.1-0.1,0.2-0.1,0.2-0.2 c0,0,0,0,0.1-0.1s0.2-0.2,0.2-0.2c0.1-0.1,0.1-0.2,0.2-0.3v-0.1c0.1-0.1,0.1-0.2,0.1-0.3v-0.1c0-0.1,0.1-0.2,0.1-0.4v-0.1 c0-0.2,0.1-0.3,0.1-0.5v-0.1c0-0.2,0-0.3,0-0.5L20,13.8L20.8,13.9z" />
                        <path class="st0"
                            d="M17.6,42.2h-0.7c-3.8-0.1-6.7-1.2-8.7-3.5c-1.9-2.2-2.9-5.3-2.9-9.3c0-0.2,0-0.3,0-0.4L5.7,5.6h0.7L6.1,28.9 c0,0.3,0,0.5,0,0.8v0.1c0,0.1,0,0.2,0,0.4c0,0.1,0,0.2,0,0.4c0,0.2,0,0.3,0,0.4s0,0.2,0,0.2c0,0.1,0,0.3,0,0.4s0,0.2,0,0.3 c0,0.1,0,0.2,0.1,0.3c0,0.1,0,0.2,0.1,0.3c0,0.1,0,0.2,0.1,0.3c0,0.1,0,0.2,0.1,0.3c0,0.1,0.1,0.2,0.1,0.4c0,0.1,0.1,0.2,0.1,0.3 s0.1,0.2,0.1,0.3s0.1,0.2,0.1,0.3C7,34.6,7,34.8,7.1,35c0.1,0.4,0.3,0.8,0.4,1.1c0.1,0.1,0.1,0.2,0.1,0.3s0.1,0.1,0.1,0.2 c0.1,0.1,0.1,0.2,0.1,0.3c0.1,0.1,0.1,0.2,0.1,0.3c0.1,0,0.1,0.2,0.2,0.3s0.1,0.2,0.2,0.2c0.1,0.2,0.2,0.3,0.3,0.4 c0.1,0.2,0.2,0.3,0.3,0.4C9,38.6,9,38.6,9.1,38.7C9.2,38.9,9.3,39,9.3,39l0,0c0.2,0.2,0.4,0.4,0.6,0.6c0.1,0.1,0.1,0.1,0.2,0.2 c0.2,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.1,0.1,0.2,0.1c0,0,0.1,0,0.1,0.1c0.1,0.1,0.1,0.1,0.2,0.1c0.1,0.1,0.2,0.1,0.3,0.2 c0.1,0.1,0.2,0.1,0.3,0.1c0.2,0.1,0.3,0.2,0.5,0.3s0.4,0.2,0.5,0.2s0.2,0.1,0.3,0.1s0.2,0.1,0.2,0.1h0.1c0.1,0,0.1,0.1,0.2,0.1 c0.1,0.1,0.3,0.1,0.4,0.1c0.1,0,0.1,0,0.2,0.1c0.2,0.1,0.4,0.1,0.6,0.2c0.1,0,0.2,0.1,0.3,0.1s0.1,0,0.2,0c0.1,0,0.2,0,0.2,0 c0.1,0,0.2,0,0.3,0.1c0.1,0,0.2,0,0.3,0l0,0c0.1,0,0.2,0,0.3,0s0.2,0,0.2,0c0.1,0,0.2,0,0.2,0c0.1,0,0.2,0,0.2,0 C17.2,42.1,17.4,42.2,17.6,42.2z" />
                        <path class="st2" d="M52.8,19.9v21.7h-10V14.1L52.8,19.9z" />
                        <path class="st1"
                            d="M42.7,14.1v25.7l0,0l10,1.8V19.9L42.7,14.1z M38.2,19.8v-8c-3.8,0.6-7.3,3.1-9.1,6.6l2.5,1.5l-0.2,9.8 c-0.1,2.8-0.7,5.2-1.9,7.1c1.8,2.8,4.9,4.7,8.5,4.7c0.1,0,0.1,0,0.2,0V28.7h4.1v-8.9C42.3,19.8,38.2,19.8,38.2,19.8z" />
                        <path class="st0" d="M38.2,11.7L38.2,11.7c-3.8,0.7-7.3,3.2-9.1,6.7l-1-0.6V5.9L38.2,11.7z" />
                        <path class="st1"
                            d="M20.8,13.9l-0.3,15.2c0,2.1-0.4,3.1-1.8,3.1c-1.5,0-2.2-1.1-2.1-3.1l0.3-17.5v0.1c-4.7,0.8-9,4.3-10.2,9.1 L6.6,29c-0.1,4.2,0.9,7.5,2.9,9.8s4.9,3.5,8.7,3.5c3.8,0.1,6.9-1,9.2-3.3s3.5-5.4,3.6-9.2l0.2-9.8L20.8,13.9z" />
                        <path class="st0"
                            d="M16.9,11.6v-0.1v-0.1l-0.2-0.1L6.9,5.6v1L6.7,20.7C7.8,16,12.2,12.4,16.9,11.6z" />
                        <path class="st1" d="M9.7,43.7L8.8,46H8.4l-0.9-2.3H8l0.6,1.6l0.6-1.6H9.7z" />
                        <path class="st1" d="M12.4,43.7V46H12v-2.3H12.4z" />
                        <path class="st1"
                            d="M14.7,46v-2.3h1.8c0.3,0,0.5,0.2,0.5,0.5v1.4c0,0.3-0.2,0.5-0.5,0.5h-1.8V46z M15.1,45.5h1.3v-1.4H15 L15.1,45.5L15.1,45.5z" />
                        <path class="st1"
                            d="M18.8,45.1v0.5h1.8V46h-2.3v-2.3h2.3v0.5h-1.8v0.5h1.8v0.5L18.8,45.1L18.8,45.1z" />
                        <path class="st1"
                            d="M22.4,43.7h1.4c0.3,0,0.5,0.2,0.5,0.5v1.4c0,0.3-0.2,0.5-0.5,0.5h-1.4c-0.3,0-0.5-0.2-0.5-0.5v-1.4 C21.9,43.8,22.1,43.7,22.4,43.7z M22.4,44.1v1.4h1.4v-1.4H22.4z" />
                        <path class="st1"
                            d="M30,45.1h-0.5V46H29v-2.3h1.8c0.3,0,0.5,0.2,0.5,0.5v0.5c0,0.3-0.2,0.5-0.4,0.5h-0.4l0.8,0.9h-0.6L30,45.1z M29.6,44.1v0.5H31v-0.5H29.6z" />
                        <path class="st1"
                            d="M33.2,45.1v0.5H35V46h-2.3v-2.3H35v0.5h-1.8v0.5H35v0.5L33.2,45.1L33.2,45.1z" />
                        <path class="st1"
                            d="M36.4,46v-1.8c0-0.3,0.2-0.5,0.5-0.5h1.4c0.3,0,0.5,0.2,0.5,0.5V46h-0.5v-1.8h-0.5V46h-0.5v-1.8h-0.5V46H36.4 z" />
                        <path class="st1" d="M41.4,43.7V46h-0.5v-2.3H41.4z" />
                        <path class="st1"
                            d="M44.5,44.8l-0.9-1.1h0.6l0.6,0.8l0.6-0.8H46l-1,1.1l0.9,1.1h-0.6l-0.6-0.8L44.2,46h-0.6L44.5,44.8z" />
                        <path class="st1"
                            d="M47.7,45.1v0.5h1.8V46h-2.3v-2.3h2.3v0.5h-1.8v0.5h1.8v0.5L47.7,45.1L47.7,45.1z" />
                        <path class="st1"
                            d="M53.1,44.1h-1.8v0.5h1.4c0.3,0,0.5,0.2,0.5,0.5v0.5c0,0.3-0.2,0.5-0.5,0.5h-1.8v-0.5h1.8v-0.5h-1.4 c-0.3,0-0.5-0.2-0.5-0.5v-0.5c0-0.3,0.2-0.5,0.5-0.5h1.8V44.1L53.1,44.1z" />
                        <path class="st1"
                            d="M30.5,0.1l-0.3,0.4l0.6,0.4l0.3-0.4l0.2,0.1l-0.6,1.1l-0.2-0.1l0.3-0.4l-0.6-0.4l-0.3,0.4L29.6,1l0.6-1 L30.5,0.1z" />
                        <path class="st1"
                            d="M32.5,2.2l-0.1,0.2l0.8,0.5l-0.1,0.2l-1-0.6l0.6-1.1l1,0.6l-0.1,0.2l-0.8-0.5L32.6,2l0.8,0.5l-0.1,0.2 L32.5,2.2z" />
                        <path class="st1"
                            d="M35.1,3.7l-0.2-0.1L34.6,4l-0.2-0.1L35,2.8l0.8,0.5C36,3.4,36,3.5,35.9,3.7l-0.1,0.2C35.7,4,35.6,4.1,35.5,4 l-0.2-0.1l0.1,0.6l-0.3-0.2L35.1,3.7z M35.1,3.2L35,3.4l0.6,0.4l0.1-0.2L35.1,3.2z" />
                        <path class="st1"
                            d="M36.8,5.3l0.6-1.1l0.2,0.1l0.2,1.1l0.4-0.8l0.2,0.1L37.9,6l-0.2-0.1l-0.2-1.1l-0.4,0.7L36.8,5.3z" />
                        <path class="st1"
                            d="M39.6,6.8L39.6,6.8l-0.3-0.1l1-0.7l0.2,0.1l-0.2,1.3l-0.2-0.2V7.1L39.6,6.8z M40.1,6.9l0.1-0.5l-0.4,0.3 L40.1,6.9z" />
                        <path class="st1"
                            d="M41.6,8.2l0.6-1.1l0.2,0.1l0.2,1.1L43,7.5l0.2,0.1l-0.6,1.1l-0.2-0.1l-0.2-1.1l-0.4,0.7H41.6z" />
                        <path class="st1"
                            d="M44,9.7l0.6-1.1l0.8,0.5c0.1,0.1,0.2,0.2,0.1,0.3L45.1,10c-0.1,0.1-0.2,0.2-0.3,0.1L44,9.7z M44.4,9.6L45,9.9 l0.4-0.6l-0.6-0.4L44.4,9.6z" />
                        <path class="st1"
                            d="M46.9,10.8L46.8,11l0.8,0.5l-0.1,0.2l-1-0.6l0.6-1.1l1,0.6L48,10.9l-0.8-0.5L47,10.6l0.8,0.5l-0.1,0.2 L46.9,10.8z" />
                        <path class="st1"
                            d="M49.9,13.2l-1-0.6l1.1-0.5l-0.6-0.4l0.1-0.2l1,0.6l-1.1,0.5L50,13L49.9,13.2z" />
                        <path class="st1"
                            d="M11.1,3l-0.7-0.4c-0.2-0.1-0.2-0.2-0.1-0.4l0.6-0.9l0.2,0.1l-0.6,0.9l0.7,0.4l0.6-0.9L12,2l-0.6,0.9 C11.4,3.1,11.2,3.1,11.1,3z" />
                        <path class="st1" d="M14,4.7L12.8,4l0.7-1.2L13.7,3l-0.6,0.9L14,4.4L14,4.7z" />
                        <path class="st1" d="M16.8,4.9L16.1,6l-0.2-0.1l0.7-1.2L16.8,4.9z" />
                        <path class="st1"
                            d="M19.8,7l-0.9-0.5l-0.1,0.2l0.7,0.4c0.2,0.1,0.2,0.2,0.1,0.4l-0.1,0.2c-0.1,0.2-0.2,0.2-0.4,0.1l-0.9-0.5V7 l0.9,0.5l0.1-0.2l-0.7-0.4c-0.2-0.1-0.2-0.2-0.1-0.4l0.1-0.2c0.1-0.2,0.2-0.2,0.4-0.1l0.9,0.5V7z" />
                        <path class="st1"
                            d="M21.2,8.5l-0.1,0.2L22,9.2l-0.1,0.2l-1.2-0.7l0.7-1.2l1.2,0.7l-0.2,0.2L21.5,8l-0.1,0.2l0.9,0.5L22.2,9 L21.2,8.5z" />
                        <path class="st1"
                            d="M25.1,10.1l-0.9-0.5L24,9.8l0.7,0.4c0.2,0.1,0.2,0.2,0.1,0.4l-0.1,0.2c-0.1,0.2-0.2,0.2-0.4,0.1l-1-0.6 l0.1-0.2l0.9,0.5l0.1-0.2L23.9,10c-0.2-0.1-0.2-0.2-0.1-0.4l0.1-0.2c0.1-0.2,0.2-0.2,0.4-0.1l0.9,0.5L25.1,10.1z" />
                    </g>
                </g>
            </svg>
            <div
                style="max-width:550px; display: block; margin: 0 auto; width: 100%; padding: 20px 50px; box-sizing: border-box; background-color: #0e1327; border-radius: 8px; -webkit-box-shadow: 1px 2px 6px 0px rgba(0,0,0,0.16); -moz-box-shadow: 1px 2px 6px 0px rgba(0,0,0,0.16); box-shadow: 1px 2px 6px 0px rgba(0,0,0,0.16); font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; text-align: center; color: #0e1327">
                <h2 style="color: #0066ff">¡Gracias por tu compra!</h2>
                <p  class="viewer" style="white-space: pre-wrap;color: white" [innerHtml]="formatedText">
                </p>
            </div><a
                style="width: 100%; display: block; margin-top: 20px; text-decoration: none; font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; text-align: center; color: #0066ff"
                href="https://www.uhremix.com/">UHRemixes @ 2021</a>
        </div>
    </div>
</div>
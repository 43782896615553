import { Component, OnInit } from "@angular/core";
import { AuthService } from "../../services/auth.service";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { DbService } from "src/app/services/db.service";

@Component({
  selector: "app-v2-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginV2Component implements OnInit {
  email = "";
  password = "";
  hasError = false;

  visible = false;
  text = "";
  title = "";
  link = "/login";

  constructor(
    private auth: AuthService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private db: DbService
  ) {}
  ngOnInit(): void {}

  login() {
    this.spinner.show();
    this.auth.loginUser(this.email, this.password).then(
      (res) => {
        this.db.getUser(res.user.uid).subscribe((user: any) => {
          console.log(user);
          if (user) {
            this.spinner.hide();
            localStorage.setItem("reload", "true");
            window.location.assign("/#/music");
            if (!user.membership) {
              console.log("ingresando con plan gratuito");
              localStorage.setItem("modalPromo", "true");
            }
          } else {
            this.spinner.hide();
            window.location.assign("/#/completeProfile");
          }
        });
        // this.visible = true;
        // this.title = "Correo Enviado";
        // alert( "Te ha llegado un correo de confirmación";
      },
      (error) => {
        this.spinner.hide();
        console.log(error);
        alert(error);
        this.hasError = true;
      }
    );
  }

  async signFacebook() {
    this.spinner.show();
    this.auth.signupUserFacebook().then(
      (res) => {
        console.log(res);
        localStorage.setItem("reload", "true");
        this.spinner.hide();
        window.location.assign("/#/music");
      },
      (error) => {
        console.log(error);
        this.spinner.hide();
        this.visible = true;
        this.title = "Error";
        alert(error.message);
      }
    );
  }

  async signGoogle() {
    this.spinner.show();
    this.auth.signupUserGoogle().then(
      (res) => {
        console.log(res);
        localStorage.setItem("reload", "true");
        this.spinner.hide();
        window.location.assign("/#/music");
      },
      (error) => {
        console.log(error);
        this.spinner.hide();
        this.visible = true;
        this.title = "Error";
        alert(error.message);
      }
    );
  }

  recoverPassword() {
    this.auth.recoverPassword(this.email).then(
      (res) => {
        console.log(res);
        this.visible = true;
        this.title = "Porfavor revista tu correo";
        alert(
          "Te hemos enviado un link a tu correo para poder restablecer tu contraseña"
        );
      },
      (error) => {
        console.log(error);
        this.visible = true;
        this.title = "Error";
        alert(error.message);
      }
    );
  }

  dismiss() {
    this.visible = false;
  }
}

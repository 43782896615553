import { Component, OnInit, HostListener } from "@angular/core";
import { DbService } from "src/app/services/db.service";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { MysqlService } from "src/app/services/mysql.service";
import { FormControl } from "@angular/forms";
import { ActiveCampService } from "src/app/services/active-camp.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-adminwmusic",
  templateUrl: "./adminwmusic.component.html",
  styleUrls: ["./adminwmusic.component.scss"],
})
export class AdminwmusicComponent implements OnInit {
  public numberOfPages = [];

  public pageSelected = 1;

  public paginatorControl = new FormControl(1);

  public paginatorLimit = new FormControl(25);

  constructor(
    private db: DbService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private mysql: MysqlService,
    public router: Router
  ) {}

  genres = [];

  items = [];

  deleteList = [];

  loading = false;

  hasMore = false;

  page = 0;

  searchWord = "";

  productId = "music";

  genre = "";

  ngOnInit(): void {
    this.paginatorControl.valueChanges.subscribe((value) => {
      this.pageSelected = parseInt(value);
      this.page = value - 1;
      this.applyFilters();
    });
    this.paginatorLimit.valueChanges.subscribe((value) => {
      this.applyFilters();
    });
    this.spinner.show();
    this.mysql.getGenre().subscribe((res: any) => {
      this.genres = res.genres;
      for (let g of res.genres) {
        this.genres[g.id] = g;
      }
      this.applyFilters();
    });
  }

  selectThing(thing, index) {
    if (thing.delete) {
      thing.delete = false;
      for (let i = 0; i <= this.deleteList.length; i++) {
        console.log(this.deleteList[i], thing.id);
        if (this.deleteList[i].id == thing.id) this.deleteList.splice(i, 1);
      }
    } else {
      thing.delete = true;
      this.deleteList.push({ id: thing.id, index });
    }
  }

  selectThings() {
    this.items = this.items.map((item) => ({
      ...item,
      delete: true,
    }));
    this.deleteList = this.items.map((item, index) => ({
      id: item.id,
      index,
    }));
    console.log(this.deleteList);
  }

  deSelectThings() {
    this.items = this.items.map((item) => ({
      ...item,
      delete: false,
    }));
    this.deleteList = [];
  }

  delete(id, index) {
    console.log(id);
    this.mysql.deleteProduct(id).subscribe(
      (res) => {
        console.log(res);
        this.items.splice(index, 1);
      },
      (error) => {
        alert("Algo salio mal, porfavor relodea la página");
        console.log(error);
      }
    );
  }

  deleteSelcted() {
    console.log(this.deleteList);
    for (let item of this.deleteList) {
      this.delete(item.id, item.index);
    }
    this.deleteList = [];
  }

  transformDate(item) {
    let date = item.createdAt;
    let d = new Date(date);
    let options: any = { day: "numeric", month: "long", year: "numeric" };
    item.date = d.toLocaleDateString("es-ES", options);
  }

  applyFilters() {
    const today = new Date();
    const june13 = new Date(
      today.getFullYear(),
      5 /* June is zero-indexed in JavaScript */,
      13
    );
    this.deSelectThings();
    this.mysql
      .getGlobalSearch(
        this.page,
        this.genre,
        this.searchWord,
        this.productId,
        this.paginatorLimit.value
      )
      .subscribe((res: any) => {
        let length = res.products ? res.products.length : res.length;
        let products = res.products ? res.products : res;
        if (length < this.paginatorLimit.value) this.hasMore = false;
        for (let s of products) {
          this.transformDate(s);
        }
        this.items = products;
        const filteredArray = this.items.filter((obj) => {
          // Convert createdAt string to a Date object
          const createdAtDate = new Date(obj.createdAt);
          // Compare createdAt date with June 13 of the current year
          return createdAtDate > june13;
        });
        this.items = filteredArray;
        console.log(this.items);

        this.loading = false;
      });
    this.getPaginator(this.productId, this.searchWord, "");
    this.spinner.hide();
  }

  public getPaginator(type, search, genre): void {
    window.scrollTo(0, 0);
    this.mysql
      .getPageLength(type, search ? search : "empty", genre ? genre : "empty")
      .subscribe((data: any) => {
        let pages = Math.ceil(
          data.pages[0]["count(*)"] / this.paginatorLimit.value
        );
        this.numberOfPages = new Array(pages);
      });
  }
}

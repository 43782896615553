<div class="container">
    <h3>Mi cuenta:</h3>
    <div class="account" *ngIf="!editInfo">
        <div>
            <span>Nombre</span>
            <p>{{user.name}}</p>
        </div>
        <div>
            <span>Correo</span>
            <p class="">{{user.email}}</p>
        </div>
        <div>
            <span>Telefono</span>
            <p class="">{{user.phone}}</p>
        </div>
    </div>
    <div *ngIf="!editInfo">
        <button class="full button-edit" (click)="editarInfo(); editInfo = true;">Editar mi informacion</button>
    </div>
    <div *ngIf="editInfo">
        <h3>Confirma tu cuenta para poder actalizar los datos</h3>
        <input type="password" placeholder="Contraseña actual" [formControl]="form.controls.ant_password">
        <h3>Informacion General</h3>
        <input type="text" placeholder="Nombre" [formControl]="form.controls.name"> <br>
        <div class="phone">
            <input required list="phone-coodes" name="browser" id="browser" [formControl]="form.controls.code">
            <datalist id="phone-coodes">
                <option *ngFor="let n of numbers" [value]="n.code">{{n.display}}</option>
            </datalist>
            <input required name="phone" placeholder="Celular" type="phone" maxlength="10" minlength="10" [formControl]="form.controls.phone">
        </div>
        <h3>Correo electronico <span class="errorText" *ngIf="form.controls.repeat_email.value && form.controls.email.value && form.controls.repeat_email.value !== form.controls.email.value">El correo debe coincidir</span></h3>
        <input type="text" placeholder="Correo electronico" [formControl]="form.controls.email">
        <input type="text" placeholder="Confirma el correo electronico" [formControl]="form.controls.repeat_email">
        <h3>Contraseña <span class="errorText" *ngIf="form.controls.repeat_password.value && form.controls.password.value && form.controls.repeat_password.value !== form.controls.password.value">La contraseña debe coincidir</span></h3>
        <input type="password" placeholder="Contraseña nueva" [formControl]="form.controls.password">
        <input type="password" placeholder="Confirma la contraseña" [formControl]="form.controls.repeat_password">
        <div class="controles">
            <button class="ghost button-edit" (click)="editInfo = false;">Cancelar</button>
            <button class="full button-edit" (click)="saveInfo()" [disabled]="!form.controls.ant_password.value || (!form.controls.name.value || !form.controls.phone.value || !form.controls.code.value || ((form.controls.repeat_email.value || form.controls.email.value ) && form.controls.repeat_email.value !== form.controls.email.value) || ((form.controls.repeat_password.value || form.controls.password.value) && form.controls.repeat_password.value !== form.controls.password.value))">Guardar</button>
        </div>
    </div>
</div>
<div class="container" *ngIf="!editInfo">
    <h3>Subscripción</h3>
    <div class="subscription detail">
        <p class="bold">{{plan.name}}  <br> </p>
        <p class="dim" *ngIf="!user?.membership?.cancelled">({{plan.status}})</p>
        <p class="dim" *ngIf="user?.membership?.cancelled">(Renovación cancelada)</p>
        <div class="left">
            <p class="dim">${{plan.price}} MXN</p>
            <!-- <p class="blue pointer" routerLink="/choose-plan">Mejorar</p> -->
            <p class="blue pointer" routerLink="/choose-plan" *ngIf="plan.type=='uh'">Mejorar</p>
            <p class="blue pointer" routerLink="/choose-plan" *ngIf="plan.upgrade && plan.type!='stripe'">Mejorar</p>
            <!-- <p class="blue pointer" (click)="openDashboard()" >Mejorar</p> -->
            <p class="blue pointer" (click)="openDashboard()" *ngIf="plan.upgrade && plan.type=='stripe' && !user?.membership?.cancelled">Mejorar</p>
            <p class="blue pointer" (click)="openDashboard()" *ngIf="plan.status!='active' && plan.type=='stripe' && !user?.membership?.cancelled">Corregir método</p>
            <p class="red pointer" (click)="cancelSubscription()" *ngIf="plan.price!='Gratis' && plan.type=='stripe' && !user?.membership?.cancelled">
                Cancelar</p>
            <ng-container *ngIf="user.paypalSubscription != undefined && !user?.membership?.cancelled">
                <a class="red pointer"
                    [href]="'https://www.paypal.com/myaccount/autopay/connect/'+user.paypalSubscription" target="_blank"
                    *ngIf="plan.price!='Gratis' && plan.type=='paypal' ">Cancelar</a>
            </ng-container>
            <a class="red pointer" *ngIf="plan.type=='conekta' && !user.membership?.cancelled" (click)="cancelConektaSubscription()">
                Cancelar
            </a>
            <a class="red pointer" *ngIf="plan.type=='mercado pago' && !user.membership?.cancelled" (click)="cancelMercadoSubscription()">
                Cancelar
            </a>
        </div>
    </div>
</div>
<!-- END OF CONTAINER -->


<div class="container" *ngIf="!editInfo">
    <div class="detail exit" (click)="logout()">
        <p class="blue">Cerrar Sesión</p>
    </div>
</div>


<ngx-spinner bdColor="rgba(10, 14, 31,0.8)" size="medium" color="#0066ff"></ngx-spinner>

<div class="alertmodal" *ngIf="alertmodal">
    <div class="alertmodal-back"></div>
    <div class="alertmodal-main">
        <div><p>Cargando, por favor espere <br> no cierre esta ventana</p></div>
        <div><img class="loading-icon" src="/assets/icons/loading.svg" alt=""></div>
    </div>
</div>
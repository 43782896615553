<div class="tableee">
    <h3>Generos</h3>
    <div for="genre" class="header">
        <label for="genre">Añadir un Nuevo Género </label>
        <input id="genre" (keyup.enter)="createGenre()" [(ngModel)]="genre" name="genre"placeholder="Nuevo Género">
    </div>
    <div class="content">
        <div class="row" *ngFor="let g of genres ; let i = index">
            <p class="title bold" >{{g.genre}}</p>
            <span class="delete" (click)="del(g.genre,i)"></span>
        </div>
    </div>
</div>
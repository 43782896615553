import { Component, OnInit } from "@angular/core";
import { DbService } from "src/app/services/db.service";
import { AuthService } from "src/app/services/auth.service";
import { trimTrailingNulls } from "@angular/compiler/src/render3/view/util";

@Component({
  selector: "app-choose-plan",
  templateUrl: "./choose-plan.component.html",
  styleUrls: ["./choose-plan.component.scss"],
})
export class ChoosePlanComponent implements OnInit {
  selected = "";
  text = "Saltar";

  myHome = {
    banner: "",
    button: "",
    headline: "",
    text: "",
    tokens: "",
    wa: "",
    fb: "",
    isVideo: false,
    hideWa: false,
    hideFb: false,
    hide99: false,
    hide149: false,
    hide199: false,
    hide1899: false,
    hide8: false,
    hide12: false,
    hide16: false,
    hide145: false,
    hide30250: false,
    hide45376: false,
    hide60501: false,
    hide548296: false,
  };

  constructor(private db: DbService, private auth: AuthService) {}

  ngOnInit(): void {
    this.db.getHome().subscribe((res) => {
      console.log(res);
      this.myHome = res[0];
    });
    this.auth.getUser().then((res) => {
      if (!res) return;
      if (res != undefined) {
        this.db.getUser(res.uid).subscribe((user: any) => {
          console.log(user.tokens);
          if (user.tokens != 0)
            this.text = `Continuar con ${user.tokens} descargas gratis`;
        });
      }
    });

    // if (localStorage.getItem("reload") == "true") {
    //   localStorage.setItem("reload", "false");
    //   window.location.reload();
    // }
  }

  select(type) {
    this.selected = type;
  }
}

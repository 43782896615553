export const numbers = [
    {code:'+44' ,display: 'UK (+44)'},
    {code:'+1' ,display: 'USA (+1)'},
    {code:'+213' ,display: 'Algeria (+213)'},
    {code:'+376' ,display: 'Andorra (+376)'},
    {code:'+244' ,display: 'Angola (+244)'},
    {code:'+1264' ,display: 'Anguilla (+1264)'},
    {code:'+1268' ,display: 'Antigua & Barbuda (+1268)'},
    {code:'+54' ,display: 'Argentina (+54)'},
    {code:'+374' ,display: 'Armenia (+374)'},
    {code:'+297' ,display: 'Aruba (+297)'},
    {code:'+61' ,display: 'Australia (+61)'},
    {code:'+43' ,display: 'Austria (+43)'},
    {code:'+994' ,display: 'Azerbaijan (+994)'},
    {code:'+1242' ,display: 'Bahamas (+1242)'},
    {code:'+973' ,display: 'Bahrain (+973)'},
    {code:'+880' ,display: 'Bangladesh (+880)'},
    {code:'+1246' ,display: 'Barbados (+1246)'},
    {code:'+375' ,display: 'Belarus (+375)'},
    {code:'+32' ,display: 'Belgium (+32)'},
    {code:'+501' ,display: 'Belize (+501)'},
    {code:'+229' ,display: 'Benin (+229)'},
    {code:'+1441' ,display: 'Bermuda (+1441)'},
    {code:'+975' ,display: 'Bhutan (+975)'},
    {code:'+591' ,display: 'Bolivia (+591)'},
    {code:'+387' ,display: 'Bosnia Herzegovina (+387)'},
    {code:'+267' ,display: 'Botswana (+267)'},
    {code:'+55' ,display: 'Brazil (+55)'},
    {code:'+673' ,display: 'Brunei (+673)'},
    {code:'+359' ,display: 'Bulgaria (+359)'},
    {code:'+226' ,display: 'Burkina Faso (+226)'},
    {code:'+257' ,display: 'Burundi (+257)'},
    {code:'+855' ,display: 'Cambodia (+855)'},
    {code:'+237' ,display: 'Cameroon (+237)'},
    {code:'+1' ,display: 'Canada (+1)'},
    {code:'+238' ,display: 'Cape Verde Islands (+238)'},
    {code:'+1345' ,display: 'Cayman Islands (+1345)'},
    {code:'+236' ,display: 'Central African Republic (+236)'},
    {code:'+56' ,display: 'Chile (+56)'},
    {code:'+86' ,display: 'China (+86)'},
    {code:'+57' ,display: 'Colombia (+57)'},
    {code:'+269' ,display: 'Comoros (+269)'},
    {code:'+242' ,display: 'Congo (+242)'},
    {code:'+682' ,display: 'Cook Islands (+682)'},
    {code:'+506' ,display: 'Costa Rica (+506)'},
    {code:'+385' ,display: 'Croatia (+385)'},
    {code:'+53' ,display: 'Cuba (+53)'},
    {code:'+90392' ,display: 'Cyprus North (+90392)'},
    {code:'+357' ,display: 'Cyprus South (+357)'},
    {code:'+42' ,display: 'Czech Republic (+42)'},
    {code:'+45' ,display: 'Denmark (+45)'},
    {code:'+253' ,display: 'Djibouti (+253)'},
    {code:'+1809' ,display: 'Dominica (+1809)'},
    {code:'+1809' ,display: 'Dominican Republic (+1809)'},
    {code:'+593' ,display: 'Ecuador (+593)'},
    {code:'+20' ,display: 'Egypt (+20)'},
    {code:'+503' ,display: 'El Salvador (+503)'},
    {code:'+240' ,display: 'Equatorial Guinea (+240)'},
    {code:'+291' ,display: 'Eritrea (+291)'},
    {code:'+372' ,display: 'Estonia (+372)'},
    {code:'+251' ,display: 'Ethiopia (+251)'},
    {code:'+500' ,display: 'Falkland Islands (+500)'},
    {code:'+298' ,display: 'Faroe Islands (+298)'},
    {code:'+679' ,display: 'Fiji (+679)'},
    {code:'+358' ,display: 'Finland (+358)'},
    {code:'+33' ,display: 'France (+33)'},
    {code:'+594' ,display: 'French Guiana (+594)'},
    {code:'+689' ,display: 'French Polynesia (+689)'},
    {code:'+241' ,display: 'Gabon (+241)'},
    {code:'+220' ,display: 'Gambia (+220)'},
    {code:'+7880' ,display: 'Georgia (+7880)'},
    {code:'+49' ,display: 'Germany (+49)'},
    {code:'+233' ,display: 'Ghana (+233)'},
    {code:'+350' ,display: 'Gibraltar (+350)'},
    {code:'+30' ,display: 'Greece (+30)'},
    {code:'+299' ,display: 'Greenland (+299)'},
    {code:'+1473' ,display: 'Grenada (+1473)'},
    {code:'+590' ,display: 'Guadeloupe (+590)'},
    {code:'+671' ,display: 'Guam (+671)'},
    {code:'+502' ,display: 'Guatemala (+502)'},
    {code:'+224' ,display: 'Guinea (+224)'},
    {code:'+245' ,display: 'Guinea - Bissau (+245)'},
    {code:'+592' ,display: 'Guyana (+592)'},
    {code:'+509' ,display: 'Haiti (+509)'},
    {code:'+504' ,display: 'Honduras (+504)'},
    {code:'+852' ,display: 'Hong Kong (+852)'},
    {code:'+36' ,display: 'Hungary (+36)'},
    {code:'+354' ,display: 'Iceland (+354)'},
    {code:'+91' ,display: 'India (+91)'},
    {code:'+62' ,display: 'Indonesia (+62)'},
    {code:'+98' ,display: 'Iran (+98)'},
    {code:'+964' ,display: 'Iraq (+964)'},
    {code:'+353' ,display: 'Ireland (+353)'},
    {code:'+972' ,display: 'Israel (+972)'},
    {code:'+39' ,display: 'Italy (+39)'},
    {code:'+1876' ,display: 'Jamaica (+1876)'},
    {code:'+81' ,display: 'Japan (+81)'},
    {code:'+962' ,display: 'Jordan (+962)'},
    {code:'+7' ,display: 'Kazakhstan (+7)'},
    {code:'+254' ,display: 'Kenya (+254)'},
    {code:'+686' ,display: 'Kiribati (+686)'},
    {code:'+850' ,display: 'Korea North (+850)'},
    {code:'+82' ,display: 'Korea South (+82)'},
    {code:'+965' ,display: 'Kuwait (+965)'},
    {code:'+996' ,display: 'Kyrgyzstan (+996)'},
    {code:'+856' ,display: 'Laos (+856)'},
    {code:'+371' ,display: 'Latvia (+371)'},
    {code:'+961' ,display: 'Lebanon (+961)'},
    {code:'+266' ,display: 'Lesotho (+266)'},
    {code:'+231' ,display: 'Liberia (+231)'},
    {code:'+218' ,display: 'Libya (+218)'},
    {code:'+417' ,display: 'Liechtenstein (+417)'},
    {code:'+370' ,display: 'Lithuania (+370)'},
    {code:'+352' ,display: 'Luxembourg (+352)'},
    {code:'+853' ,display: 'Macao (+853)'},
    {code:'+389' ,display: 'Macedonia (+389)'},
    {code:'+261' ,display: 'Madagascar (+261)'},
    {code:'+265' ,display: 'Malawi (+265)'},
    {code:'+60' ,display: 'Malaysia (+60)'},
    {code:'+960' ,display: 'Maldives (+960)'},
    {code:'+223' ,display: 'Mali (+223)'},
    {code:'+356' ,display: 'Malta (+356)'},
    {code:'+692' ,display: 'Marshall Islands (+692)'},
    {code:'+596' ,display: 'Martinique (+596)'},
    {code:'+222' ,display: 'Mauritania (+222)'},
    {code:'+269' ,display: 'Mayotte (+269)'},
    {code:'+52' ,display: 'Mexico (+52)'},
    {code:'+691' ,display: 'Micronesia (+691)'},
    {code:'+373' ,display: 'Moldova (+373)'},
    {code:'+377' ,display: 'Monaco (+377)'},
    {code:'+976' ,display: 'Mongolia (+976)'},
    {code:'+1664' ,display: 'Montserrat (+1664)'},
    {code:'+212' ,display: 'Morocco (+212)'},
    {code:'+258' ,display: 'Mozambique (+258)'},
    {code:'+95' ,display: 'Myanmar (+95)'},
    {code:'+264' ,display: 'Namibia (+264)'},
    {code:'+674' ,display: 'Nauru (+674)'},
    {code:'+977' ,display: 'Nepal (+977)'},
    {code:'+31' ,display: 'Netherlands (+31)'},
    {code:'+687' ,display: 'New Caledonia (+687)'},
    {code:'+64' ,display: 'New Zealand (+64)'},
    {code:'+505' ,display: 'Nicaragua (+505)'},
    {code:'+227' ,display: 'Niger (+227)'},
    {code:'+234' ,display: 'Nigeria (+234)'},
    {code:'+683' ,display: 'Niue (+683)'},
    {code:'+672' ,display: 'Norfolk Islands (+672)'},
    {code:'+670' ,display: 'Northern Marianas (+670)'},
    {code:'+47' ,display: 'Norway (+47)'},
    {code:'+968' ,display: 'Oman (+968)'},
    {code:'+680' ,display: 'Palau (+680)'},
    {code:'+507' ,display: 'Panama (+507)'},
    {code:'+675' ,display: 'Papua New Guinea (+675)'},
    {code:'+595' ,display: 'Paraguay (+595)'},
    {code:'+51' ,display: 'Peru (+51)'},
    {code:'+63' ,display: 'Philippines (+63)'},
    {code:'+48' ,display: 'Poland (+48)'},
    {code:'+351' ,display: 'Portugal (+351)'},
    {code:'+1787' ,display: 'Puerto Rico (+1787)'},
    {code:'+974' ,display: 'Qatar (+974)'},
    {code:'+262' ,display: 'Reunion (+262)'},
    {code:'+40' ,display: 'Romania (+40)'},
    {code:'+7' ,display: 'Russia (+7)'},
    {code:'+250' ,display: 'Rwanda (+250)'},
    {code:'+378' ,display: 'San Marino (+378)'},
    {code:'+239' ,display: 'Sao Tome & Principe (+239)'},
    {code:'+966' ,display: 'Saudi Arabia (+966)'},
    {code:'+221' ,display: 'Senegal (+221)'},
    {code:'+381' ,display: 'Serbia (+381)'},
    {code:'+248' ,display: 'Seychelles (+248)'},
    {code:'+232' ,display: 'Sierra Leone (+232)'},
    {code:'+65' ,display: 'Singapore (+65)'},
    {code:'+421' ,display: 'Slovak Republic (+421)'},
    {code:'+386' ,display: 'Slovenia (+386)'},
    {code:'+677' ,display: 'Solomon Islands (+677)'},
    {code:'+252' ,display: 'Somalia (+252)'},
    {code:'+27' ,display: 'South Africa (+27)'},
    {code:'+34' ,display: 'Spain (+34)'},
    {code:'+94' ,display: 'Sri Lanka (+94)'},
    {code:'+290' ,display: 'St. Helena (+290)'},
    {code:'+1869' ,display: 'St. Kitts (+1869)'},
    {code:'+1758' ,display: 'St. Lucia (+1758)'},
    {code:'+249' ,display: 'Sudan (+249)'},
    {code:'+597' ,display: 'Suriname (+597)'},
    {code:'+268' ,display: 'Swaziland (+268)'},
    {code:'+46' ,display: 'Sweden (+46)'},
    {code:'+41' ,display: 'Switzerland (+41)'},
    {code:'+963' ,display: 'Syria (+963)'},
    {code:'+886' ,display: 'Taiwan (+886)'},
    {code:'+7' ,display: 'Tajikstan (+7)'},
    {code:'+66' ,display: 'Thailand (+66)'},
    {code:'+228' ,display: 'Togo (+228)'},
    {code:'+676' ,display: 'Tonga (+676)'},
    {code:'+1868' ,display: 'Trinidad & Tobago (+1868)'},
    {code:'+216' ,display: 'Tunisia (+216)'},
    {code:'+90' ,display: 'Turkey (+90)'},
    {code:'+7' ,display: 'Turkmenistan (+7)'},
    {code:'+993' ,display: 'Turkmenistan (+993)'},
    {code:'+1649' ,display: 'Turks & Caicos Islands (+1649)'},
    {code:'+688' ,display: 'Tuvalu (+688)'},
    {code:'+256' ,display: 'Uganda (+256)'},
    {code:'+380' ,display: 'Ukraine (+380)'},
    {code:'+971' ,display: 'United Arab Emirates (+971)'},
    {code:'+598' ,display: 'Uruguay (+598)'},
    {code:'+7' ,display: 'Uzbekistan (+7)'},
    {code:'+678' ,display: 'Vanuatu (+678)'},
    {code:'+379' ,display: 'Vatican City (+379)'},
    {code:'+58' ,display: 'Venezuela (+58)'},
    {code:'+84' ,display: 'Vietnam (+84)'},
    {code:'+84' ,display: 'Virgin Islands - British (+1284)'},
    {code:'+84' ,display: 'Virgin Islands - US (+1340)'},
    {code:'+681' ,display: 'Wallis & Futuna (+681)'},
    {code:'+969' ,display: 'Yemen (North)(+969)'},
    {code:'+967' ,display: 'Yemen (South)(+967)'},
    {code:'+260' ,display: 'Zambia (+260)'},
    {code:'+263' ,display: 'Zimbabwe (+263)'},
  ]


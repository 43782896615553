<div class="background"></div>
<div class="container">
  <img src="/assets/logo.svg" />
  <form ngNativeValidate (ngSubmit)="login()" class="form">
    <p>Rellena tus datos personales</p>
    <input
      required
      [(ngModel)]="email"
      name="email"
      placeholder="Correo"
      type="email"
    />
    <input
      required
      [(ngModel)]="password"
      name="password"
      placeholder="Contraseña"
      type="password"
    />
    <p (click)="recoverPassword()" class="small recover">
      ¿Olvidaste tu contraseña
    </p>
    <br />
    <p class="small red" *ngIf="hasError">
      No encontramos un usuario con ese correo y contraseña,
      <span class="blue small select underline" routerLink="/signup"
        >Crea un usuario nuevo</span
      >
    </p>
    <button type="submit" class="fulla">Iniciar Sesión</button>

    <!--  <div class="logins">
      <div class="google" (click)="signGoogle()"></div>
      <div class="facebook" (click)="signFacebook()"></div>
    </div> -->
    <p class="end">¿No tienes cuenta?</p>
    <a class="blue pointer underline" routerLink="/signup">Registrate</a>
  </form>
</div>
<app-modal
  (click)="dismiss()"
  [visible]="visible"
  [text]="text"
  [title]="title"
  [link]="link"
></app-modal>

<!-- <div class="container">
    <div class="left">
        <div class="content">
            <h3>Inicia Sesión</h3>
            <p class="mid">Disfruta de Videos y Música de manera ilimitada</p>
            <form ngNativeValidate (ngSubmit)="login()">
                <input required [(ngModel)]="email" name="email" placeholder="Correo" type="email">
                <input required [(ngModel)]="password" name="password" placeholder="Contraseña" type="password">
                <p (click)="recoverPassword()" class="blue underline pointer right nomargin">¿Olvidaste tu contraseña?
                </p>
                <p class="red" *ngIf="hasError">No encontramos un usuario con ese correo y contraseña, <span
                        class='blue select underline' routerLink='/signup'>Crea un usuario nuevo</span></p>
                <p *ngIf="!hasError" class="invisible">No encontramos un usuario con ese correo y contraseña, <span
                        class='blue select underline' routerLink='/signup'>Crea un usuario nuevo</span></p>
                <div class="logins">
                    <div class="google" (click)="signGoogle()"></div>
                    <div class="facebook" (click)="signFacebook()"></div>
                </div>
                <div class="auths">
                    <button class="full sig disabled">Iniciar Sesión</button>
                </div>
                <p class="end">¿No tienes cuenta?</p>
                <a class="blue pointer underline" routerLink="/signup">Registrate</a>
            </form>
        </div>

    </div>
</div>
<app-modal (click)="dismiss()" [visible]="visible" [text]="text" [title]="title" [link]="link"></app-modal> -->

<div class="container">
  <app-toggle (click)="toggleVid()" text="El banner es un video?" [isOn]="myHome.isVideo"></app-toggle>
  <uploader alsoPublic="true" genre="banner" title="Banner" type="home"></uploader>
  <p>Descargas Gratis</p>
  <input id="button" [(ngModel)]="myHome.tokens" name="tok" placeholder="Ingresa núm de tokens" />

  <p>banner</p>
  <input id="banner" [(ngModel)]="myHome.banner" name="banner" placeholder="Ingresa el banner" />
  <p>botón</p>
  <input id="button" [(ngModel)]="myHome.button" name="button" placeholder="Ingresa el botón" />
  <p>título</p>
  <textarea id="headline" rows="5" [(ngModel)]="myHome.headline" name="headline"
    placeholder="Ingresa el título"></textarea>
  <p>texto</p>
  <textarea id="text" rows="5" [(ngModel)]="myHome.text" name="text" placeholder="Ingresa el texto"></textarea>

  <p>Link Whatsapp</p>
  <input id="button" [(ngModel)]="myHome.wa" name="tok" placeholder="Ingresa el link de WA" />

  <div class="hide">
    <app-toggle (click)="myHome.hideWa = !myHome.hideWa" text="Mostrar Whatsapp" [isOn]="!myHome.hideWa"></app-toggle>
  </div>

  <p>Link Messenger</p>
  <input id="button" [(ngModel)]="myHome.fb" name="tok" placeholder="Ingresa el link de FB" />

  <div class="hide">
    <app-toggle (click)="myHome.hideFb = !myHome.hideFb" text="Mostrar Messenger" [isOn]="!myHome.hideFb"></app-toggle>
  </div>

  <hr />

  <p>Planes</p>

  <div class="hide">
    <app-toggle (click)="myHome.hide99 = !myHome.hide99" text="Mostrar plan 99" [isOn]="!myHome.hide99"></app-toggle>
  </div>
  <div class="hide">
    <app-toggle (click)="myHome.hide149 = !myHome.hide149" text="Mostrar plan 149" [isOn]="!myHome.hide149">
    </app-toggle>
  </div>
  <div class="hide">
    <app-toggle (click)="myHome.hide199 = !myHome.hide199" text="Mostrar plan 199" [isOn]="!myHome.hide199">
    </app-toggle>
  </div>
  <div class="hide">
    <app-toggle (click)="myHome.hide1899 = !myHome.hide1899" text="Mostrar plan 1899" [isOn]="!myHome.hide1899">
    </app-toggle>
  </div>
  <div class="hide">
    <app-toggle (click)="myHome.hide8 = !myHome.hide8" text="Mostrar plan 8" [isOn]="!myHome.hide8"></app-toggle>
  </div>
  <div class="hide">
    <app-toggle (click)="myHome.hide12 = !myHome.hide12" text="Mostrar plan 12" [isOn]="!myHome.hide12"></app-toggle>
  </div>
  <div class="hide">
    <app-toggle (click)="myHome.hide16 = !myHome.hide16" text="Mostrar plan 16" [isOn]="!myHome.hide16"></app-toggle>
  </div>
  <div class="hide">
    <app-toggle (click)="myHome.hide145 = !myHome.hide145" text="Mostrar plan 145" [isOn]="!myHome.hide145">
    </app-toggle>
  </div>

  <div class="hide">
    <app-toggle (click)="myHome.hide30250 = !myHome.hide30250" text="Mostrar plan 30250" [isOn]="!myHome.hide30250">
    </app-toggle>
  </div>
  <div class="hide">
    <app-toggle (click)="myHome.hide45376 = !myHome.hide45376" text="Mostrar plan 45376" [isOn]="!myHome.hide45376">
    </app-toggle>
  </div>
  <div class="hide">
    <app-toggle (click)="myHome.hide60501 = !myHome.hide60501" text="Mostrar plan 60501" [isOn]="!myHome.hide60501">
    </app-toggle>
  </div>
  <div class="hide">
    <app-toggle (click)="myHome.hide548296 = !myHome.hide548296" text="Mostrar plan 548296" [isOn]="!myHome.hide548296">
    </app-toggle>
  </div>

  <button (click)="saveChanges()" class="full blue">Guardar</button>
</div>
import { Injectable } from "@angular/core";

import { environment } from "src/environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root",
})
export class MysqlService {
  constructor(private http: HttpClient) { }

  musicId = "music";
  comboMusicId = "combos_mus";
  videoId = "video";
  comboVideoId = "combos_vid";
  karaoke = "karaoke";

  createAbandonedCart(user) {
    return this.http.post(`${environment.endpoint}/events/emptycart`, {
      ...user,
    });
  }

  deleteAbandonedCart(userId) {
    return this.http.post(`${environment.endpoint}/events/del/emptycart/`, {
      id: userId,
    });
  }

  createMercadoSubscription(data) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer TEST-5448150659256583-101312-42d2525eb12f3edf0e53d898fa0602c1__LA_LB__-236259122',
        'Content-Type': 'application/JSON',
      })
    }
    return this.http.post(`https://api.mercadopago.com/preapproval?access_token=TEST-5448150659256583-101312-42d2525eb12f3edf0e53d898fa0602c1__LA_LB__-236259122`, {
      "reason": data.plan,
      "auto_recurring": {
        "frequency": 1,
        "frequency_type": data.frequency,
        "transaction_amount": data.amount,
        "currency_id": "MXN"
      },
      "back_url": data.backUrl,
      "card_token_id": data.cardToken,
      "payer_email": data.email,
      "status": "authorized"
    },httpOptions);
  }

  cancelMercadoSubscription(id){

  }

  // nodownloads(user){
  //   return this.http.post(`${environment.endpoint}/events/nodownloads`,{user})
  // }
  // startdownloads(user){
  //   return this.http.post(`${environment.endpoint}/events/startdownloads`,{user})
  // }
  // failpayment(user){
  //   return this.http.post(`${environment.endpoint}/events/failpayment`,{user})
  // }
  // succsesfulpayment(user,planPrice){
  //   return this.http.post(`${environment.endpoint}/events/succsesfulpayment`,{user,planPrice})
  // }

  createOxxoPay(name, email, plan, price) {
    return this.http.post(`${environment.endpoint}/conekta`, {
      name,
      email,
      plan,
      price,
    });
  }

  getInfo(id) {
    return this.http.get(`${environment.endpoint}/info/${id}`);
  }

  //ADMINS
  upload(form) {
    return this.http.post(`${environment.endpoint}/upload/`, form);
  }

  addGenre(genre) {
    return this.http.post(`${environment.endpoint}/genre`, { genre });
  }

  addCombo(fileType, folder, genre) {
    return this.http.post(`${environment.endpoint}/combo`, {
      fileType,
      folder,
      genre,
    });
  }

  deleteGenre(genre) {
    return this.http.delete(`${environment.endpoint}/genre/${genre}`);
  }

  deleteProduct(product) {
    return this.http.delete(`${environment.endpoint}/products/${product}`);
  }

  getGlobalSearch(page, genre, search, productId, limit) {
    if (search != "" && genre != "") {
      return this.http.get(
        `${environment.endpoint}/products/${productId}/${page}/search/${search}/${genre}/${limit}`
      );
    } else if (genre != "") {
      return this.http.get(
        `${environment.endpoint}/products/${productId}/${page}/genre/${genre}/${limit}`
      );
    } else if (search != "") {
      return this.http.get(
        `${environment.endpoint}/products/${productId}/${page}/search/${search}/${limit}`
      );
    } else {
      return this.http.get(
        `${environment.endpoint}/products/${productId}/${page}/${limit}`
      );
    }
  }

  // VIDEO

  getVideo(page) {
    return this.http.get(
      `${environment.endpoint}/products/${this.videoId}/${page}`
    );
  }

  getVideoGenre(page, genre) {
    return this.http.get(
      `${environment.endpoint}/products/${this.videoId}/${page}/genre/${genre}`
    );
  }

  getVideoSearch(page, search) {
    return this.http.get(
      `${environment.endpoint}/products/${this.videoId}/${page}/search/${search}`
    );
  }

  getVideoGenreSearch(page, search, genre) {
    return this.http.get(
      `${environment.endpoint}/products/${this.videoId}/${page}/search/${search}/${genre}`
    );
  }

  // MUSIC

  getMusic(page) {
    return this.http.get(
      `${environment.endpoint}/products/${this.musicId}/${page}`
    );
  }

  getMusicGenre(page, genre) {
    return this.http.get(
      `${environment.endpoint}/products/${this.musicId}/${page}/genre/${genre}`
    );
  }

  getPageLength(type, search, genre) {
    return this.http.get(
      `${environment.endpoint}/productlength/${type}/search/${search}/genre/${genre}`
      /* `${environment.endpoint}/productlength/${type}` */
    );
  }

  getMusicSearch(page, search) {
    return this.http.get(
      `${environment.endpoint}/products/${this.musicId}/${page}/search/${search}`
    );
  }

  getMusicGenreSearch(page, search, genre) {
    return this.http.get(
      `${environment.endpoint}/products/${this.musicId}/${page}/search/${search}/${genre}`
    );
  }

  // KARAOKE

  getComboVideo(page) {
    return this.http.get(
      `${environment.endpoint}/products/${this.comboVideoId}/${page}`
    );
  }

  getComboVideoGenre(page, genre) {
    return this.http.get(
      `${environment.endpoint}/products/${this.comboVideoId}/${page}/genre/${genre}`
    );
  }

  getComboVideoSearch(page, search) {
    return this.http.get(
      `${environment.endpoint}/products/${this.comboVideoId}/${page}/search/${search}`
    );
  }

  getComboVideoGenreSearch(page, search, genre) {
    return this.http.get(
      `${environment.endpoint}/products/${this.comboVideoId}/${page}/search/${search}/${genre}`
    );
  }

  // COMBO MUSIC

  getComboMusic(page) {
    return this.http.get(
      `${environment.endpoint}/products/${this.comboMusicId}/${page}`
    );
  }

  getComboMusicGenre(page, genre) {
    return this.http.get(
      `${environment.endpoint}/products/${this.comboMusicId}/${page}/genre/${genre}`
    );
  }

  getComboMusicSearch(page, search) {
    return this.http.get(
      `${environment.endpoint}/products/${this.comboMusicId}/${page}/search/${search}`
    );
  }

  getComboMusicGenreSearch(page, search, genre) {
    return this.http.get(
      `${environment.endpoint}/products/${this.comboMusicId}/${page}/search/${search}/${genre}`
    );
  }

  // KARAOKE

  getKaraoke(page) {
    return this.http.get(
      `${environment.endpoint}/products/${this.karaoke}/${page}`
    );
  }

  getKaraokeGenre(page, genre) {
    return this.http.get(
      `${environment.endpoint}/products/${this.karaoke}/${page}/genre/${genre}`
    );
  }

  getKaraokeSearch(page, search) {
    return this.http.get(
      `${environment.endpoint}/products/${this.karaoke}/${page}/search/${search}`
    );
  }

  getKaraokeGenreSearch(page, search, genre) {
    return this.http.get(
      `${environment.endpoint}/products/${this.karaoke}/${page}/search/${search}/${genre}`
    );
  }

  getGenre() {
    return this.http.get(`${environment.endpoint}/genres`);
  }

  getRecents() {
    return this.http.get(`${environment.endpoint}/recents`);
  }
}

<div class="container-flex">
  <div class="container">
    <a
      class="fulla"
      [ngClass]="{ disabled: selected == '' }"
      [routerLink]="['/payment', selected]"
    >
      Continuar
    </a>
    <div
      class="block"
      routerLink="/"
      [ngClass]="{ active: selected == 'free' }"
    >
      <p class="note">{{ text }}</p>
    </div>

    <div
      class="block"
      (click)="select('99')"
      [ngClass]="{ active: selected == '99' }"
      *ngIf="!myHome.hide99"
    >
      <p class="note">Audio</p>
      <div class="flexy">
        <p class="small">$</p>
        <p class="price">99.00</p>
        <p class="small">pesos al mes</p>
      </div>
    </div>

    <div
      class="block"
      (click)="select('149')"
      [ngClass]="{ active: selected == '149' }"
      *ngIf="!myHome.hide149"
    >
      <p class="note">Video</p>
      <div class="flexy">
        <p class="small">$</p>
        <p class="price">149.00</p>
        <p class="small">pesos al mes</p>
      </div>
    </div>

    <div
      class="block"
      (click)="select('199')"
      [ngClass]="{ active: selected == '199' }"
      *ngIf="!myHome.hide199"
    >
      <p class="note">FULL</p>
      <div class="flexy">
        <p class="small">$</p>
        <p class="price">199.00</p>
        <p class="small">pesos al mes</p>
      </div>
    </div>

    <div
      class="block"
      (click)="select('1899')"
      [ngClass]="{ active: selected == '1899' }"
      *ngIf="!myHome.hide1899"
    >
      <p class="note">FULL ANUAL</p>
      <div class="flexy">
        <p class="small">$</p>
        <p class="price">1899.00</p>
        <p class="small">pesos al año</p>
      </div>
    </div>

    <a
      class="fulla"
      [ngClass]="{ disabled: selected == '' }"
      [routerLink]="['/payment', selected]"
    >
      Continuar
    </a>
  </div>
</div>

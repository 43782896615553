<div class="dropzone" dropzone (hovered)="toggleHover($event)" (dropped)="onDrop($event)" [class.hovering]="isHovering">
  <h3>{{title}}</h3>
  <p>Deja caer los archivos </p>
  ({{genre}})
</div>
<div class="file_container">
  <div class="file-upload" *ngFor="let file of files">
    <upload-task (myImage)="emitedImage($event)" [genre]="genre" [type]="type" [file]="file"  [folder]="folder">
    </upload-task>
  </div>
</div>
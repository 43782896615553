<div class="uh-card">
  <div class="uh-card-main">
    <div class="uh-card-main-3d"></div>
    <div class="uh-card-main-contain">
      <form ngNativeValidate (ngSubmit)="login()" class="form">
        <h1>Ingresa Ahora</h1>
        <div class="uh-input">
          <label>Ingresa tu Email:</label>
          <input
            required
            [(ngModel)]="email"
            name="email"
            placeholder="Dirección Email"
            type="email"
          />
        </div>
        <div class="uh-input">
          <label>Ingresa tu Contraseña:</label>
          <input
            required
            [(ngModel)]="password"
            name="password"
            placeholder="Elige una Contraseña"
            type="password"
          />
        </div>
        <div class="uh-input">
          <label (click)="recoverPassword()" class="recovery"
            >¿Olvidaste tu contraseña?</label
          >
        </div>
        <p class="error" *ngIf="hasError">
          No encontramos un usuario con ese correo y contraseña,
        </p>
        <button type="submit">Ingresar a UH Remixes</button>
        <a routerLink="/v2/signup"
          >¿No tienes cuenta? <span>Crea una aquí</span></a
        >
      </form>
    </div>
  </div>
</div>
<app-modal
  (click)="dismiss()"
  [visible]="visible"
  [text]="text"
  [title]="title"
  [link]="link"
></app-modal>

import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { DbService } from "src/app/services/db.service";
import * as firebase from "firebase/app";

@Component({
  selector: "app-coupons",
  templateUrl: "./coupons.component.html",
  styleUrls: ["./coupons.component.scss"],
})
export class CouponsComponent implements OnInit {
  backupCoupons = [];
  coupons = [];
  searchWord = "";

  couponName = "";
  discamount = "";
  mxamount = "";
  duration = "";

  constructor(private spinner: NgxSpinnerService, private db: DbService) {}
  ngOnInit(): void {
    this.db.getCoupons().subscribe((res) => {
      this.coupons = res;
      this.backupCoupons = res;
      console.log(res);
    });
  }

  create() {
    let couponPost = firebase.functions().httpsCallable("createCoupon");
    if (this.mxamount == "") {
      couponPost({
        isAmount: false,
        name: this.couponName,
        percent: this.discamount,
        duration: this.duration,
      }).then((res) => {
        console.log(res);
      });
    } else {
      couponPost({
        isAmount: true,
        name: this.couponName,
        amount: this.mxamount,
        duration: this.duration,
      }).then((res) => {
        console.log(res);
      });
    }
  }

  delete(id) {
    console.log(id);
    this.db.removeCoupon(id).then((res) => {
      console.log(res);
    });
  }

  searchFor() {
    console.log(this.searchWord);
    if (this.searchWord == "") {
      this.coupons = this.backupCoupons;
    } else {
      this.coupons = this.coupons.filter((current) => {
        if (current.name && this.searchWord) {
          if (
            current.name.toLowerCase().indexOf(this.searchWord.toLowerCase()) >
            -1
          ) {
            return true;
          }
          return false;
        }
      });
    }
  }

  public desactivar(item): void {
    console.log(item);
    const itemIndex = this.coupons.findIndex((c) => c.uid === item.uid);
    this.coupons[itemIndex].hide = "desactivar";
    this.db.afs.collection("coupons").doc(item.uid).update({
      hide: "desactivar",
    });
  }

  public activar(item): void {
    console.log(item);
    const itemIndex = this.coupons.findIndex((c) => c.uid === item.uid);
    this.coupons[itemIndex].hide = "";
    this.db.afs.collection("coupons").doc(item.uid).update({
      hide: "",
    });
  }
}

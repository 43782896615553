import { Component, OnInit, HostListener } from "@angular/core";
import { DbService } from "src/app/services/db.service";
import { ActivatedRoute } from "@angular/router";
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-admincombos',
  templateUrl: './admincombos.component.html',
  styleUrls: ['./admincombos.component.scss']
})
export class AdmincombosComponent implements OnInit {

    videos = [];
    backupVideos = [];
    genre = "Todos";
    genres = [];
  
    hasMore = true;
    searchWord = "";
    loading = "";
  
    limit = 0;
    limitValue = 20;
  
    constructor(
      private db: DbService, private route: ActivatedRoute,
      private spinner: NgxSpinnerService
  
      ) {}
      @HostListener("window:scroll", ["$event"])
      onWindowScroll() {
        let pos = (document.documentElement.scrollTop || document.body.scrollTop) + document.documentElement.offsetHeight;
        let max = document.documentElement.scrollHeight;
        if (pos == max) {
          this.getMore();
        }
      }
    ngOnInit(): void {
      this.spinner.show();
      this.db.getGenres().subscribe((genres) => {
        this.genres = [];
        this.genres = genres;
        this.genres.push({ name: "Todos" });
      });
      this.route.params.subscribe((params) => {
        if (params.word) {
          this.hasMore = false;
          this.searchWord = params.word;
          this.searchFor();
        }
        if(params.genre){
          this.genre = params.genre
          console.log(params)
          this.db.getGenreCombos(this.genre).subscribe((videos) => {
            this.videos = videos;
            this.backupVideos = videos;
            this.getMore();
            console.log(videos.length);
            this.spinner.hide();
          });
        }else{
          this.db.getComboOrdered().subscribe((videos) => {
            this.videos = videos;
            this.backupVideos = videos;
            this.getMore();
            console.log(videos.length);
            this.spinner.hide();
          });
        }
      });
    }
  
    changeGenre() {
      window.location.assign("#/admin/combos/genre/" + this.genre);
    }
  
    getMore() {
      if (this.limit < this.videos.length) {
        this.hasMore = true;
        this.limit += this.limitValue;
      } else {
        this.hasMore = false;
      }
      console.log(this.limit);
    }
  
    delete(id, publicId, index) {
      console.log(id);
      this.videos.splice(index, 1);
      this.db.removeCombo(id);
    }
    searchFor() {
      this.limit = this.limitValue;
      console.log(this.searchWord);
      if (this.searchWord == "") {
        this.videos = this.backupVideos;
      } else {
        this.videos = this.videos.filter((current) => {
          if (current.name && this.searchWord) {
            if (current.name.toLowerCase().indexOf(this.searchWord.toLowerCase()) > -1) {
              return true;
            }
            return false;
          }
        });
      }
      for (let s of this.videos) {
        s.id = "a-" + s.id;
      }
    }
  
    transformDate(date) {
      let d = new Date(date.seconds*1000)
      let options = { day: "numeric" ,month: "long", year:"numeric"};
      return d.toLocaleDateString("es-ES", options)
    }
  }
  
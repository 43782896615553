<app-navbar *ngIf="showNavbar"></app-navbar>
<div *ngIf="showSpacer" class="navspacer"></div>
<a class="whatsapp" target="_blank" [href]="myHome.wa" *ngIf="!myHome.hideWa">
  <img src="/assets/icons/whatsapp.png" /></a>
<a class="whatsapp r" target="_blank" [href]="myHome.fb" *ngIf="!myHome.hideFb">
  <img src="/assets/icons/messenger.png" /></a>
<div class="alert" *ngIf="showNavbar" [routerLink]="alert.link" [ngClass]="alert.color">
  {{ alert.text }}
</div>
<ngx-spinner bdColor="rgba(10, 14, 31,0.8)" size="medium" color="#0066ff"></ngx-spinner>
<div class="app">
  <router-outlet></router-outlet>
</div>
<div class="modalpromo" *ngIf="db.modalPromo">
  <div class="backpromo"></div>
  <div class="mainpromo modalpromo-responsive" [ngClass]="{ closeModalPromo: db.closeModalPromo }">
    <i class="fas fa-times" (click)="db.closeModalPromo = true; closeModalPromo()"></i>
    <img src="assets/sections/music.png" alt="imagen de album de musica" />
    <h2>Obten <strong>50%</strong> de descuento</h2>
    <h4>
      Al adquirir uno de nuestros <br />
      paquetes de Audio o Video
    </h4>
    <p>Aprovecha por que esta promoción solo estará activa por unas horas</p>
    <h3>Usa el cupon <strong>50VIP</strong></h3>
    <span class="modal-requiredTex">*Solo aplica para pago con tarjeta</span>
    <button (click)="
        db.cuponAtive = true; db.closeModalPromo = true; closeModalPromo()
      " routerLink="/plans" class="full">
      Adquirir oferta
    </button>
  </div>
</div>
<div class="modalpromo" *ngIf="db.modalLogin">
  <div class="backpromo"></div>
  <div class="mainpromo modalregistro-responsivo" [ngClass]="{ closeModalPromo: db.closeModalLogin }">
    <i class="fas fa-times" (click)="db.closeModalLogin = true; closeModalLogin()"></i>
    <img src="assets/sections/music.png" alt="imagen de album de musica" />
    <h2 class="lh2">Registrate</h2>
    <h4>
      Registrate en nuestra plataforma <br />
      para poder disfrutar de todos <br />
      nuestros beneficios
    </h4>
    <div class="modalLoginControls">
      <button (click)="db.closeModalLogin = true; closeModalLogin()" routerLink="/login" class="full">
        Ingresar
      </button>
      <button (click)="db.closeModalLogin = true; closeModalLogin()" routerLink="/signup" class="full">
        Registrarme
      </button>
    </div>
  </div>
</div>
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DbService } from "src/app/services/db.service";
import * as firebase from "firebase/app";
import { NgxSpinnerService } from "ngx-spinner";
import Swal from "sweetalert2";
import { FormControl, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: "app-adminuserdetail",
  templateUrl: "./adminuserdetail.component.html",
  styleUrls: ["./adminuserdetail.component.scss"],
})
export class AdminuserdetailComponent implements OnInit {
  public user;
  public charges = [];
  public password = new FormControl("", Validators.required);
  public planForm = new FormGroup({
    subscriptionStart: new FormControl("", Validators.required),
    subscriptionEnd: new FormControl("", Validators.required),
    planPrice: new FormControl("", Validators.required),
    type: new FormControl("", Validators.required),
  });
  public planStripeForm = new FormGroup({
    planPrice: new FormControl("", Validators.required),
  });
  public params;
  public subscription;
  public amount = 0;

  constructor(
    private db: DbService,
    private ActivatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private router: Router
  ) {}

  async ngOnInit() {
    this.ActivatedRoute.params.subscribe((params) => {
      this.params = params;
      this.db.afs
        .collection("users")
        .doc(params.id)
        .ref.get()
        .then((doc) => {
          let user: any = doc.data();
          user.id = doc.id;
          this.loadPayments(user.id);
          if (
            user.membership &&
            user.membership.subscriptionStart.seconds &&
            user.membership.subscriptionEnd.seconds
          ) {
            user.membership.subscriptionStart = new Date(
              user.membership.subscriptionStart.seconds * 1000
            );
            user.membership.subscriptionEnd = new Date(
              user.membership.subscriptionEnd.seconds * 1000
            );
            if (user.membership.type === "stripe") {
              this.getSubscription(user.stripe_id);
            }
          }
          this.user = user;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  async getSubscription(customer) {
    this.spinner.show();
    let getSubscription = firebase.functions().httpsCallable("getSubscription");
    getSubscription({ customer }).then((data: any) => {
      if (data) {
        this.subscription = JSON.parse(data.data);
        if (this.subscription.price.unit_amount) {
          this.amount = this.subscription.price.unit_amount;
          if (this.amount) {
            this.amount = this.amount / 100;
          }
        }
      }
      console.log(this.subscription);
      this.spinner.hide();
    });
  }

  async updateUser() {
    if (this.password.invalid) {
      Swal.fire({
        title: "Formulario invalido",
        text: "Debes colocar una contraseña valida",
        icon: "error",
        confirmButtonText: "Aceptar",
        position: "center",
      });
      return;
    }
    let result = await Swal.fire({
      title: "Cambiar contraseña",
      text: "Estas seguro de cambiar la contraseña del usuario",
      icon: "question",
      confirmButtonText: "Aceptar",
      position: "center",
      showCancelButton: true,
      showConfirmButton: true,
    });
    if (!result.isConfirmed) {
      return;
    }
    this.spinner.show();
    let data = {
      id: this.user.id,
      params: {
        password: this.password.value,
      },
    };
    let updateUser = firebase.functions().httpsCallable("updateUser");
    updateUser(data)
      .then(() => {
        this.spinner.hide();
        Swal.fire({
          title: "Usuario actualizado",
          text: "La contraseña ha sido guardada con exito",
          icon: "success",
          confirmButtonText: "Aceptar",
          position: "center",
        });
      })
      .catch((error) => {
        this.spinner.hide();
        Swal.fire({
          title: "Error al actualizar la contraseña del usuario",
          text: error.data,
          icon: "error",
          confirmButtonText: "Aceptar",
          position: "center",
        });
      });
  }

  async enabledUser(disabled) {
    let result = await Swal.fire({
      title: disabled ? "Desactivar usuario" : "Activar usuario",
      text:
        "Estas seguro de" + disabled
          ? "desactivar el usuario"
          : "activar el usuario",
      icon: "question",
      confirmButtonText: "Aceptar",
      position: "center",
      showCancelButton: true,
      showConfirmButton: true,
    });
    if (!result.isConfirmed) {
      return;
    }
    this.spinner.show();
    let data = {
      id: this.user.id,
      params: {
        disabled,
      },
    };
    await this.db.updateUser(this.user.id, { disabled });
    let updateUser = firebase.functions().httpsCallable("updateUser");
    updateUser(data)
      .then(() => {
        Swal.fire({
          title: "Actualizado",
          text: "El usuario" + disabled ? "fue desactivado" : "fue activado",
          icon: "success",
          confirmButtonText: "Aceptar",
          position: "center",
        });
        this.spinner.hide();
      })
      .catch((error) => {
        this.spinner.hide();
        Swal.fire({
          title: "Error al actualizar el usuario",
          text: error.data,
          icon: "error",
          confirmButtonText: "Aceptar",
          position: "center",
        });
      });
  }

  async updatePlan() {
    if (this.planForm.invalid) {
      Swal.fire({
        title: "Formulario invalido",
        text: "Todos los campos son obligatorios",
        icon: "error",
        confirmButtonText: "Aceptar",
        position: "center",
      });
      return;
    }
    let result = await Swal.fire({
      title: "Plan de usuario",
      text: "Estas seguro de actualizar el plan",
      icon: "question",
      confirmButtonText: "Aceptar",
      position: "center",
      showCancelButton: true,
      showConfirmButton: true,
    });
    if (!result.isConfirmed) {
      return;
    }
    let value = this.planForm.value;
    /* fecha.setDate(fecha.getDate() + dias); */
    let subscriptionStart = new Date(value.subscriptionStart);
    subscriptionStart.setDate(subscriptionStart.getDate() + 1);
    let subscriptionEnd = new Date(value.subscriptionEnd);
    subscriptionEnd.setDate(subscriptionEnd.getDate() + 1);
    let membership = {
      subscriptionStart,
      subscriptionEnd,
      planPrice: value.planPrice,
      type: value.type,
      status: "active",
      subscription_id: "",
      userId: this.user.id,
      amount: this.planForm.controls.planPrice.value,
      paymentIntent_id: "",
      customer_id: "",
      isPay: true,
      cancelled: false,
    };
    let payment = {
      ...membership,
      customer_id: "",
      paymentIntent_id: "",
    };
    this.spinner.show();
    try {
      await this.db.updateUser(this.user.id, { membership });
      await this.db.createPayment(payment);
      let doc = await this.db.afs
        .collection("users")
        .doc(this.params.id)
        .ref.get();
      let user = doc.data();
      user.id = doc.id;
      if (
        user.membership &&
        user.membership.subscriptionStart.seconds &&
        user.membership.subscriptionEnd.seconds
      ) {
        user.membership.subscriptionStart = new Date(
          user.membership.subscriptionStart.seconds * 1000
        );
        user.membership.subscriptionEnd = new Date(
          user.membership.subscriptionEnd.seconds * 1000
        );
      }
      this.user = user;
      this.spinner.hide();
      Swal.fire({
        title: "Actualizado",
        text: "El plan ha sido actualizado con exito",
        icon: "success",
        confirmButtonText: "Aceptar",
        position: "center",
      });
    } catch (error) {
      this.spinner.hide();
      Swal.fire({
        title: "Error al actualizar el usuario",
        text: error,
        icon: "error",
        confirmButtonText: "Aceptar",
        position: "center",
      });
    }
  }

  public async deleteUser(): Promise<void> {
    let result = await Swal.fire({
      title: "Eliminar usuario",
      text: "Estas seguro, esta accion no se podra deshacer",
      icon: "question",
      confirmButtonText: "Aceptar",
      position: "center",
      showCancelButton: true,
      showConfirmButton: true,
    });
    if (!result.isConfirmed) {
      return;
    }
    this.spinner.show();
    let removeUser = firebase.functions().httpsCallable("removeUser");
    removeUser(this.user)
      .then(() => {
        Swal.fire({
          title: "Eliminado",
          text: "El usuario ha sido eliminado",
          icon: "success",
          confirmButtonText: "Aceptar",
          position: "center",
        });
        this.router.navigate(["admin/users/"]);
        this.spinner.hide();
      })
      .catch((error) => {
        this.spinner.hide();
        Swal.fire({
          title: "Error al eliminar el usuario",
          text: JSON.stringify(error),
          icon: "error",
          confirmButtonText: "Aceptar",
          position: "center",
        });
      });
  }

  async cancelPlan() {
    let result = await Swal.fire({
      title: "Cancelar plan de usuario",
      text: "Estas seguro de cancelar el plan",
      icon: "question",
      confirmButtonText: "Aceptar",
      position: "center",
      showCancelButton: true,
      showConfirmButton: true,
    });
    if (!result.isConfirmed) {
      return;
    }
    this.spinner.show();
    try {
      let cancelplanUser = firebase.functions().httpsCallable("cancelPlanUser");
      let response: any = await cancelplanUser(this.user);
      if (!response.data.removePlan) {
        await this.db.updateUser(this.user.id, { membership: null });
        let doc = await this.db.afs
          .collection("users")
          .doc(this.params.id)
          .ref.get();
        let user = doc.data();
        user.id = doc.id;
        this.user = user;
        this.spinner.hide();
        Swal.fire({
          title: "Plan cancelado",
          text: "El plan ha sido cancelado con exito",
          icon: "success",
          confirmButtonText: "Aceptar",
          position: "center",
        });
      } else {
        this.spinner.hide();
        Swal.fire({
          title: "Error al actualizar",
          text: response.data.data,
          icon: "error",
          confirmButtonText: "Aceptar",
          position: "center",
        });
      }
    } catch (error) {
      this.spinner.hide();
      Swal.fire({
        title: "Error al actualizar el usuario",
        text: error,
        icon: "error",
        confirmButtonText: "Aceptar",
        position: "center",
      });
    }
  }

  async upgradePlan(): Promise<void> {
    this.spinner.show();
    try {
      let updateSubscription = firebase
        .functions()
        .httpsCallable("updateSubscription");
      let data = await updateSubscription({
        customer: this.user.stripe_id,
        price: this.planStripeForm.value,
      });
      console.log(data);
      Swal.fire({
        title: "Actualizado",
        text: data ? data.data.message : "",
        icon: "success",
        confirmButtonText: "Aceptar",
        position: "center",
      });
      this.spinner.hide();
    } catch (error) {
      this.spinner.hide();
      Swal.fire({
        title: "Error al actualizar el usuario",
        text: JSON.stringify(error),
        icon: "error",
        confirmButtonText: "Aceptar",
        position: "center",
      });
    }
  }

  public async loadPayments(id) {
    this.spinner.show();
    let hasta: any = new Date();
    let desde: any = new Date();
    desde = new Date(desde.setDate(desde.getDate() - 90));
    desde.setHours(0, 0, 0, 0);
    hasta.setHours(23, 59, 59, 59);
    const ref = this.db.afs
      .collection("payments")
      .ref.where("userId", "==", id)
      .where("subscriptionStart", ">", desde)
      .where("subscriptionStart", "<", hasta)
      .orderBy("subscriptionStart", "desc");
    const colletion = await ref.get();
    const documents = colletion.docs;
    const data = documents.map((d) => ({
      ...d.data(),
      id: d.id,
      subscriptionStart: d.data().subscriptionStart
        ? this.transformDate(d.data().subscriptionStart)
        : "",
      subscriptionEnd: d.data().subscriptionEnd
        ? this.transformDate(d.data().subscriptionEnd)
        : "",
    }));
    this.charges = data;
    console.log(this.charges);
    this.spinner.hide();
  }

  public transformDate(date) {
    let d;
    if (date != undefined) d = new Date(date.seconds * 1000);
    if (date == undefined) d = new Date();
    let options = { day: "numeric", month: "long", year: "numeric" };
    return d.toLocaleDateString("es-ES", options);
  }
}

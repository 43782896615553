import { Injectable } from "@angular/core";
import * as firebase from "firebase";
import { logWarnings } from "protractor/built/driverProviders";
import { IpService } from "./ip.service";

export interface eventFacebook {
  event_name: string;
  event_source_url: string;
  value: number | null;
  currency: string | null;
  content_type: any | null;
  ipAddress: string;
  userAgent: string;
  test: boolean;
  type: string;
  num_items: number;
  content_category: any;
  content_ids: any;
  content_name: any;
  contents: any;
  status: any;
  em: any;
  ph: any;
  fn: any;
  subscription_id: any;
}

@Injectable({
  providedIn: "root",
})
export class EventFacebookService {
  constructor(private _IpService: IpService) {}

  async sendEvent(event: eventFacebook) {
    try {
      let data: any = await this._IpService.getIPAddressPromise();
      event.ipAddress = data.ip;
      event.userAgent = navigator.userAgent;
      event.test = false;
      console.log(event);
      let eventFacebook = firebase.functions().httpsCallable("eventFacebook");
      eventFacebook(event).then((res) => {
        console.log(res);
      });
    } catch (error) {
      console.error(error);
    }
  }

  async sendEventCampaint(event, email, eventData) {
    try {
      console.log({ event, email, eventData });
      let eventCampaing = firebase
        .functions()
        .httpsCallable("createEventActiveCampaign");
      let res = await eventCampaing({ event, email, eventData });
      console.log(res);
      return res;
    } catch (error) {
      console.log(error);
      return error;
    }
  }
}

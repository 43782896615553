<form class="search-form" action="none" method="dialog">
    <h3>Mi Playlist: </h3>
</form>

<div class="audio-table">
    <p class="large" *ngIf="user.playlist?.length == 0" class="downloadAll"> No tienes ningun elemento en tu playlist, agregala una cancion, video, karaoke o combo para descargarlo...</p>
    <p class="full" (click)="downloadAll()" *ngIf="user.playlist?.length !=0 && !loading"  class="downloadAll" > Descargar {{user.playlist?.length}} elementos</p>
    <p  class="full" *ngIf="user.playlist?.length !=0  && loading" class="downloadAll disabled" > Descargando {{user.playlist?.length}} elementos</p>
    <div *ngFor="let item of user.playlist ; let i = index">
        <div class="audio" *ngIf="item.name!='KI11'">
            <h4 class="title">{{item.name}} <span class="genre tag">{{item.genre}},
                    {{item.date}}</span>
            </h4>
            <span class="addPlaylist active" (click)="updatePlaylist(item.id)"  [ngClass]="{'gif':item.active}"></span>
        </div>
    </div>
</div>
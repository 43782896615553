import { Injectable } from "@angular/core";
import * as firebase from "firebase";

@Injectable({
  providedIn: "root",
})
export class PaypalServices {
  public plan_ant = [
    "P-8A517545JW901315GMEBYZAY",
    "P-0G4478529A591882JMEBYY3A",
    "P-53M18811WM460751PMEBYYNY",
    "P-4YA720611S971201KMEBYN7I",
    "P-00C3910020257860UL57ILXY",
    "P-90G179456F0633316L57IOUI",
    "P-8RU615877K583334TL57IPWY",
    "P-85E50208S7363620WMAHFPQQ",
    "P-13C767126L087164SMLAKI3A",
  ];

  public plans = [
    {
      id: "P-5U291984LL312754RMKPKSKY",
      product_id: "PROD-5RS72269YJ1042328",
      price: "8",
      price_mxn: "99",
      price_cop: "30250",
      currency: "USD",
      description: "Canciones por un mes",
      request_id: "-20220321-001",
      interval_unit: "MONTH",
    },
    {
      id: "P-9PL4297158420691JMI5IDEQ",
      product_id: "PROD-61U878655U418323Y",
      price: "12",
      price_mxn: "149",
      price_cop: "45376",
      currency: "USD",
      description: "Canciones y videos por un mes",
      request_id: "-20220321-002",
      interval_unit: "MONTH",
    },
    {
      id: "P-5TH84977FL4347616MI5IDEY",
      product_id: "PROD-4J369915KP755002F",
      price: "16",
      price_mxn: "199",
      price_cop: "60501",
      currency: "USD",
      description: "Acceso a todo el contenido por un mes",
      request_id: "-20220321-003",
      interval_unit: "MONTH",
    },
    {
      id: "P-4PX76846NG508541YMLAKG3Q",
      product_id: "PROD-5GV55589UJ243345R",
      price: "145",
      price_mxn: "1899",
      price_cop: "548296",
      currency: "USD",
      description: "Acceso a todo el contenido por un año",
      request_id: "-20220321-004",
      interval_unit: "YEAR",
    },
    {
      id: "P-9BD74155EV3074210MI5IDFA",
      product_id: "PROD-5A987213WN190520T",
      price: "99",
      price_usd: "8",
      price_cop: "30250",
      currency: "MXN",
      description: "Canciones por un mes",
      request_id: "-20220321-005",
      interval_unit: "MONTH",
    },
    {
      id: "P-98V54578U8979852CMI5IDFI",
      product_id: "PROD-3J358997GS5483724",
      price: "149",
      price_usd: "12",
      price_cop: "45376",
      currency: "MXN",
      description: "Canciones y videos por un mes",
      request_id: "-20220321-006",
      interval_unit: "MONTH",
    },
    {
      id: "P-59W14844Y1424533VMI5IDFI",
      product_id: "PROD-3H414841G0421702D",
      price: "199",
      price_usd: "16",
      price_cop: "60501",
      currency: "MXN",
      description: "Acceso a todo el contenido por un mes",
      request_id: "-20220321-007",
      interval_unit: "MONTH",
    },
    {
      id: "P-6PC4238657966203CMI5IDFQ",
      product_id: "PROD-5T128586NG645740E",
      price: "1899",
      price_usd: "145",
      price_cop: "548296",
      currency: "MXN",
      description: "Acceso a todo el contenido por un año",
      request_id: "-20220321-008",
      interval_unit: "YEAR",
    },
    {
      id: "P-13845155B55989732MKPKQVI",
      product_id: "PROD-0ER86674285438259",
      price: "30250",
      price_usd: "8",
      price_mxn: "99",
      currency: "COP",
      description: "Canciones por un mes",
      request_id: "-20220321-009",
      interval_unit: "MONTH",
    },
    {
      id: "P-5A154207LT3816323MKPKQVY",
      product_id: "PROD-1B342567VW6591037",
      price: "45376",
      price_usd: "12",
      price_mxn: "149",
      currency: "COP",
      description: "Canciones y videos por un mes",
      request_id: "-20220321-010",
      interval_unit: "MONTH",
    },
    {
      id: "P-2PN9852369667210YMKPKQWA",
      product_id: "PROD-38C22092M45966115",
      price: "60501",
      price_usd: "16",
      price_mxn: "199",
      currency: "COP",
      description: "Acceso a todo el contenido por un mes",
      request_id: "-20220321-011",
      interval_unit: "MONTH",
    },
    {
      id: "P-0TJ87157SJ2407603MKPKQWI",
      product_id: "PROD-5B358855EA9235234",
      price: "548296",
      price_usd: "145",
      price_mxn: "1899",
      currency: "COP",
      description: "Acceso a todo el contenido por un año",
      request_id: "-20220321-012",
      interval_unit: "YEAR",
    },
  ];

  constructor() {}

  public async subscription(plan_id, user, discount) {
    let plan = this.plans.find((plan) => plan.id === plan_id);
    let data = this._createRequestSubscription(
      plan,
      discount,
      user.name,
      "NA",
      user.email
    );
    let req = firebase.functions().httpsCallable("subscriptionpaypal");
    let res = await req({ body: data, user_id: user.id });
    return res.data;
  }

  public _createRequestSubscription(plan, discount, name, lastName, email) {
    let date: any = new Date();
    date = date.setHours(date.getHours() + 1);
    date = new Date(date);
    date = date.toISOString();
    console.log(date);
    let price = plan.price;
    price = price - discount;
    const req = {
      plan_id: plan.id,
      start_time: date,
      quantity: "1",
      subscriber: {
        name: {
          given_name: name,
          surname: lastName,
        },
        email_address: email,
      },
      application_context: {
        return_url: "https://uhremix.com/#/success",
        cancel_url: "https://uhremix.com/#/plans",
      },
      plan: {
        payment_preferences: {
          auto_bill_outstanding: true,
          setup_fee: {
            value: price.toFixed(2),
            currency_code: plan.currency,
          },
          setup_fee_failure_action: "CANCEL",
          payment_failure_threshold: 2,
        },
      },
    };
    return req;
  }

  public async getSubscriptions(subscription_id) {
    let req = firebase.functions().httpsCallable("getSubscriptions");
    let res = await req({ subscription_id });
    return res.data;
  }
}

<form class="search-form" action="none" method="dialog">
    <h3>Videos: </h3>
    <input name="search" placeholder="Buscar" (keyup.enter)="searchFor()" [(ngModel)]="searchWord">
</form>
<div class="sel-container">
    <label for="genre">Buscar por genero músical:</label>
    <select id="genre" [(ngModel)]="genre" (change)="changeGenre()" name="genre" class="selector">
        <option *ngFor="let g of genres" [value]="g.name">{{g.name}}</option>
    </select>
</div>


<div class="audio-table">
    <div *ngFor="let video of videos ; let i = index">
        <div class="audio" *ngIf="i <= limit && (video.genre == genre || genre == 'Todos') ">>
            <a class="play" [href]="video.url" target="_blank"></a>
            <h4 class="title">{{video.name}} <span class="genre tag">{{video.genre}},
                    {{transformDate(video.createdAt)}}</span>
            </h4>
            <span></span>
            <span class="delete" (click)="delete(video.id,video.publicId,i)"></span>
        </div>
    </div>
    <div class="see-more" *ngIf="hasMore && videos.length>0">
        <a (click)="getMore()" class="blue bold underline pointer" *ngIf="!loading">Cargar más</a>
        <a *ngIf="loading" class="gif_light"></a>
    </div>
</div>

<ngx-spinner bdColor="rgba(10, 14, 31,0.8)" size="medium" color="#0066ff"></ngx-spinner>
import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'uploader',
  templateUrl: './uploader.component.html',
  styleUrls: ['./uploader.component.scss']
})
export class UploaderComponent {
  @Output() myImage = new EventEmitter<any>();

  isHovering: boolean;
  files: File[] = [];
  @Input() type = true;
  @Input() alsoPublic = false;
  @Input() title = "Drag N Drop";
  @Input() genre="Otros"

  @Input() folder=""
  toggleHover(event: boolean) {
    this.isHovering = event;
  }

  onDrop(files: FileList) {
    for (let i = 0; i < files.length; i++) {
      this.files.unshift(files.item(i));
    }
  }

  emitedImage(imageUrl) {
    console.log("GOT   ", imageUrl)
    this.emitImage(imageUrl);
  }

  emitImage(item) {
    this.myImage.emit(item);
  }
}
<form class="search-form" action="none" method="dialog">
  <h3>Cupones:</h3>
  <input
    name="search"
    placeholder="Buscar"
    (input)="searchFor()"
    [(ngModel)]="searchWord"
    name="searchWord"
  />
</form>

<form class="flex">
  <button class="button blue full" routerLink="add">
    Crear descarga premium
  </button>
</form>

<div class="table">
  <div class="content">
    <div *ngFor="let item of items">
      <div class="row">
        <div class="row-image">
          <i class="fas fa-eye-slash inactive-color" *ngIf="item.hide"></i>
          <img [routerLink]="[item.uid]" [src]="item.image" />
        </div>
        <p [routerLink]="[item.uid]">{{ item.title }}</p>
        <span class="delete" (click)="delete(item.uid)"></span>
      </div>
    </div>
  </div>
</div>
<ngx-spinner
  bdColor="rgba(10, 14, 31,0.8)"
  size="medium"
  color="#0066ff"
></ngx-spinner>

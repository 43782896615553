import { Component, OnInit, HostListener } from "@angular/core";
import { DbService } from "src/app/services/db.service";
import { ActivatedRoute, Router } from "@angular/router";

import { AuthService } from "src/app/services/auth.service";
import { ActiveCampService } from "src/app/services/active-camp.service";
import { NgxSpinnerService } from "ngx-spinner";
import { MysqlService } from "src/app/services/mysql.service";
import { isNgTemplate } from "@angular/compiler";
import { environment } from "src/environments/environment";
import { FormControl } from "@angular/forms";

@Component({
  selector: "app-combos",
  templateUrl: "./combos.component.html",
  styleUrls: ["./combos.component.scss"],
})
export class CombosComponent implements OnInit {
  public numberOfPages = [];
  public pageSelected = 1;
  public paginatorControl = new FormControl(1);
  activeSubscription = true;
  endpoint = environment.endpoint;
  token = "";
  songs = [];
  backupSongs = [];

  hasMore = true;
  searchWord = "";
  genre = "";
  genres = {};
  genresList = [];

  alert = false;

  downloading = false;

  user = {
    id: "",
    tokens: 0,
    vidIds: [],
    membership: {
      planPrice: 0,
    },
    comboIds: [],
    musicIds: [],
    karaokeIds: [],
    playlist: [],
  };

  nowPlaying = {
    id: "",
    index: "",
  };
  hasPaid = "";
  downloads = 0;
  loading = false;
  page = 0;

  info = [];
  selected = 0;
  left = 0;
  top = 0;

  constructor(
    private db: DbService,
    private auth: AuthService,
    private spinner: NgxSpinnerService,
    private mysql: MysqlService,
    public router: Router
  ) {}

  ngOnInit() {
    const today = new Date();
    const june13 = new Date(
      today.getFullYear(),
      5 /* June is zero-indexed in JavaScript */,
      13
    );
    this.paginatorControl.valueChanges.subscribe((value) => {
      this.pageSelected = parseInt(value);
      this.page = value - 1;
      this.applyFilters();
    });
    this.auth.getUserToken().subscribe((res) => {
      console.log(res);
      this.token = res;
    });
    if (localStorage.getItem("reload") == "true") {
      localStorage.setItem("reload", "false");
      window.location.reload();
    }
    this.spinner.show();
    this.mysql.getGenre().subscribe((res: any) => {
      this.genresList = res.genres;
      for (let g of res.genres) {
        this.genres[g.id] = g;
      }
      this.mysql.getComboVideo(this.page).subscribe((res: any) => {
        console.log(res.products);
        if (res.products.length < environment.limit) this.hasMore = false;
        this.songs = res.products;
        const filteredArray = this.songs.filter((obj) => {
          // Convert createdAt string to a Date object
          const createdAtDate = new Date(obj.createdAt);
          // Compare createdAt date with June 13 of the current year
          return createdAtDate > june13;
        });
        this.songs = filteredArray;
        for (let s of this.songs) {
          this.transformDate(s);
        }
        this.spinner.hide();
      });
    });
    this.getPaginator(this.mysql.comboVideoId, this.searchWord, "");

    this.auth.getUser().then((res) => {
      if (!res) return;
      this.db.getUser(res.uid).subscribe((user: any) => {
        this.user = user;
        this.user.id = res.uid;
        this.downloads = user.tokens;
        if (user.membership != undefined) {
          this.hasPaid = user.membership.status;
          if (
            user.membership.planPrice == undefined &&
            this.hasPaid == "active"
          )
            this.user.membership.planPrice = 149;
        } else {
          this.hasPaid = "none";
        }
        console.log(this.user.membership);
        if (this.user.playlist == undefined) this.user.playlist = [];
        if (this.user.vidIds == undefined) this.user.vidIds = [];
        if (this.user.comboIds == undefined) this.user.comboIds = [];
        if (this.user.musicIds == undefined) this.user.comboIds = [];
        if (this.user.karaokeIds == undefined) this.user.karaokeIds = [];
      });
    });
  }

  changeGenre(selected) {
    this.genre = selected;
    this.paginatorControl.setValue(1);
  }

  searchFor() {
    this.paginatorControl.setValue(1);
  }

  applyFilters() {
    const today = new Date();
    const june13 = new Date(
      today.getFullYear(),
      5 /* June is zero-indexed in JavaScript */,
      13
    );
    if (this.genre != "") {
      let selectedId = this.genre;
      console.log(selectedId);
      if (this.searchWord == "") {
        this.mysql
          .getComboVideoGenre(this.page, selectedId)
          .subscribe((res: any) => {
            console.log(res);
            if (res.length < environment.limit) this.hasMore = false;
            console.log(res.length);
            this.songs = res;
            const filteredArray = this.songs.filter((obj) => {
              // Convert createdAt string to a Date object
              const createdAtDate = new Date(obj.createdAt);
              // Compare createdAt date with June 13 of the current year
              return createdAtDate > june13;
            });
            this.songs = filteredArray;
            for (let s of res) {
              this.transformDate(s);
            }
            this.loading = false;
          });
        this.getPaginator(this.mysql.comboVideoId, this.searchWord, selectedId);
      } else {
        this.mysql
          .getComboVideoGenreSearch(this.page, this.searchWord, selectedId)
          .subscribe((res: any) => {
            console.log(res);
            if (res.length < environment.limit) this.hasMore = false;
            console.log(res.length);
            this.songs = res;
            const filteredArray = this.songs.filter((obj) => {
              // Convert createdAt string to a Date object
              const createdAtDate = new Date(obj.createdAt);
              // Compare createdAt date with June 13 of the current year
              return createdAtDate > june13;
            });
            this.songs = filteredArray;
            for (let s of res) {
              this.transformDate(s);
            }
            this.loading = false;
          });
        this.getPaginator(this.mysql.comboVideoId, this.searchWord, selectedId);
      }
    } else {
      this.mysql
        .getComboVideoSearch(this.page, this.searchWord)
        .subscribe((res: any) => {
          if (res.products.length < environment.limit) this.hasMore = false;
          console.log(res.products.length);
          this.songs = res.products;
          const filteredArray = this.songs.filter((obj) => {
            // Convert createdAt string to a Date object
            const createdAtDate = new Date(obj.createdAt);
            // Compare createdAt date with June 13 of the current year
            return createdAtDate > june13;
          });
          this.songs = filteredArray;
          for (let s of res.products) {
            this.transformDate(s);
          }
          this.loading = false;
        });
      this.getPaginator(this.mysql.comboVideoId, this.searchWord, "");
    }
  }

  downloadFile(i): void {
    this.loading = true;
    this.songs[i].active = true;
    let href = this.endpoint + "/zip/" + this.songs[i].id + "/" + this.token;
    //window.open(href);
    var link = document.createElement("a");
    link.href = href;
    link.download = href.substr(href.lastIndexOf("/") + 1);
    link.click();

    window.setTimeout(() => {
      this.loading = true;
      this.songs[i].active = false;
      this.markFile(this.songs[i].id);
    }, 10000);
  }

  markFile(fileId) {
    this.downloading = false;
    let result = this.includesId(fileId);
    if (result >= 0) {
      this.user.comboIds[result].count++;
    } else [this.user.comboIds.push({ id: fileId, count: 1 })];
    if (this.downloads > 0) {
      this.user.tokens--;
    }
    this.db.updateUser(this.user.id, this.user);
  }

  includesId(id) {
    let array = this.user.comboIds;
    for (let i = 0; i < array.length; i++) {
      if (array[i].id == id) {
        return i;
      }
    }
    return -1;
  }

  contar(id) {
    let result = this.includesId(id);
    try {
      if (this.user.comboIds[result].count != undefined) {
        if (this.user.comboIds[result].count >= 3) return false;
      }
    } catch (error) {
      return true;
    }
    return true;
  }

  getInfo(id) {
    if (this.selected == id) {
      this.selected = -1;
    } else {
      this.selected = id;
      this.mysql.getInfo(id).subscribe((res: any) => {
        console.log(res);
        this.info = res.info;
      });
    }
  }

  updatePlaylist(song) {
    let result = this.includesPlaylist(song.id);
    console.log(result);
    if (result >= 0) {
      this.user.playlist.splice(result, 1);
    } else {
      song.type = "combo";
      if (this.user.playlist.length <= 50) this.user.playlist.push(song);
    }
    this.db.updateUser(this.user.id, this.user);
  }

  includesPlaylist(id) {
    let array = this.user.playlist;
    for (let i = 0; i < array.length; i++) {
      if (array[i].id == id) {
        return i;
      }
    }
    return -1;
  }

  transformDate(item) {
    let date = item.createdAt;
    let d = new Date(date);
    let options = { day: "numeric", month: "long", year: "numeric" };
    item.date = d.toLocaleDateString("es-ES", options);
  }

  getDate(date) {
    return date.toDate();
  }

  public getPaginator(type, search, genre): void {
    window.scrollTo(0, 0);
    this.mysql
      .getPageLength(type, search ? search : "empty", genre ? genre : "empty")
      .subscribe((data: any) => {
        let pages = Math.ceil(data.pages[0]["count(*)"] / 50);
        this.numberOfPages = new Array(pages);
      });
  }
}
